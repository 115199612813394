import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-right: 40px;
  user-select: none;
  cursor: ${(props) => (props.blocked === false ? 'pointer' : 'not-allowed')};
`;

export const Name = styled.h4`
  margin-right: 5px;
  font-family: Roboto;
  font-size: 14px;

  font-weight: ${(props) => (props.selected === true ? 500 : 400)};
  color: ${(props) => (props.selected === true ? '#7A6CFF' : '#595959')};
`;

export const Length = styled.h4`
  background-color: #f3f0ff;
  padding: 0px 8px;
  border-radius: 100px;
  font-weight: 400;
  font-family: Roboto Mono;
  font-size: 14px;

  font-weight: ${(props) => (props.selected === true ? 500 : 400)};
  color: ${(props) =>
    props.selected === true ? '#7A6CFF' : 'rgba(0,0,0,0.45)'};
`;

export const filterArea = styled.div`
  display: flex;
  flex-direction: row;

  padding-bottom: ${(props) => (props.selected === true ? 10 : 13)}px;
  border-bottom: ${(props) =>
    props.selected === true ? '3px solid #7A6CFF' : 'null'};
`;
