/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { DatePicker, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';
import { Typography } from '../../Components/typography';
import * as S from './styles';
import BreadCrumbs from '../../Components/BreadCrumbs';
import FilterButton from '../../Components/FilterButton';
import { Separator } from '../NewPayment/styles';
import 'antd/dist/antd.css';
import Charts from '../../Components/Charts';
import {
  getTotalSalesRequest,
  getTotalTransactionsRequest,
} from '../../redux/ducks/Charts/actions';
import { RootState } from '../../redux/reducers';
import { Loader } from '../../Components/loader';
import { getChannelsRequest } from '../../redux/ducks/GetChannels/actions';

export default function Home() {
  const dispatch = useDispatch();
  const { totalSales, monthlySales, loading, controlDate } = useSelector(
    (state: RootState) => state.charts
  );
  const { channel, store } = useSelector((state: RootState) => state);
  const [selected, setSelected] = useState(['Todos os Pagamentos']);
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [channels, setChannel] = useState([]);
  const [salesChannelId, setSalesChannelId] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState([]);
  const [mappedSellers, setMappedSellers] = useState([]);

  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const handleSelect = (value) => {
    const include = selected.includes(value);
    switch (value) {
      case 'Todos os Pagamentos':
        if (include && selected.length > 1) {
          setSelected((state) => state.filter((item) => value !== item));
        } else if (!include) {
          setSelected([value]);
        }
      default:
        if (include && selected.length > 1) {
          setSelected((state) => state.filter((item) => value !== item));
        } else if (!include) {
          setSelected((state) =>
            state[0] !== 'Todos os Pagamentos' ? [...state, value] : [value]
          );
          if (selected.length >= 4) {
            setSelected(['Todos os Pagamentos']);
          }
        }
    }
  };

  const handleSelected = (value) => {
    const included = selected.includes(value);

    return included;
  };

  const handleSaveDate = ([v1, v2]) => {
    v1 !== null ? setInitialDate(v1._d) : setInitialDate(v1);
    v2 !== null ? setFinalDate(v2._d) : setFinalDate(v2);
  };

  const handleSaveSalesChannel = (value) => {
    setSalesChannelId(value);
    setSelectedSeller([]);
  };

  const handleSaveSeller = (value) => {
    setSelectedSeller(value);
  };

  useEffect(() => {
    dispatch(getChannelsRequest());
  }, []);

  useEffect(() => {
    if (salesChannelId.length) {
      const setSeller = new Set();
      const selectedSalesChannel = channel.filter((el) => {
        return salesChannelId.includes(el.id);
      });

      const sellers = selectedSalesChannel
        .map((el) => el.sellers)
        .reduce((acc, array) => [...acc, ...array], [])
        .filter((el) => {
          const duplicatedSeller = setSeller.has(el.id);
          setSeller.add(el.id);
          return !duplicatedSeller;
        });

      const formattedSellers = sellers.map((item) => ({
        label: item.description,
        value: item.id,
      }));

      setMappedSellers(formattedSellers);
    }
  }, [salesChannelId]);

  useEffect(() => {
    const storeChannels = channel.length
      ? channel.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      : [];
    setChannel(storeChannels);
  }, [channel]);

  const initialDateFormatted =
    initialDate !== null ? Moment(initialDate).format('YYYY-MM-DD') : null;

  const finalDateFormatted =
    finalDate !== null ? Moment(finalDate).format('YYYY-MM-DD') : null;

  useEffect(() => {
    const type = {
      paymentType: selected.includes('Todos os Pagamentos')
        ? null
        : `${selected}`,
      initialDate: initialDateFormatted,
      finalDate: finalDateFormatted,
      salesChannel: salesChannelId.length ? salesChannelId : null,
      controlDate,
      sellers: selectedSeller.length ? selectedSeller : null,
    };

    Object.keys(type).forEach((key) => {
      if (type[key] === null || type[key] === undefined) {
        delete type[key];
      }
    });

    dispatch(getTotalSalesRequest(type));
  }, [
    selected,
    initialDate,
    finalDate,
    salesChannelId,
    controlDate,
    selectedSeller,
  ]);

  useEffect(() => {
    const type = {
      initialDate: initialDateFormatted,
      finalDate: finalDateFormatted,
      salesChannel: salesChannelId.length ? salesChannelId : null,
      controlDate,
      sellers: selectedSeller.length ? selectedSeller : null,
    };

    Object.keys(type).forEach((key) => {
      if (type[key] === null || type[key] === undefined) {
        delete type[key];
      }
    });

    dispatch(getTotalTransactionsRequest(type));
  }, [initialDate, finalDate, salesChannelId, controlDate, selectedSeller]);

  useEffect(() => {
    const type = {
      initialDate: initialDateFormatted,
      finalDate: finalDateFormatted,
      salesChannel: salesChannelId.length ? salesChannelId : null,
      controlDate,
      sellers: selectedSeller.length ? selectedSeller : null,
    };

    Object.keys(type).forEach((key) => {
      if (type[key] === null || type[key] === undefined) {
        delete type[key];
      }
    });

    dispatch(getTotalSalesRequest(type));
    dispatch(getTotalTransactionsRequest(type));

    setInitialDate(null);
    setFinalDate(null);
    setSelected(['Todos os Pagamentos']);
  }, [store]);

  return (
    <>
      <S.BreadCrumbsContainer>
        <BreadCrumbs
          options={[
            { name: 'Pagina Inicial', path: '/', disabled: false },
            {
              name: 'Dashboard',
              path: '/transactions',
              disabled: false,
            },
          ]}
        />
      </S.BreadCrumbsContainer>
      <S.Container>
        <Typography
          align="left"
          variant="h3"
          variantion="h3_medium"
          weight={500}
          color="#262626"
        >
          Página inicial
        </Typography>
        <S.InformationContainer>
          <S.PaymentFilterContainer>
            <FilterButton
              onChange={(e) => handleSelect(e)}
              value="Todos os Pagamentos"
              blocked={false}
              selected={handleSelected('Todos os Pagamentos')}
              name="Todos os Pagamentos"
              data={totalSales.total}
            />
            <FilterButton
              onChange={(e) => handleSelect(e)}
              value="CREDIT_CARD"
              blocked={false}
              selected={handleSelected('CREDIT_CARD')}
              name="Cartão de Crédito"
              data={totalSales.credit}
            />
            <FilterButton
              onChange={(e) => handleSelect(e)}
              value="BOLETO"
              blocked={false}
              selected={handleSelected('BOLETO')}
              name="Boleto"
              data={totalSales.invoice}
            />
            <FilterButton
              onChange={(e) => handleSelect(e)}
              value="PIX"
              blocked={false}
              selected={handleSelected('PIX')}
              name="Pix"
              data={totalSales.pix}
            />
            <FilterButton
              onChange={(e) => handleSelect(e)}
              value="EXCHANGE_VOUCHER"
              blocked={false}
              selected={handleSelected('EXCHANGE_VOUCHER')}
              name="Exchange Voucher"
              data={totalSales.exchangeVoucher}
            />
            <FilterButton
              onChange={(e) => handleSelect(e)}
              value="VOUCHER"
              blocked={false}
              selected={handleSelected('VOUCHER')}
              name="Voucher"
              data={totalSales.voucher}
            />
          </S.PaymentFilterContainer>
          <S.FilterContainer>
            <Separator />
          </S.FilterContainer>
          <S.FilterContainer marginTop>
            <RangePicker
              style={{
                width: '400px',
                height: '32px',
              }}
              format="YYYY-MM-DD"
              placeholder={['Data de início', 'Data final']}
              onChange={(e) =>
                e === null
                  ? handleSaveDate([e, e])
                  : handleSaveDate([e[0], e[1]])
              }
              allowEmpty={[false, true]}
            />
            <Select
              style={{ width: '400px', height: '32px' }}
              showSearch={false}
              showArrow
              mode="multiple"
              placeholder="Selecione o canal de venda"
              optionFilterProp="children"
              onChange={(e) => handleSaveSalesChannel(e)}
            >
              {channels.map((item, i) => (
                <Option value={item.value} key={i}>
                  {item.label}
                </Option>
              ))}
            </Select>

            <Select
              style={{ width: '400px', height: '32px' }}
              showSearch={false}
              showArrow
              disabled={!salesChannelId.length || salesChannelId === undefined}
              mode="multiple"
              value={selectedSeller}
              placeholder="Selecione o vendedor"
              optionFilterProp="children"
              onChange={(e) => handleSaveSeller(e)}
            >
              {mappedSellers.map((item, i) => (
                <Option value={item.value} key={i}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </S.FilterContainer>

          <S.ChartsContainer>
            {loading ? (
              <div
                style={{
                  width: '100%',
                  height: '500px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loader />
              </div>
            ) : (
              <Charts data={monthlySales} column title="Total de Vendas" />
            )}
          </S.ChartsContainer>
        </S.InformationContainer>
      </S.Container>
    </>
  );
}
