import * as S from '../../styles';
import { Typography } from '../../../../Components/typography';

export default function CustomerData({ data }) {
  return (
    <S.Card>
      <S.CardHeader>
        <Typography
          align="left"
          variant="h5"
          variantion="h5_small"
          weigth="600"
          color="#0F1826"
        >
          Dados do Cliente
        </Typography>
      </S.CardHeader>
      <Typography
        align="left"
        variant="h6"
        variantion="h6_small"
        weigth="normal"
        color="#879BB4"
      >
        ID
      </Typography>
      <Typography
        align="left"
        variant="h5"
        variantion="h5_small"
        weigth="normal"
        color="#414F62"
      >
        {data?.id}
      </Typography>
      <S.CardItemsColumn>
        <S.CardItem>
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weigth="normal"
            color="#879BB4"
          >
            NOME
          </Typography>
          <Typography
            align="left"
            variant="h5"
            variantion="h5_small"
            weigth="normal"
            color="#414F62"
          >
            {data?.fullName}
          </Typography>
        </S.CardItem>
        <S.CardItem>
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weigth="normal"
            color="#879BB4"
          >
            TIPO DE DOCUMENTO
          </Typography>
          <Typography
            align="left"
            variant="h5"
            variantion="h5_small"
            weigth="normal"
            color="#414F62"
          >
            {data?.documentType}
          </Typography>
        </S.CardItem>
        <S.CardItem>
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weigth="normal"
            color="#879BB4"
          >
            DOCUMENTO
          </Typography>
          <Typography
            align="left"
            variant="h5"
            variantion="h5_small"
            weigth="normal"
            color="#414F62"
          >
            {data?.document}
          </Typography>
        </S.CardItem>
        <S.CardItem>
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weigth="normal"
            color="#879BB4"
          >
            TELEFONE
          </Typography>
          <Typography
            align="left"
            variant="h5"
            variantion="h5_small"
            weigth="normal"
            color="#414F62"
          >
            {data?.phone}
          </Typography>
        </S.CardItem>
      </S.CardItemsColumn>
      <Typography
        align="left"
        variant="h6"
        variantion="h6_small"
        weigth="normal"
        color="#879BB4"
      >
        E-MAIL
      </Typography>
      <Typography
        align="left"
        variant="h5"
        variantion="h5_small"
        weigth="normal"
        color="#414F62"
      >
        {data?.email}
      </Typography>
    </S.Card>
  );
}
