import styled, { css } from 'styled-components';

import { InfraButton } from '@infralabs/design-system';

export const Button = styled(InfraButton)`
  ${({ outline, color }) => css`
    background-color: ${outline ? 'transparent' : color}!important;
    border: ${outline ? `1px solid ${color}` : 'none'}!important;
    color: ${outline ? color : ''} !important;
    width: 35%;
    margin-left: ${outline ? '80px' : 'none'}!important;
    margin-right: ${outline ? 'none' : '80px'}!important;
  `}
`;

export const BreadCrumbsContainer = styled.div`
  padding: 32px 70px;
`;

export const BackContainer = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 55px;
  cursor: pointer;
`;

export const CartContainer = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
`;

export const PaymentType = styled.div`
  display: flex;
  background-color: #4f4ffb;
  border-radius: 3px;
  height: 25px;
  width: 150px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 10px;
`;

export const PageContent = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 47px 50px;
`;

/* ---------------- Timeline ------------------- */

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 13px;
  padding-left: 5%;
`;

/* ---------------- General ------------------- */

export const Card = styled.div`
  padding: 24px 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dde5ef;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 10px 0;
  width: 100%;
  align-items: flex-start;
`;

export const CardHeader = styled.div`
  width: 100%;
  margin-bottom: 24px;
  flex: 1;
`;

export const CardItems = styled.div`
  ${({ fullLine }) => css`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  `}
`;

export const CardItemsColumn = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  &:nth-child(0) {
    ${(props) => props.separator && 'border-right: 1px solid #DDE5EF;'}
  }
`;

export const CardItemsColumnAddress = styled.div`
  display: grid;
  grid-template-columns: 35% 30% 35%;
`;

export const CardItem = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 15px 5px;
`;

export const PaybackGrid = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dde5ef;
  padding: 0 0 55px 0;
`;

export const PaybackTransaction = styled.div`
  width: 30%;
  border-right: 1px solid #dde5ef;
`;
export const PaybackType = styled.div`
  width: 70%;
  padding: 0 24px;
`;

export const PaybackValue = styled.div`
  width: 100%;
  padding: 9px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ValueContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
`;

export const Selection = styled.div`
  ${({ selected }) => css`
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: ${selected ? '#45E398' : '#DDE5EF'};
    color: ${selected ? '#fff' : '#879BB4'};
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
  `}
`;
