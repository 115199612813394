import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApi } from '../../../Services/api';

import { getPaymentSuccess } from './actions';

import { serialize } from '../../../utils';

export function* getPayment(payload) {
  try {
    const response = yield call(
      baseApi.get,
      `payments?${serialize(payload.payload)}`
    );

    yield put(getPaymentSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export default all([takeLatest(Types.GET_PAYMENT, getPayment)]);
