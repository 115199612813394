import styled, { css } from 'styled-components';

import { InfraTypography } from '@infralabs/design-system';

export const Typography = styled(InfraTypography)`
  ${({ weight, color, lineHeight, margin }) => css`
    font-weight: ${weight || '300'}!important;
    color: ${color}!important;
    line-height: ${lineHeight}!important;
    margin: ${margin}!important;
    word-break: break-word;
  `}
`;
