import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApi } from '../../../Services/api';
import { setError } from '../Error/actions';

import {
  getTransactionsSuccess,
  getTransactionsHistorySuccess,
} from './actions';

export function* getTransaction(payload) {
  try {
    const response = yield call(
      baseApi.get,
      `/payments/${payload.payload.paymentId}`
    );

    const historyResponse = yield call(
      baseApi.get,
      `/payments/transactions/history/${payload.payload.paymentId}/${payload.payload.transactionId}/${payload.payload.sellerId}`
    );

    yield put(getTransactionsSuccess(response.data.data));
    yield put(getTransactionsHistorySuccess(historyResponse.data.data));
  } catch (err) {
    console.log(err);
    yield put(
      setError({
        show: true,
        message: 'Ops! Não conseguimos pegar os detalhes da transação.',
      })
    );
  }
}

export default all([takeLatest(Types.GET_TRASACTIONS_DETAILS, getTransaction)]);
