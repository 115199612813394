import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApiMain } from '../../../Services/api';

import { approvePaymentSuccess } from './actions';

export function* approvePayment(payload) {
  try {
    let body = {};
    if (payload.payload.paymentType === 'billet') {
      body = {
        status: {
          statusText: payload.payload.body,
        },
      };
    } else {
      body = {
        status: payload.payload.body,
      };
    }

    const response = yield call(
      baseApiMain.post,
      `callback/${payload.payload.paymentId}/transactions/${payload.payload.paymentOrderId}/${payload.payload.paymentType}`,
      body
    );

    yield put(approvePaymentSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export default all([takeLatest(Types.APPROVE_PAYMENT, approvePayment)]);
