import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
`;

export const Text = styled.span`
  ${({ disabled }) => css`
    font-size: 14px;
    color: #414f62;
    margin-right: 10px;

    &:hover {
      cursor: ${disabled ? '' : 'pointer'};
      color: ${disabled ? '#414f62' : '#7676ff'};
    }
  `}
`;
