/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  InfraAccordion,
  InfraSelect,
  InfraModal,
  InfraButton,
  InfraTextField,
  InfraSwitch,
} from '@infralabs/design-system';
import { MinusCircle, PlusCircle, X, Info, ArrowUUpLeft } from 'phosphor-react';
import { Typography } from '../../../Components/typography';
import * as S from '../styles';
import {
  deletePaymentOptions,
  getPaymentOptionsRequest,
  postPaymentOptions,
  replicateDefaultSellerConfigs,
  replicateDefaultSellerConfigsToSpecific,
  updatePaymentOptions,
} from '../../../redux/ducks/PaymentOptions/actions';
import { updateCards } from '../../../redux/ducks/CardInUse/actions';
import { RootState } from '../../../redux/reducers';
import formatCurrency from '../../../utils/formatCurrency';
import { CurrencyInput } from '../../../Components/currencyInput';
import { NumberFormatInput } from '../../../Components/numberFormatInput';
import removeMask from '../../../utils/removeMask';
import { ScrollToTop } from '../../../utils/scrollToTop';
import { mountTableFlag } from '../../../utils/mountTable';
import { AvailableCardFlags } from '../../../Types/availableCardFlags';
import Table from './table';
import { Separator } from '../../NewPayment/styles';
import { percentMask } from '../../../utils/percentMask';

type ConnectorsType = {
  label: string;
  value: string;
};

export default function CreditCard({
  data,
  channel,
  seller,
  def,
  defId,
  active,
  connectors,
  selectedChannel,
  position,
}) {
  const [inputs, setInputs] = useState<AvailableCardFlags[]>([]);
  const [partner, setPartner] = useState<ConnectorsType>({
    label: '',
    value: '',
  });
  const [namePaymentOption, setNamePaymentOption] = useState(data.name);
  const [fraudCheckPartner, setFraudCheckPartner] = useState<ConnectorsType>({
    label: '',
    value: '',
  });
  const [result, setResult] = useState([]);
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [optionModal, setOptionModal] = useState('');
  const [percentages, setPercentages] = useState([]);
  const [percentSelect, setPercentSelect] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const minInstallmentValue = 1;
  const [minValue, setMinValue] = useState(0);
  const [checked, setChecked] = useState(false);
  const [indice, setIndex] = useState(0);
  const [replicateSellerDefault, setReplicateSellerDefault] = useState(false);
  const [
    replicateSellerDefaultToSpecific,
    setReplicateSellerDefaultToSpecific,
  ] = useState(false);
  const [changedInputs, setChangedInputs] = useState(false);
  const [textInfo, setTextInfo] = useState(
    'Por favor verifique se os dados estão preenchidos corretamente.'
  );
  const [userAction, setUserAction] = useState('salvar');
  const dispatch = useDispatch();
  const { cardsInUse } = useSelector((state: RootState) => state);

  function verifyCardFlags(cardFlags) {
    let flagResult = false;
    cardFlags.map((cardflag) => {
      if (cardflag.name === '' || cardflag.numberInstallments === 0) {
        flagResult = true;
      }
    });
    if (minValue === undefined) {
      flagResult = true;
    }
    return flagResult;
  }

  const errorModal = () => {
    setTextInfo(
      `O valor mínimo da parcela deve ser maior do que R$ ${
        minInstallmentValue / 100
      }`
    );

    setShowAlert(true);
  };

  function verifyCardFlagsValues(item) {
    item.map((cardflag) => {
      if (cardflag.interestRatePercentages.length === 0) {
        const aux = [];
        for (let i = 1; i <= cardflag.numberInstallments; i++) {
          aux.push(0);
        }
        cardflag.interestRatePercentages = aux;
      }
      if (
        cardflag.interestRatePercentages.length !== cardflag.numberInstallments
      ) {
        while (
          cardflag.interestRatePercentages.length < cardflag.numberInstallments
        ) {
          cardflag.interestRatePercentages.push(0);
        }
        while (
          cardflag.interestRatePercentages.length > cardflag.numberInstallments
        ) {
          cardflag.interestRatePercentages.pop();
        }
      }
    });
  }

  function unselectFlag(flagName: string) {
    const indexes = [];
    cardsInUse.usedList.map((el, i) => {
      if (flagName === el) {
        indexes.push(i);
      }
    });
    for (let index = indexes.length; index > 0; index--) {
      cardsInUse.usedList.splice(indexes[index - 1], 1);
    }
  }

  const deleteItem = (index) => {
    if (inputs[index].new !== true) {
      setChangedInputs(true);
    }
    unselectFlag(inputs[index].name);
    inputs.splice(index, 1);
    setInputs([...inputs]);
  };

  const addItem = () => {
    setInputs([
      ...inputs,
      {
        name: '',
        numberInstallments: 0,
        interestRatePercentages: [],
        new: true,
      },
    ]);
  };

  const changeName = (item, index) => {
    inputs[index].name = item;
    setInputs([...inputs]);
  };

  const changeInstallments = (item, index) => {
    inputs[index].numberInstallments = item;
    setInputs([...inputs]);
  };

  function getAntiFraud() {
    if (fraudCheckPartner.value && fraudCheckPartner.label) {
      return {
        connectorId: fraudCheckPartner.value,
        connectorName: fraudCheckPartner.label,
      };
    }
    return null;
  }

  const updatePayment = () => {
    const formattedInputs = inputs.map((input) => {
      if (input.new) {
        delete input.new;
      }
      return input;
    });
    const antifraud = getAntiFraud();
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: active?.active === undefined ? active : active.active,
      id: data._id,
      paymentType: 'CREDIT_CARD',
      name: namePaymentOption !== '' ? namePaymentOption : null,
      partnerName: partner.label,
      connectorId: partner.value,
      availableCardFlags: formattedInputs,
      installmentMinimumValue: Number(
        removeMask(minValue.toLocaleString().replace(/\D/g, ''))
      ),
      antiFraud: antifraud,
      position,
    };
    verifyCardFlagsValues(item.availableCardFlags);
    dispatch(updatePaymentOptions(item));
    setOptionModal('');
    setChangedInputs(false);
  };

  const postPaymentOption = () => {
    const formattedInputs = inputs.map((input) => {
      if (input.new) {
        delete input.new;
      }
      return input;
    });
    const antifraud = getAntiFraud();
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: true,
      id: data._id,
      paymentType: 'CREDIT_CARD',
      name: namePaymentOption !== '' ? namePaymentOption : null,
      partnerName: partner.label,
      connectorId: partner.value,
      availableCardFlags: formattedInputs,
      installmentMinimumValue: Number(
        removeMask(minValue.toLocaleString().replace(/\D/g, ''))
      ),
      antiFraud: antifraud,
    };
    verifyCardFlagsValues(item.availableCardFlags);
    dispatch(postPaymentOptions(item));
    dispatch(
      getPaymentOptionsRequest({
        id: selectedChannel,
        paymentType: 'CREDIT_CARD',
        sellerId: seller,
      })
    );
    setOptionModal('');
    setChangedInputs(false);
  };

  const deletePayment = () => {
    data.availableCardFlags.map((item) => {
      unselectFlag(item.name);
    });
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: active?.active === undefined ? active : active.active,
      id: data._id,
      paymentType: 'CREDIT_CARD',
      partnerName: partner.label,
      connectorId: partner.value,
      availableCardFlags: inputs,
    };
    dispatch(deletePaymentOptions(item));
  };

  const pickIndex = (edit) => {
    const arrayposition = inputs.map((item, index) => {
      if (item.name === edit.flag) {
        return index;
      }
    });

    function getPosition(value) {
      if (typeof value === 'number') return value;
    }

    const parse = arrayposition.filter(getPosition);

    return parse.length === 0 ? 0 : parse[0];
  };

  useEffect(() => {
    setInputs(data.availableCardFlags);
    setResult(mountTableFlag(data));
    const cardArray =
      data.availableCardFlags !== null
        ? data.availableCardFlags.map((item) => item.name)
        : [];
    dispatch(updateCards(cardArray));
    setPartner({ label: data.partnerName, value: data.connectorId });
    if (data.antiFraud !== null) {
      setChecked(true);
      setFraudCheckPartner({
        label: data.antiFraud?.connectorName,
        value: data.antiFraud?.connectorId,
      });
    }
    setMinValue(data.installmentMinimumValue);
  }, [data]);

  function getPayment() {
    dispatch(
      getPaymentOptionsRequest({
        id: channel,
        paymentType: 'CREDIT_CARD',
        sellerId: seller,
      })
    );
  }

  function replicateDefaultSellerConfig() {
    const item = {
      id: data._id,
      salesChannelId: channel,
      sellerId: seller,
      paymentType: 'CREDIT_CARD',
    };

    dispatch(replicateDefaultSellerConfigs(item));

    setReplicateSellerDefault(false);
  }

  function replicateDefaultSellerSpecificConfig() {
    const item = {
      salesChannelId: channel,
      sellerDefaultId: defId,
      sellerSpecificId: seller,
      paymentType: 'CREDIT_CARD',
    };

    dispatch(replicateDefaultSellerConfigsToSpecific(item));

    setReplicateSellerDefaultToSpecific(false);
  }

  function allUsedCards() {
    const x = inputs
      .map((item) => {
        if (item.new) {
          return item.name;
        }
      })
      .filter((item) => item !== undefined);

    x.map((item) => unselectFlag(item));
  }

  useEffect(() => {
    const item = {
      availableCardFlags: inputs,
      updatedAt: data.updatedAt,
      createdAt: data.createdAt,
      _id: data._id,
      salesChannelId: data.salesChannelId,
      active: active?.active === undefined ? active : active.active,
      paymentType: 'CREDIT_CARD',
      partnerName:
        data.partnerName === 'Novo Parceiro' ? partner : data.partnerName,
      accountId: data.accountId,
    };
    setResult(mountTableFlag(item));
  }, [inputs]);

  const cards = [
    { value: 'Visa', label: 'Visa' },
    { value: 'Mastercard', label: 'Mastercard' },
    { value: 'Elo', label: 'Elo' },
    { value: 'Hiper', label: 'Hiper' },
    { value: 'Hipercard', label: 'Hipercard' },
    { value: 'JCB', label: 'JCB' },
    { value: 'Banescard', label: 'Banescard' },
    { value: 'American Express', label: 'American Express' },
    { value: 'Aura', label: 'Aura' },
  ];

  function selectCards(item) {
    return !cardsInUse.usedList.includes(item.value);
  }

  const usedCards = cards.filter((item) => selectCards(item));

  const parcels = [
    { value: 1, label: '1x' },
    { value: 2, label: '2x' },
    { value: 3, label: '3x' },
    { value: 4, label: '4x' },
    { value: 5, label: '5x' },
    { value: 6, label: '6x' },
    { value: 7, label: '7x' },
    { value: 8, label: '8x' },
    { value: 9, label: '9x' },
    { value: 10, label: '10x' },
    { value: 11, label: '11x' },
    { value: 12, label: '12x' },
  ];

  const setPosition = (e, index) => {
    const newArr = [...percentages];
    newArr[index] = e;

    setPercentages(newArr);
  };

  const changeInputs = (e, index) => {
    const newArr = [...inputs];
    newArr[index].interestRatePercentages = percentages;
    setInputs(newArr);
  };

  const alterarTamanho = (e) => {
    const newArr = new Array(e);
    newArr.fill(0);
    setPercentSelect(newArr);
  };

  const numberMask = (value) => {
    return value >= 0 && value <= 100 ? Number(value).toFixed(3) : '';
  };

  const SetModal = (rowData: any) => {
    alterarTamanho(
      data.availableCardFlags[pickIndex(rowData)] !== undefined
        ? data.availableCardFlags[pickIndex(rowData)].numberInstallments
        : inputs[pickIndex(rowData)].numberInstallments
    );
    setPercentages(inputs[pickIndex(rowData)].interestRatePercentages);
    setIndex(pickIndex(rowData));

    // eslint-disable-next-line no-sequences
    return setShow(true), ScrollToTop();
  };

  const fradCheckConnectors = connectors.filter(
    (item) => item.fraudCheck === 'FRAUD-CHECK'
  );

  return (
    <InfraAccordion
      title={partner.label !== '' ? partner.label : data.partnerName}
      theme="light"
      headColor
      expanded
    >
      <S.AccordionContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <S.PartnerInput width="45">
            <Typography
              align="left"
              variant="h5"
              variantion="h5_small"
              weight="bold"
            >
              Nome de Exibição
            </Typography>
            <InfraTextField
              height="medium"
              inputStyle="normal"
              onChange={(e) => {
                setNamePaymentOption(e.target.value);
                setChangedInputs(true);
              }}
              placeholder="Cartão de Crédito"
              type="text"
              value={namePaymentOption}
              position="start"
            />
          </S.PartnerInput>
          <S.PartnerInput width="45">
            <Typography
              align="left"
              variant="h5"
              variantion="h5_small"
              weight="bold"
            >
              Nome do Parceiro
            </Typography>
            <InfraSelect
              size="medium"
              handlerClick={(e) => {
                setPartner(e);
                setChangedInputs(true);
              }}
              items={connectors}
              placeholder={partner.label}
            />
          </S.PartnerInput>

          <S.PartnerInput width="28">
            <Typography
              align="left"
              variant="h5"
              variantion="h5_small"
              weight="bold"
            >
              Valor Minimo por parcela
            </Typography>
            <CurrencyInput
              onChange={(e) => {
                setChangedInputs(true);
                setMinValue(e.target.value);
              }}
              value={minValue}
              placeholder="R$ 0,00"
              autoComplete="none"
              format={formatCurrency}
            />
          </S.PartnerInput>
          {!data.new ? (
            <S.DeleteContainer>
              <S.DeleteButton
                onClick={() => {
                  ScrollToTop();
                  setTextInfo('Deseja realmente excluir esse parceiro?');
                  setUserAction('excluir');
                  setShowAlert(true);
                }}
              >
                <Typography
                  align="left"
                  variant="h6"
                  variantion="h6_small"
                  weight={600}
                  color="#FA878F"
                >
                  Excluir parceiro
                </Typography>
                <X size={20} color="#FA878F" />
              </S.DeleteButton>
            </S.DeleteContainer>
          ) : (
            ''
          )}
        </div>

        <InfraModal
          width="450"
          theme="light"
          show={replicateSellerDefault}
          onClose={() => {
            setReplicateSellerDefault(false);
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja replicar as configurações de seller default
            para todos os sellers?
          </Typography>
          <S.ButtonContainer>
            <div
              style={{
                width: '100%',
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => setReplicateSellerDefault(false)}
                modal
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                modal
                color="#414F62"
                onClick={() => replicateDefaultSellerConfig()}
              >
                Salvar
              </S.Button>
            </div>
          </S.ButtonContainer>
        </InfraModal>

        <InfraModal
          width="450"
          theme="light"
          show={replicateSellerDefaultToSpecific}
          onClose={() => {
            setReplicateSellerDefaultToSpecific(false);
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja replicar as configurações de seller default
            para apenas este seller?
          </Typography>
          <S.ButtonContainer>
            <div
              style={{
                width: '100%',
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => setReplicateSellerDefaultToSpecific(false)}
                modal
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                modal
                color="#414F62"
                onClick={() => replicateDefaultSellerSpecificConfig()}
              >
                Salvar
              </S.Button>
            </div>
          </S.ButtonContainer>
        </InfraModal>

        <Separator />
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '5px 0',
          }}
        >
          <S.SwitchContainer margin="12px 0" width="49%">
            <InfraSwitch
              checked={checked}
              onChange={() => {
                setChecked(!checked);
                setChangedInputs(true);
              }}
              disabled={!fradCheckConnectors.length}
            />
            <Typography
              align="left"
              variant="h6"
              variantion="h6"
              weight="regular"
              color="#414F62"
              margin="0 0 0 8px"
            >
              {!fradCheckConnectors.length
                ? 'Nenhum parceiro antifraude configurado'
                : 'Habilitar análise de antifraude'}
            </Typography>
          </S.SwitchContainer>
          {checked ? (
            <S.PartnerInput width="45">
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weight="bold"
              >
                Parceiro Antifraude
              </Typography>
              <InfraSelect
                size="medium"
                handlerClick={(e) => {
                  setFraudCheckPartner(e);
                  setChangedInputs(true);
                }}
                items={fradCheckConnectors}
                placeholder={
                  data.antiFraud !== null
                    ? data.antiFraud?.connectorName
                    : 'Selecione um parceiro'
                }
              />
            </S.PartnerInput>
          ) : (
            ''
          )}
        </div>
        <Separator />
        {inputs?.map((_, i) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            key={i}
          >
            <S.PartnerInput width="45">
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weight="bold"
              >
                Bandeira
              </Typography>
              <InfraSelect
                handlerClick={(e) => {
                  !inputs[i].name || unselectFlag(inputs[i].name);
                  changeName(e.value, i);
                  setChangedInputs(true);
                  dispatch(updateCards([e.value]));
                }}
                items={usedCards}
                placeholder={inputs[i].name}
                size="medium"
              />
            </S.PartnerInput>
            <S.PartnerInput width="45">
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weight="bold"
              >
                Número Máximo de Parcelas
              </Typography>
              <InfraSelect
                handlerClick={(e) => {
                  changeInstallments(e.value, i);
                  setChangedInputs(true);
                }}
                items={parcels}
                placeholder={inputs[i].numberInstallments}
                size="medium"
              />
            </S.PartnerInput>
            <S.DeleteIconContainer onClick={() => deleteItem(i)}>
              <MinusCircle size={20} color="#414F62" />
            </S.DeleteIconContainer>
          </div>
        ))}
        <S.AddFlagContainer onClick={() => addItem()}>
          <PlusCircle size={20} color="#414F62" />
          <Typography
            align="left"
            variant="h5"
            variantion="h5_small"
            weight={600}
            color="#414F62"
            margin="0 10px"
          >
            Adicionar bandeira
          </Typography>
        </S.AddFlagContainer>

        <InfraModal
          width="450"
          theme="light"
          show={show}
          onClose={() => {
            setShow(false);
          }}
        >
          <S.divInfraTexts>
            <Typography
              align="center"
              variant="h5"
              variantion="h5"
              weight={400}
              color="#414F62"
              margin="0 0 16px 0"
            >
              Mude o valor para trocar a porcentagem de juros na parcela.
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {percentSelect.map((_, index) => {
                const percent: number = percentages[index]
                  ? percentages[index]
                  : 0;
                const value = parseFloat(String(percent)).toFixed(2);

                return (
                  <div
                    key={index}
                    style={{ position: 'relative', width: '40%' }}
                  >
                    <S.PartnerInput style={{ width: '100%' }}>
                      <Typography
                        align="left"
                        variant="h5"
                        variantion="h5_small"
                        weight="bold"
                      >
                        {`${index + 1}X`}
                      </Typography>
                      <NumberFormatInput
                        onChange={(e) => {
                          setPosition(parseFloat(e.target.value), index);
                          setChangedInputs(true);
                        }}
                        value={value}
                        format={(v: string) => percentMask(v, 100)}
                        valueIsNumericString
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: '42%',
                          right: '30%',
                        }}
                      >
                        %
                      </div>
                    </S.PartnerInput>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <InfraButton
                style={{
                  backgroundColor: '#7676FF',
                  padding: '20px 40px 20px 40px',
                }}
                icon="admin"
                outline={false}
                onClick={() => {
                  setShow(false);
                  changeInputs(percentages, indice);
                }}
              >
                Confirmar
              </InfraButton>

              <InfraButton
                style={{
                  backgroundColor: '#ffffff',
                  color: '#000',
                  borderRadius: '5px',
                  border: '1px solid #000',
                  padding: '20px 40px 20px 40px',
                }}
                icon="admin"
                outline={false}
                onClick={() => {
                  setShow(false);
                }}
              >
                Cancelar
              </InfraButton>
            </div>
          </S.divInfraTexts>
        </InfraModal>
        <div style={{ overflow: 'auto', width: '100%' }}>
          <Table data={result} func={(row) => SetModal(row)} />
        </div>
        <InfraModal
          width="450"
          theme="light"
          show={optionModal}
          onClose={() => {
            setOptionModal('');
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja {optionModal} as alterações
          </Typography>
          <S.ButtonContainer>
            {optionModal === 'salvar' ? (
              <div
                style={{
                  width: '100%',
                  marginTop: '15px',
                  justifyContent: 'space-around',
                  display: 'flex',
                }}
              >
                <S.Button
                  icon="X"
                  iconLeft
                  outline
                  size="medium"
                  color="#414F62"
                  onClick={() => {
                    setOptionModal('');
                    setChangedInputs(false);
                  }}
                  modal
                >
                  Cancelar
                </S.Button>
                <S.Button
                  icon="Check"
                  iconLeft
                  size="medium"
                  modal
                  color="#414F62"
                  onClick={data.new ? postPaymentOption : updatePayment}
                >
                  Salvar
                </S.Button>
              </div>
            ) : (
              <div
                style={{
                  width: '100%',
                  marginTop: '15px',
                  justifyContent: 'space-around',
                  display: 'flex',
                }}
              >
                <S.Button
                  icon="X"
                  iconLeft
                  outline
                  size="medium"
                  color="#414F62"
                  onClick={() => {
                    setOptionModal('');
                    setChangedInputs(false);
                  }}
                  modal
                >
                  Não
                </S.Button>
                <S.Button
                  icon="Check"
                  iconLeft
                  size="medium"
                  color="#414F62"
                  onClick={() => {
                    getPayment();
                    allUsedCards();
                    setChangedInputs(false);
                  }}
                  modal
                >
                  Sim
                </S.Button>
              </div>
            )}
          </S.ButtonContainer>
        </InfraModal>
        <InfraModal
          width="450"
          theme="light"
          show={showAlert}
          onClose={() => {
            setShowAlert(false);
            setTextInfo(
              'Por favor verifique se os dados estão preenchidos corretamente.'
            );
            setUserAction('salvar');
          }}
        >
          <S.divInfraImage>
            <Info size={84} color="#FF0000" />
          </S.divInfraImage>
          <S.divInfraTexts>
            <Typography
              align="center"
              variant="h5"
              variantion="h5"
              weight={400}
              color="#414F62"
              margin="0 0 16px 0"
            >
              {textInfo}
            </Typography>
          </S.divInfraTexts>
          <S.divInfraButtons>
            <div />
            <InfraButton
              style={{
                backgroundColor: '#ffffff',
                color: '#000',
                borderRadius: '5px',
                border: '1px solid #000',
                padding: '20px 60px 20px 60px',
              }}
              icon="admin"
              outline={false}
              onClick={() => {
                setShowAlert(false);
              }}
            >
              Cancelar
            </InfraButton>
            {userAction === 'excluir' ? (
              <InfraButton
                style={{
                  backgroundColor: '#7676FF',
                  padding: '20px 60px 20px 60px',
                }}
                icon="admin"
                outline={false}
                onClick={() => {
                  deletePayment();
                  setShowAlert(false);
                }}
              >
                Sim
              </InfraButton>
            ) : (
              ''
            )}
            <div />
          </S.divInfraButtons>
        </InfraModal>
        <S.ButtonContainer>
          {!def && (
            <S.DefaultButtonContainer
              onClick={() => setReplicateSellerDefaultToSpecific(true)}
            >
              <S.DefaultSellerButton size="medium" color="#414F62" modal>
                Replicar configurações de Seller Default para um Seller
                específico
              </S.DefaultSellerButton>
              <ArrowUUpLeft size={20} color="#414F62" />
            </S.DefaultButtonContainer>
          )}
          {def && (
            <S.DefaultButtonContainer
              onClick={() => setReplicateSellerDefault(true)}
            >
              <S.DefaultSellerButton size="medium" color="#414F62" modal>
                Replicar configurações de Seller Default para todos os Sellers
              </S.DefaultSellerButton>
              <ArrowUUpLeft size={20} color="#414F62" />
            </S.DefaultButtonContainer>
          )}
          {(changedInputs || data.new) && (
            <>
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => {
                  ScrollToTop();
                  setOptionModal('cancelar');
                }}
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                color="#414F62"
                onClick={() => {
                  ScrollToTop();
                  verifyCardFlags(inputs)
                    ? setShowAlert(true)
                    : Number(
                        removeMask(minValue.toLocaleString().replace(/\D/g, ''))
                      ) >= minInstallmentValue
                    ? setOptionModal('salvar')
                    : errorModal();
                }}
              >
                Salvar
              </S.Button>
            </>
          )}
        </S.ButtonContainer>
      </S.AccordionContainer>
    </InfraAccordion>
  );
}
