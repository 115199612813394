import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApi } from '../../../Services/api';

import { setError } from '../Error/actions';
import { reprocessPaymentSuccess } from './actions';

export function* reprocessPayment(payload) {
  try {
    const body = payload.payload;
    const response = yield call(
      baseApi.post,
      `payments/reprocess/${body.paymentId}`
    );

    yield put(reprocessPaymentSuccess(response.data.data));
  } catch (err) {
    console.log(err); 
    yield put(
      setError({
        show: true,
        message: err.message,
      })
    );
  }
}

export default all([takeLatest(Types.REPROCESS_PAYMENT, reprocessPayment)]);
