import { TrashSimple, Warning } from 'phosphor-react';
import {
  InfraTable,
  InfraTableColumn,
  InfraModal,
} from '@infralabs/design-system';
import { useState } from 'react';
import { Typography } from '../../Components/typography';
import * as S from './styles';

export function LogsTable() {
  const [show, setShow] = useState(false);
  const [table, setTable] = useState([]);

  return (
    <S.ConectorsContainer logs>
      <InfraModal
        width="450"
        theme="light"
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <S.ModalContainer>
          <TrashSimple size={42} color="#FA878F" />

          <Typography
            align="center"
            variant="h6"
            variantion="h6_small"
            weight={600}
            color="#414F62"
          >
            Você realmente deseja
          </Typography>
          <Typography
            align="center"
            variant="h6"
            variantion="h6_small"
            weight={600}
            color="#414F62"
          >
            excluir os Logs?
          </Typography>
          <S.ButtonContainer>
            <S.Button
              outline
              size="medium"
              color="#FA878F"
              onClick={() => setShow(false)}
            >
              Cancelar
            </S.Button>
            <S.Button
              size="medium"
              color="#FA878F"
              onClick={() => {
                setTable([]);
                setShow(false);
              }}
            >
              Excluir
            </S.Button>
          </S.ButtonContainer>
        </S.ModalContainer>
      </InfraModal>
      <S.LogButtonsContainer>
        <S.LogsButton size="medium" onClick={() => console.log('hi')}>
          Mostrar Logs
        </S.LogsButton>
        <S.LogsButton
          size="medium"
          onClick={() => {
            table.length ? setShow(true) : '';
          }}
        >
          Excluir Logs
        </S.LogsButton>
      </S.LogButtonsContainer>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '20px',
        }}
      >
        {table.length ? (
          <div style={{ width: '100%' }}>
            <InfraTable value={table} theme="default">
              <InfraTableColumn field="data" header="Data" align="left" />
              <InfraTableColumn align="left" field="proof" header="Evidência" />
            </InfraTable>
          </div>
        ) : (
          <>
            <Warning size={32} color="#414F62" />
            <Typography
              align="center"
              variant="h6"
              variantion="h6_small"
              weight={600}
              color="#414F62"
              margin="0 0 16px 0"
            >
              Nenhum log encontrado
            </Typography>
          </>
        )}
      </div>
    </S.ConectorsContainer>
  );
}
