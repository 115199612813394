import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApi } from '../../../Services/api';
import { setError } from '../Error/actions';

import { getConnectorsSuccess } from './actions';

export function* getConnectors() {
  try {
    const response = yield call(baseApi.get, '/connectors');

    yield put(getConnectorsSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* editConnectors(payload) {
  try {
    yield call(
      baseApi.put,
      `/connectors/${payload.payload[0]}`,
      payload.payload[1]
    );
  } catch (err) {
    console.log(err);
  }
}

export function* createConnector(payload) {
  try {
    yield call(baseApi.post, `/connectors`, payload.payload);
  } catch (err) {
    console.log(err);
    yield put(
      setError({
        show: true,
        message: 'Hmm, algo deu errado ao salvar os dados.',
      })
    );
  }
}

export function* getConnectorsById(payload) {
  try {
    const response = yield call(baseApi.get, `/connectors/${payload.payload}`);

    yield put(getConnectorsSuccess(response.data.data));
  } catch (err) {
    console.log(err);
    yield put(
      setError({
        show: true,
        message: 'Não conseguimos buscar os conectores solicitados.',
      })
    );
  }
}

export function* changeStatus(payload) {
  try {
    const response = yield call(
      baseApi.patch,
      `/connectors/${payload.payload.id}/${payload.payload.type}/${payload.payload.active}`
    );
    yield put(getConnectorsSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export default all([
  takeLatest(Types.GET_CONNECTOR, getConnectors),
  takeLatest(Types.GET_CONNECTOR_BY_ID, getConnectorsById),
  takeLatest(Types.PUT_CONNECTOR_BY_ID, editConnectors),
  takeLatest(Types.POST_CONNECTOR, createConnector),
  takeLatest(Types.CHANGE_STATUS, changeStatus),
]);
