export const Types = {
  GET_CHANNEL: 'GET_CHANNEL',
  GET_CHANNEL_SUCCESS: 'GET_CHANNEL_SUCCESS',
};

export function getChannel() {
  return {
    type: Types.GET_CHANNEL,
  };
}

const initialState = {
  channel: [],
};

export default function GetChannel(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CHANNEL: {
      return {};
    }
    case Types.GET_CHANNEL_SUCCESS: {
      return action.payload;
    }

    default:
      return state;
  }
}
