import styled, { css } from 'styled-components';
import { InfraButton } from '@infralabs/design-system';

export const Button = styled(InfraButton)`
  ${({ outline, color }) => css`
    background-color: ${outline ? 'transparent' : color}!important;
    border: ${outline ? `1px solid ${color}` : 'none'}!important;
    color: ${outline ? color : ''} !important;
    width: 47%;
  `}
`;

export const LogsButton = styled(InfraButton)`
  background-color: #eff1f6 !important;
  color: #414f62;
  border: none !important;
  width: 138px;
  margin-right: 10px;
  font-size: 14px !important;
`;

export const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 0 0;
`;

export const Container = styled.div`
  padding: 10px 40px 10px 90px;
`;

export const BreadCrumbsContainer = styled.div`
  padding: 32px 90px;
`;

export const ConfigContainer = styled.div`
  width: 52%;
  display: flex;
  background-color: #fff;
  border: 1px solid #e7ecf2;
  border-radius: 3px;
  flex-direction: column;
  padding: 24px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 300px;
  padding: 15px 18px 15px 0;
  overflow: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dde5ef;
    opacity: 0.5;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #dde5ef;
    opacity: 1;
  }
`;

export const PartnerInput = styled.div`
  ${(partner) => css`
    display: flex;
    flex-direction: column;
    width: 47%;
    margin-bottom: 20px;
    margin-top: ${partner ? '6px' : 0};
  `}
`;

export const ExchangePartnerInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    width: 47%;
    margin-bottom: 20px;
  `}
`;

export const TestContainer = styled.div`
  width: 45%;
  display: none;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e7ecf2;
  border-radius: 3px;
  padding: 24px 30px;
  flex-direction: column;
`;

export const SwitchContainer = styled.div`
  width: 50%;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
`;

export const ActionSwitchContainer = styled.div`
  width: 90%;
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: space-between;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dde5ef;
`;

export const SwitchItemContainer = styled.div`
  ${({ margin }) => css`
    display: flex;
    width: 100%;
    margin: ${margin};
  `}
`;

export const ConectorsContainer = styled.div`
  ${({ logs }) => css`
    display: none;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: ${logs ? '25px' : '40px 105px'};
    flex-wrap: wrap;
    margin-top: 40px;
    background-color: #fff;
    border: 1px solid #e7ecf2;
    border-radius: 3px;
  `}
`;

export const LogButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;
