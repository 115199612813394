/* eslint-disable jsx-a11y/click-events-have-key-events */
import './style.css';

import { Typography } from '../typography';

function Radio({ selected, onChange, text, value, blocked }) {
  return (
    <div
      className="modern-radio-container"
      onClick={() => {
        onChange(value);
      }}
    >
      <div
        className={`radio-outer-circle ${
          blocked ? 'blocked' : value !== selected && 'unselected'
        }`}
      >
        <div
          className={`radio-inner-circle ${
            value !== selected && 'unselected-circle'
          }`}
        />
      </div>
      <Typography
        align="left"
        variant="h6"
        variantion="h6"
        weight={400}
        color={blocked ? 'rgba(189, 195, 199)' : '#2F3C4E'}
      >
        {text}
      </Typography>
    </div>
  );
}

export default Radio;
