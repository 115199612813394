import { combineReducers } from 'redux';

import ChangeStore from './ducks/changeStore';
import Channel from './ducks/GetChannels';
import Options from './ducks/PaymentOptions';
import Payment from './ducks/Payment';
import CardsInUse from './ducks/CardInUse';
import TransactionsDetails from './ducks/TransactionDetails';
import Connectors from './ducks/Connectors';
import GetConnectors from './ducks/GetConnectors';
import Providers from './ducks/Providers';
import Page from './ducks/ChangePage';
import Error from './ducks/Error';
import Filters from './ducks/Filters';
import Charts from './ducks/Charts';
import approvePayment from './ducks/ApprovePayment';
import csvTransaction from './ducks/Csv';
import salesPlans from './ducks/SalesPlans';
import rollbackPayment from './ducks/RollbackPayment';
import reprocessPayment from './ducks/ReprocessPayment';
import resendWebhook from './ducks/ResendWebhook';

export const Reducers = combineReducers({
  store: ChangeStore,
  channel: Channel,
  page: Page,
  options: Options,
  payment: Payment,
  cardsInUse: CardsInUse,
  transactions: TransactionsDetails,
  connectors: Connectors,
  providers: Providers,
  getConnectors: GetConnectors,
  error: Error,
  charts: Charts,
  settledFilters: Filters,
  approvePayments: approvePayment,
  rollbackPayments: rollbackPayment,
  reprocessPayments: reprocessPayment,
  resendWebhooks: resendWebhook,
  csvTransaction,
  salesPlans,
});

export type RootState = ReturnType<typeof Reducers>;
