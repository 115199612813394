import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApi } from '../../../Services/api';

import { getTotalSalesSuccess, getTotalTransactionsSuccess } from './actions';

import { serialize } from '../../../utils';

export function* getTotalSalesData(payload) {
  try {
    const response = yield call(
      baseApi.get,
      `payments/chart?${serialize(payload.payload)}`
    );

    yield put(getTotalSalesSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getTotalTransactionsData(payload) {
  try {
    const response = yield call(
      baseApi.get,
      `payments/chart/total-transactions?${serialize(payload.payload)}`
    );

    yield put(getTotalTransactionsSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export default all([
  takeLatest(Types.GET_TOTALSALES, getTotalSalesData),
  takeLatest(Types.GET_TOTALTRANSACTIONS, getTotalTransactionsData),
]);
