import { action } from 'typesafe-actions';

import { Types } from './index';

export const createSalesPlansRequest = (payload = {}) =>
  action(Types.CREATE_SALES_PLANS_REQUEST, payload);

export const createSalesPlansSuccess = (payload = {}) =>
  action(Types.CREATE_SALES_PLANS_SUCCESS, payload);

export const updateSalesPlansRequest = (payload = {}) =>
  action(Types.UPDATE_SALES_PLANS_REQUEST, payload);

export const updateSalesPlansSuccess = (payload) =>
  action(Types.UPDATE_SALES_PLANS_SUCCESS, payload);

export const deleteSalesPlansRequest = (payload = {}) =>
  action(Types.DELETE_SALES_PLANS_REQUEST, payload);

export const deleteSalesPlansRequestSuccess = () =>
  action(Types.DELETE_SALES_PLANS_SUCCESS);

export const setNewSalesPlanToNull = (payload) =>
  action(Types.SET_NEW_SALESPLAN_TO_NULL, payload);
