import styled, { css } from 'styled-components';
import { NumberFormatBase } from 'react-number-format';

export const NumberFormatInput = styled(NumberFormatBase)`
  ${({ error }) => css`
    width: 100%;
    height: 50px;
    border: 1px solid #c6d3de;
    border-radius: 4px;
    padding: 5px 15px;
    border: ${error ? '1px solid #E11D2B' : ''};
  `}
`;
