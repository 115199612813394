import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 10px 40px 10px 90px;
`;

export const BreadCrumbsContainer = styled.div`
  padding: 32px 90px;
`;

export const BackContainer = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 55px;
  cursor: pointer;
`;

export const MethodsCardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const MethodsCard = styled.div`
  ${({ center }) => css`
    width: 210px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    border: 1px solid #e7ecf2;
    height: 100px;
    background-color: #fff;
    border-radius: 3px;
    margin: 32px 19px 0 0;
    align-items: center;
    img {
      width: 40%;
    }
  `}
`;

export const ConectorsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 40px 90px;
  flex-wrap: wrap;
  margin-top: 40px;
  background-color: #fff;
  border: 1px solid #e7ecf2;
  border-radius: 3px;
`;

export const MethodsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px;
`;

export const MethodsFooter = styled.div`
  width: 100%;
  display: flex;
  padding: 0 8px;
  justify-content: space-between;
`;

export const HeaderText = styled.p`
  ${({ underline }) => css`
    color: #c6d3de;
    font-size: 12px;
    cursor: ${underline ? 'pointer' : ''};
    text-decoration: ${underline ? 'underline' : ''};
    white-space: nowrap;
    overflow: hidden;
    max-width: 70%;
    text-overflow: ellipsis;
  `}
`;

export const ActiveText = styled.p`
  ${({ active }) => css`
    color: ${active ? '#45E398' : '#FA878F'};
    font-size: 12px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    max-width: 70%;
    text-overflow: ellipsis;
  `}
`;
