/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable radix */
import { InfraTable, InfraTableColumn } from '@infralabs/design-system';
import { PencilSimple } from 'phosphor-react';

export default function Table({ data, func }) {
  const renderAction = (rowData: any) => {
    return (
      <div>
        <PencilSimple
          className="pencil-simple"
          size={28}
          color="#414F62"
          onClick={() => func(rowData)}
        />
      </div>
    );
  };

  return (
    <InfraTable value={data} theme="default">
      <InfraTableColumn field="flag" header="Bandeira" headerAlign="center" />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel1"
        header="1x"
      />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel2"
        header="2x"
      />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel3"
        header="3x"
      />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel4"
        header="4x"
      />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel5"
        header="5x"
      />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel6"
        header="6x"
      />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel7"
        header="7x"
      />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel8"
        header="8x"
      />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel9"
        header="9x"
      />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel10"
        header="10x"
      />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel11"
        header="11x"
      />
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="parcel12"
        header="12x"
      />
      <InfraTableColumn
        field="action"
        align="center"
        padding="checkbox"
        body={renderAction}
      />
    </InfraTable>
  );
}
