import { Provider } from 'react-redux';
import RoutesComponent from './RoutesComponent';
import Store from '../redux/store';

export default function App(props) {
  const { basePath } = props;
  return (
    <Provider store={Store}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <RoutesComponent basePath={basePath} />
      </div>
    </Provider>
  );
}
