import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 10px 40px 10px 90px;
`;

export const CSVContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableContainer = styled.div`
  span {
    font-size: 0.55rem !important;
  }
`;

export const BreadCrumbsContainer = styled.div`
  padding: 32px 90px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  .MuiPaginationItem-root.Mui-selected {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
`;

export const BackContainer = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 55px;
  cursor: pointer;
`;

export const FilterContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  max-width: 98%;
`;

export const FilterItem = styled.div`
  width: 140px;
  .item {
    font-size: 0.75rem !important;
    padding: 0 !important;
  }

  &
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
    border-radius: 5px;
    display: flex;
    min-width: 163px;
    justify-content: center;
    align-items: center;
  }

  &
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search
    .ant-select-selection-search-input {
    margin-top: 8px;
  }
`;

export const FilterItemDate = styled.div`
  width: 250px;
`;

export const buttonLoader = styled.div`
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
