import * as S from './styles';

function FilterButton({ selected, onChange, name, value, data, blocked }) {
  return (
    <S.Container
      blocked={blocked}
      onClick={() => (!blocked ? onChange(value) : null)}
    >
      <S.filterArea selected={selected}>
        <S.Name selected={selected}>{name}</S.Name>
        <S.Length selected={selected}>{data}</S.Length>
      </S.filterArea>
    </S.Container>
  );
}

export default FilterButton;
