import { all } from 'redux-saga/effects';

import channel from './ducks/GetChannels/sagas';
import paymentOptions from './ducks/PaymentOptions/sagas';
import payment from './ducks/Payment/sagas';
import transaction from './ducks/TransactionDetails/sagas';
import connectors from './ducks/Connectors/sagas';
import getConnectors from './ducks/GetConnectors/sagas';
import providers from './ducks/Providers/sagas';
import Charts from './ducks/Charts/sagas';
import approvePayment from './ducks/ApprovePayment/sagas';
import csvTransaction from './ducks/Csv/sagas';
import salesPlans from './ducks/SalesPlans/sagas';
import rollbackPayment from './ducks/RollbackPayment/sagas';
import reprocessPayment from './ducks/ReprocessPayment/sagas';
import resendWebhook from './ducks/ResendWebhook/sagas';

export default function* rootSaga() {
  return yield all([
    channel,
    paymentOptions,
    payment,
    transaction,
    connectors,
    providers,
    getConnectors,
    Charts,
    approvePayment,
    rollbackPayment,
    reprocessPayment,
    resendWebhook,
    csvTransaction,
    salesPlans,
  ]);
}
