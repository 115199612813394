import axios from 'axios';
import Store from '../redux/store';

const { baseURL } = window.parent.IFC_VARIABLES;

export const baseApi = axios.create({
  baseURL: `${baseURL}/payment/admin`,
  headers: {
    'x-tenant-id': window.parent.IFC_VARIABLES.headers['x-tenant-id'],
    'x-channel-id': window.parent.IFC_VARIABLES.headers['x-channel-id'],
  },
});

export const baseApiMain = axios.create({
  baseURL: `${baseURL}/payment/main`,
  headers: {
    'x-tenant-id': window.parent.IFC_VARIABLES.headers['x-tenant-id'],
    'x-channel-id': window.parent.IFC_VARIABLES.headers['x-channel-id'],
  },
});

baseApi.interceptors.request.use((config) => {
  const { store } = Store.getState();

  const header = {
    'x-tenant-id': window.parent.IFC_VARIABLES.headers['x-tenant-id'],
    'x-channel-id': config['x-channel-id'],
  };

  const mappedHeaders = Object.fromEntries(
    Object.entries(header).filter(([_, value]) => value !== undefined)
  );

  if (store.storeId) {
    config.headers = mappedHeaders;
  }

  return config;
});

baseApiMain.interceptors.request.use((config) => {
  const { store } = Store.getState();

  const header = {
    'x-tenant-id': window.parent.IFC_VARIABLES.headers['x-tenant-id'],
  };

  if (store.storeId) {
    config.headers = header;
  }
  return config;
});
