/* eslint-disable consistent-return */
export const Types = {
  CREATE_SALES_PLANS_REQUEST: 'CREATE_SALES_PLANS_REQUEST',
  CREATE_SALES_PLANS_SUCCESS: 'CREATE_SALES_PLANS_SUCCESS',

  UPDATE_SALES_PLANS_SUCCESS: 'UPDATE_SALES_PLANS_SUCCESS',
  UPDATE_SALES_PLANS_REQUEST: 'UPDATE_SALES_PLANS_REQUEST',

  DELETE_SALES_PLANS_REQUEST: 'DELETE_SALES_PLANS_REQUEST',
  DELETE_SALES_PLANS_SUCCESS: 'DELETE_SALES_PLANS_SUCCESS',

  SET_NEW_SALESPLAN_TO_NULL: 'SET_NEW_SALESPLAN_TO_NULL',
};

export function getSalesPlans() {
  return {
    type: Types.CREATE_SALES_PLANS_REQUEST,
  };
}

const initialState = {
  loading: false,
  newSalesPlan: null,
};

export default function GetSalesPlans(state = initialState, action) {
  switch (action.type) {
    case Types.CREATE_SALES_PLANS_REQUEST: {
      return { ...state, loading: true };
    }
    case Types.CREATE_SALES_PLANS_SUCCESS: {
      return {
        ...state,
        loading: false,
        newSalesPlan: action.payload,
      };
    }
    case Types.UPDATE_SALES_PLANS_REQUEST: {
      state[action.payload.position] = action.payload;
      return state;
    }
    case Types.UPDATE_SALES_PLANS_SUCCESS: {
      return { ...state, loading: false };
    }
    case Types.DELETE_SALES_PLANS_REQUEST: {
      return { ...state };
    }
    case Types.DELETE_SALES_PLANS_SUCCESS: {
      return { ...state };
    }

    case Types.SET_NEW_SALESPLAN_TO_NULL: {
      return { ...state, newSalesPlan: action.payload.value };
    }

    default:
      return state;
  }
}
