import { action } from 'typesafe-actions';

import { Types } from './index';

export const getConnectors = (payload = {}) =>
  action(Types.GET_CONNECTOR, payload);

export const getConnectorsById = (payload = {}) =>
  action(Types.GET_CONNECTOR_BY_ID, payload);

export const putConnector = (payload = {}) =>
  action(Types.PUT_CONNECTOR_BY_ID, payload);

export const getConnectorsSuccess = (payload = {}) =>
  action(Types.GET_CONNECTOR_SUCCESS, payload);

export const createConnector = (payload = {}) =>
  action(Types.POST_CONNECTOR, payload);

export const changeStatus = (payload = {}) =>
  action(Types.CHANGE_STATUS, payload);
