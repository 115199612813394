/* eslint-disable consistent-return */
export const Types = {
  ERROR: 'ERROR',
};

const initialState = {
  show: false,
  message: '',
};

export default function Error(state = initialState, action) {
  switch (action.type) {
    case Types.ERROR: {
      return action.payload;
    }

    default:
      return state;
  }
}
