/* eslint-disable consistent-return */
export const Types = {
  GET_CONNECTOR: 'GET_CONNECTOR',
  GET_CONNECTOR_SUCCESS: 'GET_CONNECTOR_SUCCESS',
  GET_CONNECTOR_BY_ID: 'GET_CONNECTOR_BY_ID',
  PUT_CONNECTOR_BY_ID: 'PUT_CONNECTOR_BY_ID',
  GET_CONNECTOR_BY_ID_SUCCESS: 'GET_CONNECTOR_BY_ID_SUCCESS',
  POST_CONNECTOR: 'POST_CONNECTOR',
  CHANGE_STATUS: 'CHANGE_STATUS',
};

const initialState = {
  options: [],
  loading: true,
};

export default function connectors(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CONNECTOR: {
      return { loading: true };
    }
    case Types.GET_CONNECTOR_SUCCESS: {
      return action.payload;
    }
    case Types.GET_CONNECTOR_BY_ID: {
      return { loading: true };
    }
    case Types.PUT_CONNECTOR_BY_ID: {
      return { ...state };
    }
    case Types.POST_CONNECTOR: {
      return { ...state };
    }
    case Types.CHANGE_STATUS: {
      return { ...state };
    }

    default:
      return state;
  }
}
