export enum ETransactionStatus {
  PROCESSING = 'PROCESSING',
  'PRE-AUTHORIZED' = 'PRE-AUTHORIZED',
  'NOT-AUTHORIZED' = 'NOT-AUTHORIZED',
  'FRAUD-CHECK-OK' = 'FRAUD-CHECK-OK',
  'FRAUD-CHECK-ERROR' = 'FRAUD-CHECK-ERROR',
  'WAITING-APPROVAL' = 'WAITING-APPROVAL',
  'CHECKING-PAYMENT' = 'CHECKING-PAYMENT',
  APPROVED = 'APPROVED',
  REFUNDED = 'REFUNDED',
  PARTIAL_REFUNDED = 'PARTIAL_REFUNDED',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
}