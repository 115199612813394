const reg =
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
const regPassword =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,8}$/;

export function isEmail(email: string) {
  return reg.test(email);
}

export function isPassword(password: string) {
  return regPassword.test(password);
}

export function cleanDocument(document: string) {
  return document.replace(/[^0-9]/g, '');
}
export function mobileFormat(mobilePhone: string) {
  return mobilePhone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
}
export function personDocumentFormat(personDocument: string) {
  return personDocument.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}
export function documentFormat(document: string) {
  return document.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5'
  );
}
export const formatCurrencyWithoutSymbol = (value: string) =>
  new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(typeof value === 'string' ? parseFloat(value) : value);
export function phoneFormat(phone: string) {
  return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
}
export function zipCodeFormat(zipCode: string) {
  return zipCode.replace(/(\d{5})(\d{2})/, '$1-$2');
}
export const formatCurrency = (value: number | string) =>
  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
    typeof value === 'string' ? parseFloat(value) : value
  );
export const cleanCurrency = (currency: string) =>
  parseFloat(currency.replace(/[^,0-9/-]/g, '').replace(',', '.'));

export const capitalizeEachFirstLetter = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
};

export const maskStateSubscription = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const maskDocument = [
  /[0-9]/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
export const maskPersonDocument = [
  /[0-9]/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
export const maskMobilePhone = [
  '(',
  /[0-9]/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const maskPhone = [
  '(',
  /[0-9]/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const maskZipCode = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
];

export const toUTC = (date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

export const dateFormatIso = (str) =>
  new Date(str).toISOString().replace(/T.+$/g, '');

export const dateFormat = (str, locale = 'pt-br') =>
  toUTC(new Date(str)).toLocaleDateString(locale);

export const dateFormatWeekDay = (str) => {
  const days = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];

  return days[toUTC(new Date(str)).getDay()];
};

export const dateTimeFormat = (str, useUTC = true, locale = 'pt-br') =>
  !str
    ? '-'
    : (useUTC ? toUTC(new Date(str)) : new Date(str)).toLocaleString(locale);

export const serialize = (obj) => {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&');
};
