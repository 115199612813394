import { action } from 'typesafe-actions';

import { Types } from './index';

export const rollbackPayment = (payload) =>
  action(Types.ROLLBACK_PAYMENT, payload);

export const rollbackPaymentSuccess = (payload) =>
  action(Types.ROLLBACK_PAYMENT_SUCCESS, payload);


  
