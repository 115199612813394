import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfraInputCurrency } from '@infralabs/design-system';
import { useHistory } from 'react-router-dom';
import { Button } from '../../Components/button';
import { Typography } from '../../Components/typography';
import { RootState } from '../../redux/reducers';
import * as S from './styles';
import { rollbackPayment } from '../../redux/ducks/RollbackPayment/actions';
import { Loader } from '../../Components/loader';

export default function PayBack() {
  const [paymentRefunded, setPaymentRefunded] = useState(false);
  const [amountRefunded, setAmountRefunded] = useState(0);
  const history = useHistory();
  const { transactions, rollbackPayments } = useSelector(
    (state: RootState) => state
  );
  const { transaction } = transactions;
  const dispatch = useDispatch();

  let formattedValue = transaction.value;
  formattedValue = formattedValue
    .replace(/R\$\s?/, '')
    .replace('.', '')
    .replace(',', '.');
  formattedValue = parseFloat(formattedValue);

  useEffect(() => {
    if (!rollbackPayments.loading) {
      history.push('/transactions');
    }
    rollbackPayments.loading = true;
  }, [rollbackPayments]);

  const rollback = (params: { paymentId; transactionId; amount }) => {
    setPaymentRefunded(true);
    dispatch(rollbackPayment(params));
  };

  return (
    <>
      {paymentRefunded ? (
        <div
          style={{
            width: '100%',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </div>
      ) : (
        <div>
          <S.PaybackGrid>
            <S.PaybackTransaction>
              <Typography
                align="left"
                variant="h4"
                variantion="h4_small_bold"
                weight="bold"
              >
                IDs de Transação
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                style={{ margin: '0 0 32px 0' }}
              >
                {transaction.transactionId}
              </Typography>
              <Typography
                align="left"
                variant="h4"
                variantion="h4_small_bold"
                weight="bold"
              >
                Forma de Pagamento
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                style={{ margin: '0 0 32px 0' }}
              >
                {transaction.paymentType}
              </Typography>
              <Typography
                align="left"
                variant="h4"
                variantion="h4_small_bold"
                weight="bold"
              >
                Valor Disponível pra Estorno{' '}
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                style={{ margin: '0 0 32px 0' }}
              >
                {transaction.status === 'REFUNDED' ||
                transaction.status === 'ERROR'
                  ? transaction.status
                  : transaction.value}
              </Typography>
            </S.PaybackTransaction>
            <S.PaybackType>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  align="left"
                  variant="h4"
                  variantion="h4_small_bold"
                  weight="bold"
                >
                  Estorno:
                </Typography>
              </div>
              <S.ValueContainer>
                <div style={{ width: '260px' }}>
                  <Typography
                    align="left"
                    variant="h5"
                    variantion="h5_small_bold"
                  >
                    Valor a ser estornado
                  </Typography>
                  <InfraInputCurrency
                    onValueChange={(value) =>
                      setAmountRefunded(
                        parseFloat(value.toString().replace(',', '.'))
                      )
                    }
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    decimalSeparator=","
                    decimalsLimit={2}
                    defaultValue={0}
                    name="amountRefunded"
                    disabled={
                      transaction.status === 'REFUNDED' ||
                      transaction.status === 'ERROR'
                    }
                  />
                </div>
              </S.ValueContainer>
            </S.PaybackType>
          </S.PaybackGrid>
          <S.PaybackValue>
            <div />
            <Button
              size="medium"
              color="#414F62"
              onClick={() =>
                rollback({
                  paymentId: transaction.paymentId,
                  transactionId: transaction.transactionId,
                  amount: Number((amountRefunded * 100).toFixed(0)),
                })
              }
              disabled={
                transaction.status === 'REFUNDED' ||
                transaction.status === 'ERROR' ||
                amountRefunded > formattedValue ||
                amountRefunded <= 0
              }
            >
              Estornar
            </Button>
          </S.PaybackValue>
        </div>
      )}
    </>
  );
}
