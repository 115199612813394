import { action } from 'typesafe-actions';

import { Types } from './index';

export const resendWebhook = (payload) =>
  action(Types.RESEND_WEBHOOK, payload);

export const resendWebhookSuccess = (payload) =>
  action(Types.RESEND_WEBHOOK_SUCCESS, payload);


  
