export enum PaymentType {
  INVOICE = 'BOLETO',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  PIX = 'PIX',
  PAYPAL = 'PAYPAL',
  CHANGE_CARD = 'CHANGE_CARD',
  GIFT_CARD = 'GIFT_CARD',
  CASHBACK = 'CASHBACK',
  VOUCHER = 'VOUCHER',
  EXCHANGE_VOUCHER = 'EXCHANGE_VOUCHER',
}