import CustomerData from './components/customerData';
import Address from './components/address';
import Transaction from './components/transaction';
import Items from './components/items';

export default function General({ transactions }) {
  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '30% auto',
          columnGap: '20px',
        }}
      >
        <CustomerData data={transactions.data?.customer} />

        <Address data={transactions.data} />
      </div>
      <Transaction
        data={transactions.data}
        paymentId={transactions.transaction.paymentId}
      />
      <Items data={transactions.data} />
    </>
  );
}
