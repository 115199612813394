import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 32px 32px 10px 32px;
`;

export const BreadCrumbsContainer = styled.div`
  padding: 32px;
`;

export const ChartsContainer = styled.div`
  padding: 16px;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  margin-top: 32px;
`;

export const PaymentFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  padding-left: 24px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 16px;
  padding-left: 16px;

  justify-content: ${(props) => (props.marginTop ? 'space-between' : null)};
  margin-top: ${(props) => (props.marginTop ? 24 : 0)}px;
`;

export const VerticalSeparator = styled.div`
  border-left: 1px solid #dedede;
  height: 24px;
  align-self: center;
`;

export const DualCharts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GridCharts = styled.div`
  display: grid;
  grid-template-columns: 65.4% 33.4%;
  column-gap: 1.2%;
`;
