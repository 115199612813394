/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CaretRight } from 'phosphor-react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';

function BreadCrumbs({ options }) {
  const history = useHistory();
  return (
    <S.Container>
      {options.map((option, i) => (
        <div key={i}>
          {i !== 0 ? (
            <CaretRight style={{ marginRight: 10 }} color="#414f62" size={14} />
          ) : (
            ''
          )}
          <S.Text
            disabled={option.disabled}
            onClick={() => (!option.disabled ? history.push(option.path) : '')}
          >
            {option.name}
          </S.Text>
        </div>
      ))}
    </S.Container>
  );
}

export default BreadCrumbs;
