import { XCircle, CheckCircle } from 'phosphor-react';
import { Typography } from '../../Components/typography';
import { Button } from '../../Components/button';
import * as S from './styles';
import { PaymentType } from '../../utils/payment-type.enum';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reprocessPayment } from '../../redux/ducks/ReprocessPayment/actions';
import { resendWebhook } from '../../redux/ducks/ResendWebhook/actions';
import { RootState } from '../../redux/reducers';
import { useHistory } from 'react-router-dom';
import { ETransactionStatus } from '../../utils/transaction-status.enum';
import { Loader } from '../../Components/loader';

export default function TimeLine({ transactions }) {
  const [paymentReprocess, setPaymentReprocess] = useState(false);
  const [paymentResendWebhook, setPaymentResendWebhook] = useState(false);
  const history = useHistory();
  const { reprocessPayments, resendWebhooks } = useSelector(
    (state: RootState) => state
  );

  let lastStatus = null;
  let isCreditCard = false;
  let paymentId = null;

  const dispatch = useDispatch();

  const treatDate = (data) => {
    const date = new Date(data);

    const dateParsed = new Intl.DateTimeFormat('br', {
      dateStyle: 'short',
      timeStyle: 'medium',
    }).format(new Date(date));

    return dateParsed;
  };

  useEffect(() => {
    if (!reprocessPayments.loading) {
      setTimeout(() => {
        history.push('/transactions');
      }, 5000);
    }
    reprocessPayments.loading = true;
  }, [reprocessPayments]);

  const reprocess = (params: { paymentId }) => {
    setPaymentReprocess(true);
    dispatch(reprocessPayment(params));
  };

  useEffect(() => {
    if (!resendWebhooks.loading) {
      setTimeout(() => {
        history.push('/transactions');
      }, 3000);
    }
    resendWebhooks.loading = true;
  }, [resendWebhooks]);

  const resend = (params: { paymentId }) => {
    setPaymentResendWebhook(true);
    dispatch(resendWebhook(params));
  };

  return (
    <>
      {paymentReprocess ? (
        <div
          style={{
            width: '100%',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </div>
      ) : (
        <div>
          {transactions !== undefined && transactions.length > 0
            ? transactions.map((item, index) => {
                if (!paymentId) {
                  paymentId = item.paymentId;
                }

                if (!lastStatus) {
                  lastStatus = item.status;
                }

                if (
                  !isCreditCard &&
                  item.paymentOptionType === PaymentType.CREDIT_CARD
                ) {
                  isCreditCard = true;
                }

                return (
                  <div style={{ marginBottom: '36px' }} key={index}>
                    <S.Container>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '0 12px 0 0',
                        }}
                      >
                        {item.errorMessage !== null ? (
                          <XCircle size={37} color="#FA878F" />
                        ) : (
                          <CheckCircle size={37} color="#45E398" />
                        )}
                      </div>
                      <Typography
                        align="left"
                        variant="h4"
                        variantion="h4_small"
                        weigth="bold"
                        color="#2F3C4E"
                      >
                        {treatDate(item.createdAt)}:
                      </Typography>
                      <Typography
                        style={{ padding: '2px 0 0 5px' }}
                        align="left"
                        variant="h5"
                        variantion="h5_small"
                        weigth="bold"
                        color="#879BB4"
                      >
                        {item.status}
                      </Typography>
                    </S.Container>
                    {item.message !== null ? (
                      <S.Content>
                        <Typography
                          align="left"
                          variant="h4"
                          variantion="h4_small"
                          weigth="bold"
                          color="#2F3C4E"
                        >
                          Comentário
                        </Typography>
                        <Typography
                          align="left"
                          variant="h5"
                          variantion="h5_small"
                          weigth="bold"
                          color="#879BB4"
                        >
                          {item.comment}
                        </Typography>
                      </S.Content>
                    ) : null}
                    {item.errorMessage !== null ? (
                      <S.Content>
                        <Typography
                          align="left"
                          variant="h4"
                          variantion="h4_small"
                          weigth="bold"
                          color="#2F3C4E"
                        >
                          Comentário
                        </Typography>
                        <Typography
                          align="left"
                          variant="h5"
                          variantion="h5_small"
                          weigth="bold"
                          color="#879BB4"
                        >
                          {item.errorMessage}
                        </Typography>
                      </S.Content>
                    ) : null}
                    {transactions.length - index === transactions.length ? (
                      <S.Content>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 12px 0 0',
                          }}
                        >
                          {item.webhookMessage !== '' &&
                          item.webhookMessage !== null ? (
                            <XCircle size={37} color="#FA878F" />
                          ) : (
                            <CheckCircle size={37} color="#45E398" />
                          )}
                          <Typography
                            style={{ padding: '2px 0 0 5px' }}
                            align="left"
                            variant="h5"
                            variantion="h5_small"
                            weigth="bold"
                            color="#879BB4"
                          >
                            Detalhes de envio de status:{' '}
                            {item.webhookMessage !== '' &&
                            item.webhookMessage !== null
                              ? item.webhookMessage
                              : 'sucesso.'}
                          </Typography>
                        </div>
                        {paymentResendWebhook ? (
                          <div
                            style={{
                              width: '30%',
                              height: '100px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Loader />
                          </div>
                        ) : (
                          <Button
                            size="small"
                            color="#414F62"
                            onClick={() => resend({ paymentId })}
                          >
                            Reenviar Status
                          </Button>
                        )}
                      </S.Content>
                    ) : null}
                  </div>
                );
              })
            : null}
          {isCreditCard &&
          (lastStatus === ETransactionStatus.PROCESSING ||
            lastStatus === ETransactionStatus['PRE-AUTHORIZED'] ||
            lastStatus === ETransactionStatus['FRAUD-CHECK-OK']) ? (
            <S.PaybackValue>
              <div></div>
              <Button
                size="medium"
                color="#414F62"
                onClick={() => reprocess({ paymentId })}
              >
                Reprocessar
              </Button>
            </S.PaybackValue>
          ) : null}
        </div>
      )}
    </>
  );
}
