/* eslint-disable consistent-return */
export const Types = {
  RESEND_WEBHOOK: 'RESEND_WEBHOOK',
  RESEND_WEBHOOK_SUCCESS: 'RESEND_WEBHOOK_SUCCESS',
};

export function getPaymentOptions() {
  return {
    type: Types.RESEND_WEBHOOK,
  };
}

const initialState = {
  loading: true,
};

export default function GetPaymentOptions(state = initialState, action) {
  switch (action.type) {
    case Types.RESEND_WEBHOOK: {
      return { ...state, loading: true };
    }

    case Types.RESEND_WEBHOOK_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
