/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ArrowUUpLeft, Image, PlusCircle } from 'phosphor-react';
import { Typography } from '../../Components/typography';
import * as S from './styles';
import { RootState } from '../../redux/reducers';
import { getProvidersRequest } from '../../redux/ducks/Providers/actions';
import { ConnectorsRequest } from '../../redux/ducks/GetConnectors/actions';
import { Loader } from '../../Components/loader';
import BreadCrumbs from '../../Components/BreadCrumbs';

export default function Configurations() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { providers, getConnectors } = useSelector((state: RootState) => state);

  useEffect(() => {
    console.log(
      '🚀 ~ file: index.tsx:20 ~ Configurations ~ getConnectors:',
      getConnectors
    );
    dispatch(getProvidersRequest());
    dispatch(ConnectorsRequest());
  }, []);

  useEffect(() => {
    dispatch(ConnectorsRequest());
  }, [providers]);

  return (
    <>
      <S.BreadCrumbsContainer>
        <BreadCrumbs
          options={[
            {
              disabled: false,
              path: '/',
              name: 'Página Inicial',
            },
            {
              disabled: true,
              path: 'https://www.infracommerce.com.br/',
              name: 'Configuração de conectores',
            },
          ]}
        />
      </S.BreadCrumbsContainer>
      <S.Container>
        <Typography align="left" variant="h2" weight={800} color="#2F3C4E">
          Configuração de Conectores
        </Typography>
        <S.BackContainer onClick={() => history.goBack()}>
          <ArrowUUpLeft size={15} color="#45E398" />
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weight="bold"
            color="#45E398"
          >
            Voltar
          </Typography>
        </S.BackContainer>
        {getConnectors?.loading ? (
          <div
            style={{
              width: '100%',
              marginTop: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <S.MethodsCardContainer>
              {getConnectors?.map((connector, i) => (
                <S.MethodsCard key={i}>
                  <S.MethodsHeader>
                    <S.HeaderText title={connector.name}>
                      {connector.name}
                    </S.HeaderText>
                    <S.HeaderText
                      underline
                      onClick={() =>
                        history.push(
                          `/config/edit-payment-method/${connector._id}`
                        )
                      }
                    >
                      Visualizar
                    </S.HeaderText>
                  </S.MethodsHeader>
                  {connector.providerImageUrl === null ? (
                    <Image size={32} color="#C6D3DE" />
                  ) : (
                    <img
                      src={connector.providerImageUrl}
                      alt="connector-logo"
                    />
                  )}
                  <S.MethodsFooter>
                    <S.ActiveText active={connector.active}>
                      {connector.active ? 'Ativo' : 'Inativo'}
                    </S.ActiveText>
                    <S.ActiveText active={connector.isProduction}>
                      {connector.isProduction ? 'Produção' : 'Testes'}
                    </S.ActiveText>
                  </S.MethodsFooter>
                </S.MethodsCard>
              ))}
              <S.MethodsCard
                center
                onClick={() => history.push('/config/new-payment-method')}
              >
                <PlusCircle size={32} color="#c6d3de" />
                <S.HeaderText underline>Criar novo conector</S.HeaderText>
              </S.MethodsCard>
            </S.MethodsCardContainer>

            <S.ConectorsContainer>
              <div
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  align="center"
                  variant="h6"
                  variantion="h6_small"
                  weight={600}
                  color="#414F62"
                  margin="0 0 16px 0"
                >
                  CONECTORES ATIVOS
                </Typography>
              </div>
              {providers.data.map((item, i) => (
                <S.MethodsCard key={i} center>
                  <img src={item.logoUrl} alt="zoop-logo" />
                </S.MethodsCard>
              ))}
            </S.ConectorsContainer>
          </>
        )}
      </S.Container>
    </>
  );
}
