export const Types = {
  GET_TRASACTIONS_DETAILS: 'GET_TRASACTIONS_DETAILS',
  GET_TRASACTIONS_DETAILS_SUCCESS: 'GET_TRASACTIONS_DETAILS_SUCCESS',
  GET_TRASACTIONS_HISTORY_SUCCESS: 'GET_TRASACTIONS_HISTORY_SUCCESS',
};

const initialState = {
  transactionHistory: {},
  transaction: {},
  data: {},
};

export default function GetTransactions(state = initialState, action) {
  switch (action.type) {
    case Types.GET_TRASACTIONS_DETAILS: {
      return { ...state, transaction: action.payload };
    }
    case Types.GET_TRASACTIONS_DETAILS_SUCCESS: {
      return { ...state, data: action.payload };
    }
    case Types.GET_TRASACTIONS_HISTORY_SUCCESS: {
      return { ...state, transactionHistory: action.payload };
    }

    default:
      return state;
  }
}
