/* eslint-disable no-shadow */

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  InfraAccordion,
  InfraModal,
  InfraButton,
} from '@infralabs/design-system';
import { Switch } from 'antd';
import { Info } from 'phosphor-react';
import * as S from '../styles';
import { Separator } from '../../../Components/separator';
import {
  postPaymentOptions,
  updatePaymentOptions,
} from '../../../redux/ducks/PaymentOptions/actions';
import { Typography } from '../../../Components/typography';

enum EPaymentTypes {
  Card = 'CARD_EXTERNAL_PAYMENT',
  Cash = 'CASH_EXTERNAL_PAYMENT',
}

interface ExternalProps {
  data: any;
  channel: string;
  seller: string;
}

function External({ data, channel, seller }: ExternalProps) {
  const dispatch = useDispatch();
  const [card, setCard] = useState(false);
  const [cash, setCash] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (data.length) {
      let helper =
        data.find(
          (dataOptions) => dataOptions.paymentType === EPaymentTypes.Card
        ) || null;

      if (helper !== null) setCard(helper.active);

      helper =
        data.find(
          (dataOptions) => dataOptions.paymentType === EPaymentTypes.Cash
        ) || null;

      if (helper !== null) setCash(helper.active);
    }
  }, []);

  const newExternalPayment = () => {
    const requestCard = {
      active: card,
      paymentType: EPaymentTypes.Card,
      partnerName: 'EXTERNAL',
      salesChannelId: channel,
      sellerId: seller,
    };

    const requestCash = {
      active: cash,
      paymentType: EPaymentTypes.Cash,
      partnerName: 'EXTERNAL',
      salesChannelId: channel,
      sellerId: seller,
    };

    dispatch(postPaymentOptions(requestCard));
    dispatch(postPaymentOptions(requestCash));
  };

  const editExternalPayment = () => {
    const cardHelper =
      data.find(
        (dataOptions) => dataOptions.paymentType === EPaymentTypes.Card
      ) || null;

    const cashHelper =
      data.find(
        (dataOptions) => dataOptions.paymentType === EPaymentTypes.Cash
      ) || null;

    const editCardRequest = {
      id: cardHelper._id,
      active: card,
      partnerName: 'EXTERNAL',
      salesChannelId: cardHelper.salesChannelId,
      sellerId: seller,
      paymentType: 'EXTERNAL_PAYMENT',
    };

    const editCashRequest = {
      id: cashHelper._id,
      active: cash,
      partnerName: 'EXTERNAL',
      salesChannelId: cashHelper.salesChannelId,
      sellerId: seller,
      paymentType: 'EXTERNAL_PAYMENT',
    };

    if (card !== null) dispatch(updatePaymentOptions(editCardRequest));
    if (cash !== null) dispatch(updatePaymentOptions(editCashRequest));
  };

  return (
    <InfraAccordion
      title="Pagamento externo"
      theme="light"
      headColor
      expanded
      id="infraAccordion"
    >
      <S.ExternalOptionsContainer>
        <h3>Cartão: </h3>
        <span>
          <Switch checked={card} onClick={() => setCard(!card)} />
        </span>
      </S.ExternalOptionsContainer>
      <Separator />
      <S.ExternalOptionsContainer>
        <h3>Dinheiro: </h3>
        <span>
          <Switch checked={cash} onClick={() => setCash(!cash)} />
        </span>
      </S.ExternalOptionsContainer>

      <S.ButtonContainer>
        <S.Button
          icon="X"
          iconLeft
          outline
          size="medium"
          color="#414F62"
          onClick={() => {
            console.log('cancelar');
          }}
        >
          Cancelar
        </S.Button>
        <S.Button
          icon="Check"
          iconLeft
          size="medium"
          color="#414F62"
          onClick={() => setModal(true)}
        >
          Salvar
        </S.Button>
      </S.ButtonContainer>
      <InfraModal
        width="450"
        theme="light"
        show={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <S.divInfraImage>
          <Info size={84} color="#7676FF" />
        </S.divInfraImage>
        <S.divInfraTexts>
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Deseja realmente salvar essas modificações ?
          </Typography>
        </S.divInfraTexts>
        <S.divInfraButtons>
          <InfraButton
            style={{
              backgroundColor: '#ffffff',
              color: '#000',
              borderRadius: '5px',
              border: '1px solid #000',
              padding: '20px 60px 20px 60px',
            }}
            icon="admin"
            outline={false}
            onClick={() => {
              setModal(false);
            }}
          >
            Cancelar
          </InfraButton>
          <InfraButton
            style={{
              backgroundColor: '#7676FF',
              padding: '20px 60px 20px 60px',
            }}
            icon="admin"
            outline={false}
            onClick={() => {
              data.length > 0 ? editExternalPayment() : newExternalPayment();
              setModal(false);
            }}
          >
            Sim
          </InfraButton>
        </S.divInfraButtons>
      </InfraModal>
    </InfraAccordion>
  );
}

export default External;
