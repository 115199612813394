import styled from 'styled-components';

export const Card = styled.div`
  min-width: 320px;
  height: fit-content;
  box-shadow: 0px 8px 18px rgba(119, 132, 163, 0.15);
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 30px;
`;
