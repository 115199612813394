/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line
// @ts-nocheck
import { useState } from 'react';
import { Select } from 'antd';
import { Column, Line, Pie } from '@ant-design/plots';
import { useDispatch, useSelector } from 'react-redux';
import { Separator } from '../separator';
import * as S from './styles';
import { RootState } from '../../redux/reducers';
import { getControlDate } from '../../redux/ducks/Charts/actions';

function Charts({
  title,
  data,
  column = false,
  groupedColumn = false,
  dualLine = false,
  donut = false,
}) {
  const dispatch = useDispatch();
  const { controlDate } = useSelector((state: RootState) => state.charts);
  const { Option } = Select;

  const valueFormatter = (value: number) => {
    return value / 100;
  };

  const columnConfig = {
    data,
    theme: {
      defaultColor: '#A294FF',
    },
    xField: 'month',
    yField: 'sales',
    seriesField: 'type',
    legend: {
      layout: 'horizontal',
      position: 'bottom',
    },
    maxColumnWidth: 50,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      month: {
        alias: 'Mês',
      },
      sales: {
        formatter: (value: number) =>
          valueFormatter(value) / 1000000 > 1
            ? `${(valueFormatter(value) / 1000000).toFixed(2)} milhões`
            : valueFormatter(value) / 1000 > 1
            ? `${(valueFormatter(value) / 1000).toFixed(3)} mil`
            : valueFormatter(value),
        alias: 'Vendas',
      },
    },
  };

  return (
    <S.Container donut={donut}>
      <S.Content>
        <S.Title>{title}</S.Title>
        <Select
          onChange={(e) => {
            dispatch(getControlDate(e));
          }}
          style={{ width: 150 }}
          value={controlDate}
          bordered={false}
        >
          <Option value={7}>Última semana</Option>
          <Option value={30}>Últimos 30 dias</Option>
          <Option value={90}>Últimos 3 meses</Option>
          <Option value={180}>Últimos 6 meses</Option>
          <Option value={0}>Todos</Option>
        </Select>
      </S.Content>
      <Separator />
      <S.ChartContent>
        {column ? <Column {...columnConfig} /> : null}
      </S.ChartContent>
    </S.Container>
  );
}

export default Charts;
