import styled, { css } from 'styled-components';

import { InfraButton, InfraTextField } from '@infralabs/design-system';

export const Button = styled(InfraButton)`
  ${({ outline, color, modal }) => css`
    background-color: ${outline ? 'transparent' : color}!important;
    border: ${outline ? `1px solid ${color}` : 'none'}!important;
    color: ${outline ? color : ''} !important;
    width: 30%;
  `}
`;

export const DefaultSellerContainer = styled.div`
  ${({ debit }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-bottom: ${!debit ? '20px' : '0px'};
    align-self: ${debit ? 'center' : ''};
  `}
`;

export const DefaultSellerButton = styled(InfraButton)`
  ${({ outline, color, modal, debit }) => css`
    background-color: 'transparent';
    border: ${outline ? `1px solid ${color}` : 'none'}!important;
    color: ${color} !important;
    width: 100%;
    padding: 25px;
    align-self: ${debit ? 'center' : ''};
    margin-left: ${modal ? 'none' : outline ? '80px' : 'none'}!important;
    margin-right: ${modal ? 'none' : outline ? 'none' : '80px'}!important;
  `}

  & span {
    font-size: 14px;
    word-break: break-word;
    font-weight: bold;
  }
`;

export const ParcelConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const ParcelText = styled.h1`
  font-weight: 600;
  color: '#414F62';
  font-size: 22px;
`;

export const InvoicedButtons = styled(InfraButton)`
  ${({ outline, color, modal }) => css`
    background-color: ${outline ? 'transparent' : color}!important;
    border: ${outline ? `1px solid ${color}` : 'none'}!important;
    color: ${outline ? color : ''} !important;
    width: 25%;
  `}
`;

export const invoicedTicketFieldsContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin: 8px 0px 8px 0px;
  `}
`;

export const PercentageField = styled(InfraTextField)`
  text-indent: 3rem;
`;

export const addButton = styled(InfraButton)`
  ${({ outline, color }) => css`
    background-color: ${outline ? 'transparent' : color}!important;
    border: ${outline ? `1px solid ${color}` : 'none'}!important;
    color: ${outline ? color : ''} !important;
  `}
`;

export const BreadCrumbsContainer = styled.div`
  padding: 32px 70px;
`;

export const BackContainer = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 55px;
  cursor: pointer;
`;

export const CartContainer = styled.div`
  padding: 10px 0;
  display: flex;
`;

export const CartContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

export const PageContent = styled.div`
  margin: 45px 0;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const InfoContainer = styled.div`
  height: 100%;
  padding: 0 10px;
  width: 74%;
`;

export const AccordionContainer = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  &
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
    margin-top: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search
    .ant-select-selection-search-input {
    margin-top: 8px;
  }

  &
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-arrow {
    margin-top: 0px;
  }
`;

export const PartnerInput = styled.div`
  ${({ partner, width }) => css`
    display: flex;
    flex-direction: column;
    width: ${width ? `${width}%` : '36%'};
    margin-bottom: 20px;
    margin-top: ${partner ? '6px' : 0};
  `}
`;

export const ExchangePartnerInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 38%;
    margin-bottom: 20px;
  `}
`;

export const InvoicedExchangePartnerInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    flex-wrap: wrap;
    height: 300px;
    justify-content: space-between;
    width: 100%;
  `}
`;

export const PartnerField = styled.div`
  ${(disabled) => css`
    width: 45%;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    cursor: ${!disabled ? 'pointer' : 'not-allowed'};
  `}
`;

export const MappedFields = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
`;

export const CustomOptionsContainer = styled.div`
  ${() => css`
    width: 100%;
    margin-bottom: 20px;
  `}
`;

export const CustomOptionsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`;

export const CustomOptionsInstructionsContainer = styled.div`
  display: flex;
  margin: 10px 0px;
  flex-direction: column;
`;

export const ExchangeDeleteContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;
`;

export const ExternalOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`;

export const SwitchContainer = styled.div`
  ${({ margin, width }) => css`
    display: flex;
    width: ${width || '100%'};
    margin: ${margin};
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 40px 0 40px 0;
`;

export const InvoicedButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 40px 0 0 0;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`;

export const DeleteButton = styled.div`
  display: flex;
  height: 3.125rem;
  width: 163px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1.5px solid #fa878f;
  justify-content: space-between;
  padding: 0 20px;
  cursor: pointer;
`;
export const DeleteContainer = styled.div`
  ${({ pix }) => css`
    width: 19%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${pix ? '8px' : 0};
  `}
`;

export const DeleteIconContainer = styled.div`
  width: 3%;
  cursor: pointer;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddFlagContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const DefaultButtonContainer = styled.div`
  display: flex;
  width: 30%;
  align-items: center;
  cursor: pointer;
`;

export const divInfraTexts = styled.div`
  flex-direction: column;
`;

export const divInfraButtons = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const divInfraImage = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  padding: 0 0 16px 0;
`;
