/* eslint-disable consistent-return */
export const Types = {
  GET_BY_CHANNEL_ID: 'GET_BY_CHANNEL_ID',
  CREATE_NEW_PARTNER: 'CREATE_NEW_PARTNER',
  GET_BY_CHANNEL_ID_SUCCESS: 'GET_BY_CHANNEL_ID_SUCCESS',
  CREATE_BY_CHANNEL_ID: 'CREATE_BY_CHANNEL_ID',
  UPDATE_BY_CHANNEL_ID: 'UPDATE_BY_CHANNEL_ID',
  DELETE_BY_CHANNEL_ID: 'DELETE_BY_CHANNEL_ID',
  UPDATE_ACTIVE_OPTIONS_BY_ID: 'UPDATE_ACTIVE_OPTIONS_BY_ID',
  REPLICATE_DEFAULT_SELLER_CONFIGS: 'REPLICATE_DEFAULT_SELLER_CONFIGS',
  REPLICATE_DEFAULT_SELLER_CONFIGS_TO_SPECIFIC:
    'REPLICATE_DEFAULT_SELLER_CONFIGS_TO_SPECIFIC',
};

export function getPaymentOptions() {
  return {
    type: Types.GET_BY_CHANNEL_ID,
  };
}

const initialState = [];

export default function GetPaymentOptions(state = initialState, action) {
  switch (action.type) {
    case Types.GET_BY_CHANNEL_ID: {
      return { loading: true };
    }
    case Types.GET_BY_CHANNEL_ID_SUCCESS: {
      return action.payload;
    }
    case Types.CREATE_BY_CHANNEL_ID: {
      return state;
    }
    case Types.CREATE_NEW_PARTNER: {
      const data = {
        availableCardFlags: [],
        active: action.payload.active,
        paymentType: action.payload.paymentType,
        partnerName: 'Adicionar nome do parceiro',
        new: true,
        customOptions: action.payload.customOptions,
      };

      return [...state, data];
    }
    case Types.UPDATE_BY_CHANNEL_ID: {
      state[action.payload.position] = action.payload;
      return state;
    }
    case Types.DELETE_BY_CHANNEL_ID: {
      return state;
    }
    case Types.UPDATE_ACTIVE_OPTIONS_BY_ID: {
      return state;
    }
    case Types.REPLICATE_DEFAULT_SELLER_CONFIGS: {
      return state;
    }
    case Types.REPLICATE_DEFAULT_SELLER_CONFIGS_TO_SPECIFIC: {
      return state;
    }

    default:
      return state;
  }
}
