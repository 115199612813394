/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Router, Route, BrowserRouter, Switch } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import { history } from '../Helper';

import Home from './Home';
import Transactions from './Transactions';
import PaymentOptions from './PaymentOptions';
import TransactionDetails from './TransactioDetails';
import Configurations from './Configurations';
import NewPayment from './NewPayment';

export const PageLayout = ({ children }) => {
  const navigate = useHistory();
  useEffect(() => {
    function handleRouteChange(event) {
      console.log(
        '🚀 ~ file: RoutesComponent.tsx:20 ~ handleRouteChange ~ event:',
        event.detail
      );
      navigate.push(event.detail.newRoute);
    }
    window.addEventListener('changeRoute', handleRouteChange);
    return () => {
      window.removeEventListener('changeRoute', handleRouteChange);
    };
  }, []);
  return children;
};

export default function RoutesComponent({ basePath }) {
  return (
    <Router history={history}>
      <BrowserRouter basename={basePath}>
        <PageLayout>
          <Switch>
            <Route exact path="/" render={() => <Home />} />
            <Route
              exact
              path="/payment-options"
              render={() => <PaymentOptions />}
            />
            <Route exact path="/transactions" render={() => <Transactions />} />
            <Route
              exact
              path="/detalhes-da-transacao"
              render={() => <TransactionDetails />}
            />
            <Route exact path="/config" render={() => <Configurations />} />
            <Route
              exact
              path="/config/new-payment-method"
              render={() => <NewPayment />}
            />
            <Route
              exact
              path="/config/edit-payment-method/:id"
              render={() => <NewPayment />}
            />
          </Switch>
        </PageLayout>
      </BrowserRouter>
    </Router>
  );
}
