import formatCurrency from './formatCurrency';

/* eslint-disable consistent-return */
export interface Plans {
  days: number;
}

export interface InvoicedTable {
  salesPlan: string;
  plans: Array<Plans>;
  minimumValue: number | string;
  numberOfParcels: number;
  maxNumber: number;
}

type MountInvoicedTableFlagInput = {
  data: InvoicedTable[];
};

type MountInvoicedTableFlagOutput = {
  [K in string]: string;
};

export const mountInvoicedTable = (
  payload: MountInvoicedTableFlagInput
): MountInvoicedTableFlagOutput[] => {
  if (payload !== null) {
    return payload.data.map((mappedData) => {
      const obj: MountInvoicedTableFlagOutput = {};
      obj.salesPlan = mappedData.salesPlan;
      obj.minimumValue = formatCurrency(Number(mappedData.minimumValue));
      obj.createFields = mappedData.numberOfParcels.toString();

      for (let idx = 0; idx < mappedData.numberOfParcels; idx++) {
        obj[`parcel${idx + 1}`] = `${
          mappedData?.plans[idx]?.days
            ? `${
                mappedData?.plans[idx]?.days > 1
                  ? `${mappedData?.plans[idx]?.days} dias`
                  : `${mappedData?.plans[idx]?.days} dia`
              }`
            : '-'
        }`;
      }
      return obj;
    });
  }
};
