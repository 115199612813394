import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApi } from '../../../Services/api';

import { getCSVTransactionSuccess } from './actions';

import { serialize } from '../../../utils';

export function* getCSVTransaction(payload) {
  try {
    const response = yield call(
      baseApi.get,
      `payments/csv/items?${serialize(payload.payload)}`
    );

    yield put(getCSVTransactionSuccess(response.data));
  } catch (err) {
    console.log(err);
  }
}

export default all([
  takeLatest(Types.GET_CSV_TRANSACTION_REQUEST, getCSVTransaction),
]);
