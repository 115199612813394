/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable radix */
import { InfraTable, InfraTableColumn } from '@infralabs/design-system';
import { PencilSimple, MinusCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';

export default function Table({ data, maxParcel, func, func2 }) {
  const [elements, setElements] = useState([]);
  const [createdFields, setCreatedFields] = useState(0);

  const renderAction = (rowData: any) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'space-around',
        }}
      >
        <PencilSimple
          className="pencil-simple"
          size={28}
          color="#414F62"
          onClick={() => {
            func(rowData);
          }}
        />
        <MinusCircle
          className="minus-circle"
          size={28}
          color="#414F62"
          onClick={() => func2(rowData)}
        />
      </div>
    );
  };

  const renderFields = () => {
    for (let i = 0; i < createdFields; i++) {
      setElements((prevElements) => [
        ...prevElements,
        <InfraTableColumn
          align="center"
          key={i + 1}
          padding="checkbox"
          field={`parcel${i + 1}`}
          header={`Prazo Parcela ${i + 1}`}
        />,
      ]);
    }
  };

  useEffect(() => {
    setCreatedFields(maxParcel);
  }, [maxParcel]);

  useEffect(() => {
    setElements([]);
    renderFields();
  }, [createdFields]);

  const rowFunction = (data) => {
    return data.map((item, i) => ({
      ...item,
      index: i,
    }));
  };
  return (
    <InfraTable value={rowFunction(data)} theme="default">
      <InfraTableColumn
        align="center"
        field="salesPlan"
        header="Plano de Vendas"
        headerAlign="center"
        padding="checkbox"
      />
      {elements}
      <InfraTableColumn
        align="center"
        padding="checkbox"
        field="minimumValue"
        header="Valor Mínimo"
      />
      <InfraTableColumn
        field="action"
        align="center"
        padding="checkbox"
        body={renderAction}
      />
    </InfraTable>
  );
}
