import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApi } from '../../../Services/api';
import { setError } from '../Error/actions';

import { getPaymentOptionsSuccess } from './actions';

export function* getPaymentOptions(payload) {
  try {
    const response = yield call(
      baseApi.get,
      `paymentOptionConfigs/get/${payload.payload.id}/${payload.payload.paymentType}/${payload.payload.sellerId}`
    );

    yield put(getPaymentOptionsSuccess(response.data.data));
  } catch (err) {
    console.log(err);
    yield put(
      setError({
        show: true,
        message: 'Não conseguimos buscar os dados de parceiros.',
      })
    );
  }
}

export function* createPaymentOptions(payload) {
  console.log(
    '🚀 ~ file: sagas.ts:29 ~ function*createPaymentOptions ~ payload:',
    payload
  );
  try {
    yield call(baseApi.post, 'paymentOptionConfigs', payload.payload);

    let { paymentType } = payload.payload;

    if (
      paymentType === 'CARD_EXTERNAL_PAYMENT' ||
      paymentType === 'CASH_EXTERNAL_PAYMENT'
    ) {
      paymentType = 'EXTERNAL_PAYMENT';
    }

    const response = yield call(
      baseApi.get,
      `paymentOptionConfigs/get/${payload.payload.salesChannelId}/${paymentType}/${payload.payload.sellerId}`
    );

    yield put(getPaymentOptionsSuccess(response.data.data));
  } catch (err) {
    console.log(err);
    yield put(
      setError({
        show: true,
        message: 'Não foi possível criar um novo parceiro, tente novamente!',
      })
    );
  }
}

export function* updatePaymentOptions(payload) {
  try {
    yield call(
      baseApi.put,
      `paymentOptionConfigs/${payload.payload.id}`,
      payload.payload
    );

    let { paymentType } = payload.payload;

    if (
      paymentType === 'CARD_EXTERNAL_PAYMENT' ||
      paymentType === 'CASH_EXTERNAL_PAYMENT'
    ) {
      paymentType = 'EXTERNAL_PAYMENT';
    }

    const response = yield call(
      baseApi.get,
      `paymentOptionConfigs/get/${payload.payload.salesChannelId}/${paymentType}/${payload.payload.sellerId}`
    );

    yield put(getPaymentOptionsSuccess(response.data.data));
  } catch (error) {
    yield put(
      setError({
        show: true,
        message:
          'Ops! Parece que não conseguimos salvar as alterações, por favor tente novamente mais tarde.',
      })
    );
  }
}

export function* deletePaymentOptions(payload) {
  try {
    yield call(
      baseApi.delete,
      `paymentOptionConfigs/${payload.payload.id}`,
      payload.payload
    );

    const response = yield call(
      baseApi.get,
      `paymentOptionConfigs/get/${payload.payload.salesChannelId}/${payload.payload.paymentType}/${payload.payload.sellerId}`
    );

    yield put(getPaymentOptionsSuccess(response.data.data));
  } catch (err) {
    yield put(
      setError({
        show: true,
        message: 'Não foi possível excluir o dado!',
      })
    );
  }
}

export function* updateActiveOptions(payload) {
  try {
    yield call(
      baseApi.patch,
      `paymentOptionConfigs/changeStatus/${payload.payload.salesChannelId}`,
      payload.payload
    );
  } catch (err) {
    console.log(err);
  }
}

export function* replicateDefaultSellerConfigs(payload) {
  try {
    yield call(
      baseApi.post,
      `paymentOptionConfigs/replicateSellerConfig/${payload.payload.id}`,
      payload.payload
    );

    let { paymentType } = payload.payload;

    if (
      paymentType === 'CARD_EXTERNAL_PAYMENT' ||
      paymentType === 'CASH_EXTERNAL_PAYMENT'
    ) {
      paymentType = 'EXTERNAL_PAYMENT';
    }

    const response = yield call(
      baseApi.get,
      `paymentOptionConfigs/get/${payload.payload.salesChannelId}/${paymentType}/${payload.payload.sellerId}`
    );

    yield put(getPaymentOptionsSuccess(response.data.data));
  } catch (error) {
    yield put(
      setError({
        show: true,
        message:
          'Ops! Parece que não conseguimos salvar as alterações, por favor tente novamente mais tarde.',
      })
    );
  }
}

export function* replicateDefaultSellerConfigsToSpecific(payload) {
  try {
    yield call(
      baseApi.post,
      `paymentOptionConfigs/replicateSellerConfigToSpecific`,
      payload.payload
    );

    let { paymentType } = payload.payload;

    if (
      paymentType === 'CARD_EXTERNAL_PAYMENT' ||
      paymentType === 'CASH_EXTERNAL_PAYMENT'
    ) {
      paymentType = 'EXTERNAL_PAYMENT';
    }

    const response = yield call(
      baseApi.get,
      `paymentOptionConfigs/get/${payload.payload.salesChannelId}/${paymentType}/${payload.payload.sellerSpecificId}`
    );

    yield put(getPaymentOptionsSuccess(response.data.data));
  } catch (error) {
    yield put(
      setError({
        show: true,
        message:
          'Ops! Parece que não conseguimos salvar as alterações, por favor tente novamente mais tarde.',
      })
    );
  }
}

export default all([
  takeLatest(Types.GET_BY_CHANNEL_ID, getPaymentOptions),
  takeLatest(Types.CREATE_BY_CHANNEL_ID, createPaymentOptions),
  takeLatest(Types.UPDATE_BY_CHANNEL_ID, updatePaymentOptions),
  takeLatest(Types.DELETE_BY_CHANNEL_ID, deletePaymentOptions),
  takeLatest(Types.UPDATE_ACTIVE_OPTIONS_BY_ID, updateActiveOptions),
  takeLatest(
    Types.REPLICATE_DEFAULT_SELLER_CONFIGS,
    replicateDefaultSellerConfigs
  ),
  takeLatest(
    Types.REPLICATE_DEFAULT_SELLER_CONFIGS_TO_SPECIFIC,
    replicateDefaultSellerConfigsToSpecific
  ),
]);
