import styled, { css } from 'styled-components';

import { InfraButton } from '@infralabs/design-system';

export const Button = styled(InfraButton)`
  ${({ outline, color }) => css`
    background-color: ${outline ? 'transparent' : color}!important;
    border: ${outline ? `1px solid ${color}` : 'none'}!important;
    color: ${outline ? color : ''} !important;
    width: 35%;
    margin-left: ${outline ? '80px' : 'none'}!important;
    margin-right: ${outline ? 'none' : '80px'}!important;
  `}
`;
