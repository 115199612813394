/* eslint-disable consistent-return */
import { AvailableCardFlags } from '../Types/availableCardFlags';

type MountTableFlagInput = {
  availableCardFlags: AvailableCardFlags[];
  updatedAt: Date;
  createdAt: Date;
  _id: string;
  salesChannelId: string;
  active: boolean;
  paymentType: string;
  partnerName: string;
  accountId: string;
};

type MountTableFlagOutput = {
  [K in string]: string;
};

export const mountTableFlag = (
  payload: MountTableFlagInput
): MountTableFlagOutput[] => {
  if (payload.availableCardFlags !== null) {
    return payload.availableCardFlags.map((flag) => {
      const obj: MountTableFlagOutput = {};
      obj.flag = flag.name;

      if (flag.interestRatePercentages.length === 0) {
        for (let idx = 0; idx <= flag.numberInstallments; idx++) {
          obj[`parcel${idx}`] = '0.00%';
        }
      } else {
        for (let idx = 0; idx < flag.numberInstallments; idx++) {
          obj[`parcel${idx + 1}`] = `${`${
            flag.interestRatePercentages[idx]
              ? flag.interestRatePercentages[idx]
              : 0
          }%`}`;
        }
      }
      return obj;
    });
  }
};
