import {
  InfraSelect,
  InfraTextField,
  InfraSwitch,
  InfraModal,
} from '@infralabs/design-system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Info } from 'phosphor-react';
import { RootState } from '../../redux/reducers';
import { getProvidersRequest } from '../../redux/ducks/Providers/actions';
import { divInfraImage as DivInfraImage } from '../PaymentOptions/styles';
import { Typography } from '../../Components/typography';
import {
  createConnector,
  putConnector,
  changeStatus,
} from '../../redux/ducks/Connectors/actions';
import { ScrollToTop } from '../../utils/scrollToTop';
import * as S from './styles';
import { getChannelsRequest } from '../../redux/ducks/GetChannels/actions';

export function ConectorConfig({ connector, id }) {
  const [partner, setPartner] = useState('');
  const [key, setKey] = useState('');
  const [token, setToken] = useState('');
  const [params, setParams] = useState([]);
  const [optionModal, setOptionModal] = useState('');
  const [changedInputs, setChangedInputs] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isProduction, setIsProduction] = useState(false);
  const [show, setShow] = useState(false);
  const [showProduction, setShowProduction] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState({
    label: '',
    connector: { providerId: '', type: '', customParams: [], version: '' },
  });
  const [channels, setChannel] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const { store, channel, providers } = useSelector(
    (state: RootState) => state
  );

  useEffect(() => {
    dispatch(getChannelsRequest());
  }, [store]);

  useEffect(() => {
    const storeChannels = channel.length ? channel.map((item) => item.id) : [];
    setChannel(storeChannels);
  }, [channel]);

  const setNewParams = (e, index) => {
    const newArr = [...params];
    newArr[index].value = e;

    setParams(newArr);
  };

  useEffect(() => {
    dispatch(getProvidersRequest());
  }, []);

  function getProviderName() {
    let name = false;
    providers.data.map((element) => {
      if (connector.providerId === element._id) {
        name = element.name;
      }
    });
    return name;
  }

  useEffect(() => {
    if (id) {
      setKey(connector.clientKey);
      setToken(connector.clientToken);
      setParams(connector.customParams);
      setChecked(connector.active);
      setIsProduction(connector.isProduction);
      setSelectedConnector({
        label: getProviderName() ? getProviderName() : connector.name,
        connector: { providerId: '', type: '', customParams: [], version: '' },
      });
    } else {
      connector = providers.data;
    }
  }, [id, connector]);

  const editConnector = () => {
    const item = [
      id,
      {
        clientKey: key,
        clientToken: token,
        customParams: params,
        isFraudCheck: false,
      },
    ];
    dispatch(putConnector(item));
    setOptionModal('');
    history.push('/config');
  };

  const changeActive = () => {
    const item = {
      id,
      active: checked,
      type: 'status',
    };
    dispatch(changeStatus(item));
    setOptionModal('');
  };

  const changeIsProduction = () => {
    const item = {
      id,
      active: isProduction,
      type: 'production',
    };
    dispatch(changeStatus(item));
    setOptionModal('');
  };

  const newConnector = () => {
    const item = {
      salesChannelId: channels ? channels[0] : '',
      providerId: selectedConnector.connector?.providerId,
      providerType: selectedConnector.connector?.type,
      name: partner,
      clientKey: key,
      clientToken: token,
      customParams: selectedConnector.connector?.customParams,
      isFraudCheck: selectedConnector.connector?.type === 'FRAUD-CHECK',
      active: false,
    };

    if (selectedConnector.connector.version.includes('2.')) {
      delete item.clientKey;
      delete item.clientToken;
    }

    dispatch(createConnector(item));
    setOptionModal('');
    history.push('/config');
  };

  const availableConnectors = connector.length
    ? connector.map((item) => {
        return { value: item._id, label: item.name, connector: item };
      })
    : providers.data.map((item) => {
        return {
          value: item._id,
          label: item.name,
          connector: {
            customParams: item.customFieldConfig,
            providerId: item._id,
            type: item.type,
            version: item.version,
          },
        };
      });

  const renderAppKeyToken = (): JSX.Element => {
    const connectorByProvider =
      providers.data.find(
        (providerData) => providerData.name === selectedConnector.label
      ) || null;

    if (
      !selectedConnector.connector.version.includes('2.') &&
      connectorByProvider !== null &&
      !connectorByProvider.version.includes('2.')
    ) {
      return (
        <>
          <S.ExchangePartnerInput>
            <InfraTextField
              height="medium"
              id="1"
              inputStyle="normal"
              label="AppKey"
              onChange={(e) => {
                setChangedInputs(true);
                setKey(e.target.value);
              }}
              placeholder="Parceiro"
              type="text"
              value={key}
              position="start"
            />
          </S.ExchangePartnerInput>
          <S.ExchangePartnerInput>
            <InfraTextField
              height="medium"
              id="1"
              inputStyle="normal"
              label="AppToken"
              onChange={(e) => {
                setChangedInputs(true);
                setToken(e.target.value);
              }}
              placeholder="Parceiro"
              type="text"
              value={token}
              position="start"
            />
          </S.ExchangePartnerInput>
        </>
      );
    }

    return <></>;
  };

  return (
    <S.ConfigContainer>
      <Typography
        align="left"
        variant="h6"
        variantion="h6"
        weight="regular"
        color="#C6D3DE"
      >
        CONFIGURAÇÕES
      </Typography>
      <S.InputContainer>
        <S.PartnerInput partner>
          <Typography
            align="left"
            variant="h5"
            variantion="h5_small"
            weight="bold"
          >
            Provedor
          </Typography>
          <InfraSelect
            handlerClick={(e) => {
              setSelectedConnector(e);
              setParams(e.connector.customParams);
            }}
            items={availableConnectors}
            placeholder={
              selectedConnector.label ? selectedConnector.label : 'selecione'
            }
            size="medium"
            disable={id}
          />
        </S.PartnerInput>
        <S.ExchangePartnerInput>
          <InfraTextField
            height="medium"
            id="1"
            inputStyle="normal"
            label="Nome do Parceiro"
            onChange={(e) => setPartner(e.target.value)}
            placeholder="Parceiro"
            type="text"
            value={id ? connector.name : partner}
            position="start"
            disabled={id}
          />
        </S.ExchangePartnerInput>
        {renderAppKeyToken()}
        {params
          ? params.map((item, i) => (
              <S.ExchangePartnerInput key={i}>
                <InfraTextField
                  height="medium"
                  id="1"
                  inputStyle="normal"
                  label={params[i].label}
                  onChange={(e) => {
                    setChangedInputs(true);
                    setNewParams(e.target.value, i);
                  }}
                  placeholder="Parceiro"
                  type="text"
                  value={params[i].value}
                  position="start"
                />
              </S.ExchangePartnerInput>
            ))
          : ''}
      </S.InputContainer>
      <S.Separator />

      <InfraModal
        width="450"
        theme="light"
        show={optionModal}
        onClose={() => {
          setOptionModal('');
        }}
      >
        <Typography
          align="center"
          variant="h5"
          variantion="h5"
          weight={400}
          color="#414F62"
          margin="0 0 16px 0"
        >
          Tem certeza que deseja {optionModal} as alterações
        </Typography>
        <S.ButtonContainer>
          {optionModal === 'salvar' ? (
            <div
              style={{
                width: '100%',
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => setOptionModal('')}
                modal
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                modal
                color="#414F62"
                onClick={() => {
                  id ? editConnector() : newConnector();
                }}
              >
                Salvar
              </S.Button>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => setOptionModal('')}
                modal
              >
                Não
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                color="#414F62"
                onClick={() => history.push('/config')}
                modal
              >
                Sim
              </S.Button>
            </div>
          )}
        </S.ButtonContainer>
      </InfraModal>

      <InfraModal
        width="450"
        theme="light"
        show={show}
        onClose={() => {
          setChecked(!checked);
          setShow(false);
        }}
      >
        <Typography
          align="center"
          variant="h5"
          variantion="h5"
          weight={400}
          color="#414F62"
          margin="0 0 16px 0"
        >
          Tem certeza que deseja {!checked ? 'habilitar' : 'desabilitar'} o
          conector?
        </Typography>
        <S.ButtonContainer>
          <div
            style={{
              width: '100%',
              marginTop: '15px',
              justifyContent: 'space-around',
              display: 'flex',
            }}
          >
            <S.Button
              icon="X"
              iconLeft
              outline
              size="medium"
              color="#414F62"
              onClick={() => {
                setShow(false);
                setChecked(!checked);
              }}
              modal
            >
              Cancelar
            </S.Button>
            <S.Button
              icon="Check"
              iconLeft
              size="medium"
              modal
              color="#414F62"
              onClick={() => {
                setShow(false);
                changeActive();
              }}
            >
              Salvar
            </S.Button>
          </div>
        </S.ButtonContainer>
      </InfraModal>

      <InfraModal
        width="450"
        theme="light"
        show={showProduction}
        onClose={() => {
          setIsProduction(!isProduction);
          setShowProduction(false);
        }}
      >
        <Typography
          align="center"
          variant="h5"
          variantion="h5"
          weight={400}
          color="#414F62"
          margin="0 0 16px 0"
        >
          Tem certeza que deseja {!isProduction ? 'desativar' : 'ativar'} a
          operarão deste conector em ambiente de produção?
        </Typography>
        <S.ButtonContainer>
          <div
            style={{
              width: '100%',
              marginTop: '15px',
              justifyContent: 'space-around',
              display: 'flex',
            }}
          >
            <S.Button
              icon="X"
              iconLeft
              outline
              size="medium"
              color="#414F62"
              onClick={() => {
                setShowProduction(false);
                setIsProduction(!isProduction);
              }}
              modal
            >
              Cancelar
            </S.Button>
            <S.Button
              icon="Check"
              iconLeft
              size="medium"
              modal
              color="#414F62"
              onClick={() => {
                setShowProduction(false);
                changeIsProduction();
              }}
            >
              Salvar
            </S.Button>
          </div>
        </S.ButtonContainer>
      </InfraModal>

      <InfraModal
        width="450"
        theme="light"
        show={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
      >
        <DivInfraImage>
          <Info size={84} color="#FF0000" />
        </DivInfraImage>
        <Typography
          align="center"
          variant="h5"
          variantion="h5"
          weight={400}
          color="#414F62"
          margin="0 0 16px 0"
        >
          Por favor verifique se AppKey e AppToken estão preenchidos
          corretamente.
        </Typography>
        <S.ButtonContainer>
          <div />
          <S.Button
            icon="admin"
            outline={false}
            onClick={() => {
              setShowAlert(false);
            }}
          >
            Cancelar
          </S.Button>
          <div />
        </S.ButtonContainer>
      </InfraModal>
      <S.ButtonContainer
        style={{ display: changedInputs || !id ? 'flex' : 'none' }}
      >
        <S.Button
          icon="X"
          iconLeft
          outline
          size="medium"
          color="#45E398"
          onClick={() => setOptionModal('cancelar')}
        >
          Cancelar
        </S.Button>
        <S.Button
          icon="Check"
          iconLeft
          size="medium"
          color="#45E398"
          onClick={() => {
            setOptionModal('salvar');
          }}
        >
          Salvar
        </S.Button>
      </S.ButtonContainer>

      <S.ActionSwitchContainer>
        <InfraSwitch
          checked={checked}
          onChange={() => {
            setShow(true);
            setChecked(!checked);
            ScrollToTop();
          }}
          disabled={null}
        />
        <Typography
          align="left"
          variant="h6"
          variantion="h6"
          weight="regular"
          color="#414F62"
        >
          Deseja {checked === true ? 'desabilitar' : 'habilitar'} o conector ?
        </Typography>

        <InfraSwitch
          checked={isProduction}
          onChange={() => {
            setShowProduction(true);
            setIsProduction(!isProduction);
            ScrollToTop();
          }}
          disabled={null}
        />
        <Typography
          align="left"
          variant="h6"
          variantion="h6"
          weight="regular"
          color="#414F62"
        >
          Deseja {isProduction === true ? 'desativar' : 'ativar'} a operação
          deste conector em produção ?
        </Typography>
      </S.ActionSwitchContainer>
    </S.ConfigContainer>
  );
}
