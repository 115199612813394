import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InfraStepper } from '@infralabs/design-system';
import { ArrowUUpLeft, CreditCard } from 'phosphor-react';
import { useHistory } from 'react-router-dom';
import { Container } from '../../Components/container';

import { Typography } from '../../Components/typography';
import * as S from './styles';
import { RootState } from '../../redux/reducers';
import { getChannelsRequest } from '../../redux/ducks/GetChannels/actions';

import TimeLine from './timeline';
import General from './general';
import PayBack from './payback';
import BreadCrumbs from '../../Components/BreadCrumbs';

export default function TransactionDetails() {
  const dispatch = useDispatch();
  const { store, transactions } = useSelector((state: RootState) => state);
  const [step, setStep] = useState(0);
  const history = useHistory();

  const { transaction, transactionHistory } = transactions;

  useEffect(() => {
    dispatch(getChannelsRequest());
    if (!transaction.paymentId) {
      history.push('/');
    }
  }, [dispatch, history, store, transaction.paymentId]);

  const onSelected = (index: number) => {
    setStep(index);
  };

  const steps = [
    {
      label: 'Visão geral',
      icon: 'SquaresFour',
    },
    {
      label: 'Linha do Tempo do Status',
      icon: 'ClockClockwise',
    },
    {
      label: 'Estorno',
      icon: 'Repeat',
    },
  ];

  // eslint-disable-next-line consistent-return
  function renderBody() {
    switch (step) {
      case 0: {
        return <General transactions={transactions} />;
      }

      case 1: {
        return <TimeLine transactions={transactionHistory} />;
      }

      case 2: {
        return <PayBack />;
      }
    }
  }

  return (
    <>
      <S.BreadCrumbsContainer>
        <BreadCrumbs
          options={[
            {
              disabled: false,
              path: '/',
              name: 'Pagina Inicial',
            },
            {
              disabled: true,
              path: 'https://www.infracommerce.com.br/',
              name: 'Detalhes da Transação',
            },
          ]}
        />
      </S.BreadCrumbsContainer>
      <Container>
        <S.Title>
          <Typography
            style={{ margin: '0 4px 0 0' }}
            align="left"
            variant="h1"
            variantion="h1_small_bold"
            color="#2F3C4E"
          >
            Detalhe da Transação
          </Typography>
          <S.PaymentType>
            <Typography
              style={{ margin: '0 5px 0 0' }}
              align="left"
              weight="bold"
              variant="caption_small"
              color="#fff"
            >
              {transaction.paymentType}
            </Typography>
            <CreditCard size={18} color="#fff" />
          </S.PaymentType>
        </S.Title>
        <S.BackContainer onClick={() => history.goBack()}>
          <ArrowUUpLeft size={15} color="#45E398" />
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weight="bold"
            color="#45E398"
          >
            Voltar
          </Typography>
        </S.BackContainer>
        <S.PageContent>
          <InfraStepper
            activeIndex={step}
            model={steps}
            onSelected={onSelected}
          />

          <div style={{ padding: '3rem' }}>{renderBody()}</div>
        </S.PageContent>
      </Container>
    </>
  );
}
