/* eslint-disable consistent-return */
export const Types = {
  GET_PAYMENT: 'GET_PAYMENT',
  GET_PAYMENT_SUCCESS: 'GET_PAYMENT_SUCCESS',
};

export function getPaymentOptions() {
  return {
    type: Types.GET_PAYMENT,
  };
}

const initialState = {
  options: [],
  loading: false,
};

export default function GetPaymentOptions(state = initialState, action) {
  switch (action.type) {
    case Types.GET_PAYMENT: {
      return { loading: true };
    }
    case Types.GET_PAYMENT_SUCCESS: {
      return action.payload;
    }

    default:
      return state;
  }
}
