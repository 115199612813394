/* eslint-disable consistent-return */
export const Types = {
  APPROVE_PAYMENT: 'APPROVE_PAYMENT',
  APPROVE_PAYMENT_SUCCESS: 'APPROVE_PAYMENT_SUCCESS',
};

export function getPaymentOptions() {
  return {
    type: Types.APPROVE_PAYMENT,
  };
}

const initialState = {
  loading: true,
};

export default function GetPaymentOptions(state = initialState, action) {
  switch (action.type) {
    case Types.APPROVE_PAYMENT: {
      return { ...state, loading: true };
    }

    case Types.APPROVE_PAYMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
