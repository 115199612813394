import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 16px;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
`;

export const ChartContent = styled.div`
  padding: 24px 40px;
`;
