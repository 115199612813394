/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';

import {
  InfraAccordion,
  InfraSelect,
  InfraTextField,
  InfraModal,
  InfraButton,
} from '@infralabs/design-system';
import { ArrowUUpLeft, Info, X } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '../../../Components/typography';
import * as S from '../styles';
import {
  deletePaymentOptions,
  getPaymentOptionsRequest,
  postPaymentOptions,
  replicateDefaultSellerConfigs,
  replicateDefaultSellerConfigsToSpecific,
  updatePaymentOptions,
} from '../../../redux/ducks/PaymentOptions/actions';
import { ScrollToTop } from '../../../utils/scrollToTop';
import { RootState } from '../../../redux/reducers';
import { getProvidersRequest } from '../../../redux/ducks/Providers/actions';
import { Separator } from '../../../Components/separator';

type ConnectorsType = {
  label: string;
  value: string;
};

type CurrentConnectorType = {
  fraudCheck: string;
  label: string;
  providerId: string;
  selected: boolean;
  value: string;
};

export default function Pix({
  data,
  channel,
  seller,
  def,
  defId,
  active,
  paymentType,
  connectors,
}) {
  const { providers } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const [partner, setPartner] = useState<ConnectorsType>({
    label: data.partnerName ? data.partnerName : '',
    value: data.connectorId ? data.connectorId : '',
  });
  const [namePaymentOption, setNamePaymentOption] = useState(data.name);
  const [expirationDate, setExpirationDate] = useState();
  const [optionModal, setOptionModal] = useState('');
  const [changedInputs, setChangedInputs] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(false);
  const [replicateSellerDefault, setReplicateSellerDefault] = useState(false);
  const [
    replicateSellerDefaultToSpecific,
    setReplicateSellerDefaultToSpecific,
  ] = useState(false);
  const [currentProvider, setCurrentProvider] = useState({
    customConfigOptions: data.customOptions,
    ...data,
  });
  const [informCustomOption, setInformCustomOption] = useState(false);

  useEffect(() => {
    console.log('🚀 ~ file: index.tsx:73 ~ currentProvider:', currentProvider);
    dispatch(getProvidersRequest());

    setCurrentProvider({
      customFieldOptions: data?.customOptions?.filter(
        (customOption) => customOption.fieldName !== 'due_days'
      ),
      ...data,
    });
  }, []);

  useEffect(() => {
    setError(false);
  }, [active]);

  const updatePayment = () => {
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: currentProvider.active,
      name: namePaymentOption !== '' ? namePaymentOption : null,
      id: data._id,
      paymentType,
      partnerName: partner.label,
      connectorId: partner.value,
      expirationDateInDays: Number(expirationDate),
      customOptions: currentProvider?.customFieldOptions,
    };
    dispatch(updatePaymentOptions(item));
    setOptionModal('');
  };

  useEffect(() => {
    setExpirationDate(
      data.expirationDateInDays
        ? data.expirationDateInDays
        : paymentType !== 'BOLETO'
        ? 5
        : 1
    );
    setPartner({
      label: data.partnerName,
      value: data.connectorId ? data.connectorId : '',
    });
  }, [data]);

  useEffect(() => {
    const { providerId } =
      connectors.find((connector) => connector.value === partner.value) || '';

    const provider = providers.data.find(
      (providerHelper) => providerHelper._id === providerId
    );

    if (provider !== undefined) {
      provider.customFieldOptions = provider.customFieldOptions?.filter(
        (customOptions) => customOptions.fieldName !== 'due_days'
      );

      setCurrentProvider({ ...provider });
    }
  }, [partner]);

  function getPayment() {
    dispatch(
      getPaymentOptionsRequest({ id: channel, paymentType, sellerId: seller })
    );
  }

  function replicateDefaultSellerConfig() {
    const item = {
      id: data._id,
      salesChannelId: channel,
      sellerId: seller,
      paymentType,
    };

    dispatch(replicateDefaultSellerConfigs(item));

    setReplicateSellerDefault(false);
  }

  function replicateDefaultSellerSpecificConfig() {
    const item = {
      salesChannelId: channel,
      sellerDefaultId: defId,
      sellerSpecificId: seller,
      paymentType,
    };

    dispatch(replicateDefaultSellerConfigsToSpecific(item));

    setReplicateSellerDefaultToSpecific(false);
  }

  const postPaymentOption = () => {
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: currentProvider.active,
      id: data._id,
      paymentType,
      name: namePaymentOption !== '' ? namePaymentOption : null,
      partnerName: partner.label,
      connectorId: partner.value,
      expirationDateInDays: Number(expirationDate),
      customOptions: currentProvider?.customFieldOptions,
    };
    dispatch(postPaymentOptions(item));

    setOptionModal('');
  };

  const deletePayment = () => {
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: currentProvider.active,
      id: data._id,
      paymentType,
      partnerName: partner.label,
      connectorId: partner.value,
    };
    dispatch(deletePaymentOptions(item));
  };

  const numberMask = (value) => {
    if (paymentType !== 'BOLETO') {
      return value >= 0 && value <= 360 ? value : '';
    }
    return value >= 0 && value <= 90 ? value : '';
  };

  const handleChangeCustomFieldValue = (
    fieldName: string,
    value: string
  ): void => {
    const helperProvider = { ...currentProvider };
    const index = helperProvider.customFieldOptions.findIndex(
      (customOption) => customOption.fieldName === fieldName
    );
    helperProvider.customFieldOptions[index].value = value;

    setCurrentProvider({ ...helperProvider });
    setChangedInputs(true);
  };

  const isCurrentProviderValid = (): boolean => {
    const currentProviderHelper = currentProvider?.customFieldOptions?.filter(
      (customField) => !customField.fieldName.includes('instruction')
    );

    return !currentProviderHelper?.find(
      (currentProviderOption) =>
        currentProviderOption.value === '' ||
        currentProviderOption.value.length <= 0
    );
  };

  const isCustomOptionInserted = (newData: any): void => {
    if (
      currentProvider?.customFieldOptions?.length === 0 ||
      isCurrentProviderValid()
    ) {
      newData ? postPaymentOption() : updatePayment();
      return;
    }

    setShowAlert(false);
    setOptionModal('');
    setInformCustomOption(true);
  };

  const handleChangeCurrentProvider = (connectorInfo) => {
    const providerHelper = providers.data.find(
      (provider) => provider._id === connectorInfo.providerId
    );

    setCurrentProvider({ ...providerHelper });
  };

  const renderCustomOptions = (): JSX.Element => {
    const gridChildren = [];
    const instructionChildren = [];

    currentProvider &&
      currentProvider.customFieldOptions &&
      currentProvider?.customFieldOptions.map(
        (customFields: { label: string; value: string; fieldName: string }) => {
          customFields.fieldName.includes('instruction')
            ? instructionChildren.push(
                <div key={customFields.label} style={{ margin: '10px 0px' }}>
                  <InfraTextField
                    height="medium"
                    id="1"
                    inputStyle="normal"
                    label={`${customFields.label} ${
                      customFields.fieldName.includes('instruction') ? '' : '*'
                    }: `}
                    onChange={(event) =>
                      handleChangeCustomFieldValue(
                        customFields.fieldName,
                        event.target.value
                      )
                    }
                    type="string"
                    value={customFields.value}
                    position="start"
                    placeholder={`${customFields.label}...`}
                  />
                </div>
              )
            : gridChildren.push(
                <div key={customFields.label} style={{ margin: '10px 0px' }}>
                  <InfraTextField
                    height="medium"
                    id="1"
                    inputStyle="normal"
                    label={`${customFields.label}*: `}
                    onChange={(event) =>
                      handleChangeCustomFieldValue(
                        customFields.fieldName,
                        event.target.value
                      )
                    }
                    type="string"
                    value={customFields.value}
                    position="start"
                    placeholder={`${customFields.label}...`}
                  />
                </div>
              );
        }
      );

    return (
      <>
        <Separator />
        <S.CustomOptionsContainer>
          <S.CustomOptionsGridContainer>
            {gridChildren}
          </S.CustomOptionsGridContainer>
          <Separator />
          <S.CustomOptionsInstructionsContainer>
            {instructionChildren}
          </S.CustomOptionsInstructionsContainer>
        </S.CustomOptionsContainer>
      </>
    );
  };

  return (
    <InfraAccordion
      title={partner.label !== '' ? partner.label : data.partnerName}
      theme="light"
      headColor
      expanded
    >
      <S.AccordionContainer>
        <S.PartnerInput namePaymentOption>
          <Typography
            align="left"
            variant="h5"
            variantion="h5_small"
            weight="bold"
          >
            Nome de Exibição
          </Typography>
          <InfraTextField
            height="medium"
            inputStyle="normal"
            onChange={(e) => {
              setNamePaymentOption(e.target.value);
              setChangedInputs(true);
            }}
            placeholder={paymentType !== 'BOLETO' ? 'PIX' : 'Boleto'}
            type="text"
            value={namePaymentOption}
            position="start"
          />
        </S.PartnerInput>
        <S.PartnerInput partner>
          <Typography
            align="left"
            variant="h5"
            variantion="h5_small"
            weight="bold"
          >
            Nome do Parceiro
          </Typography>
          <InfraSelect
            handlerClick={(e) => {
              setPartner(e);
              setChangedInputs(true);
              handleChangeCurrentProvider(e);
            }}
            items={connectors}
            placeholder={partner.label}
            size="medium"
          />
        </S.PartnerInput>

        <S.DeleteContainer pix={paymentType !== 'BOLETO'}>
          <S.DeleteButton>
            <Typography
              align="left"
              variant="h6"
              variantion="h6_small"
              weight={600}
              color="#FA878F"
              onClick={() => {
                if (!active) {
                  setShowAlert(true);
                  ScrollToTop();
                } else {
                  setError(true);
                }
              }}
            >
              Excluir parceiro
            </Typography>
            <X size={20} color="#FA878F" />
          </S.DeleteButton>
        </S.DeleteContainer>
        <InfraModal
          width="450"
          theme="light"
          show={showAlert}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          <S.divInfraImage>
            <Info size={84} color="#FF0000" />
          </S.divInfraImage>
          <S.divInfraTexts>
            <Typography
              align="center"
              variant="h5"
              variantion="h5"
              weight={400}
              color="#414F62"
              margin="0 0 16px 0"
            >
              Deseja realmente excluir esse parceiro?
            </Typography>
          </S.divInfraTexts>
          <S.divInfraButtons>
            <div />
            <InfraButton
              style={{
                backgroundColor: '#ffffff',
                color: '#000',
                borderRadius: '5px',
                border: '1px solid #000',
                padding: '20px 60px 20px 60px',
              }}
              icon="admin"
              outline={false}
              onClick={() => {
                setShowAlert(false);
              }}
            >
              Cancelar
            </InfraButton>
            <InfraButton
              style={{
                backgroundColor: '#7676FF',
                padding: '20px 60px 20px 60px',
              }}
              icon="admin"
              outline={false}
              onClick={() => {
                deletePayment();
                setShowAlert(false);
              }}
            >
              Sim
            </InfraButton>
            <div />
          </S.divInfraButtons>
        </InfraModal>
        <S.ExchangePartnerInput>
          <InfraTextField
            height="medium"
            id="1"
            inputStyle="normal"
            label={
              paymentType !== 'BOLETO'
                ? 'Expiração Padrão do PIX  (minutos)'
                : 'Vencimento padrão do boleto (dias)'
            }
            onChange={(e) => {
              setExpirationDate(numberMask(e.target.value));
              setChangedInputs(true);
            }}
            placeholder={paymentType !== 'BOLETO' ? 'minutos' : 'dias'}
            type="number"
            value={expirationDate}
            position="start"
          />
        </S.ExchangePartnerInput>
        {error ? (
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weight={600}
            color="error_300"
            margin="0"
          >
            Para excluir um parceiro é necessário que o método de pagamento
            esteja desabilitado
          </Typography>
        ) : (
          ''
        )}

        {renderCustomOptions()}

        <InfraModal
          width="450"
          theme="light"
          show={informCustomOption}
          onClose={() => {
            setInformCustomOption(false);
          }}
        >
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weight={600}
            color="error_300"
            margin="0"
          >
            Para continuar deve informar os campos obrigatorios
          </Typography>
        </InfraModal>

        <InfraModal
          width="450"
          theme="light"
          show={replicateSellerDefault}
          onClose={() => {
            setReplicateSellerDefault(false);
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja replicar as configurações de seller default
            para todos os sellers?
          </Typography>
          <S.ButtonContainer>
            <div
              style={{
                width: '100%',
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => setReplicateSellerDefault(false)}
                modal
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                modal
                color="#414F62"
                onClick={() => replicateDefaultSellerConfig()}
              >
                Salvar
              </S.Button>
            </div>
          </S.ButtonContainer>
        </InfraModal>

        <InfraModal
          width="450"
          theme="light"
          show={replicateSellerDefaultToSpecific}
          onClose={() => {
            setReplicateSellerDefaultToSpecific(false);
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja replicar as configurações de seller default
            para apenas este seller?
          </Typography>
          <S.ButtonContainer>
            <div
              style={{
                width: '100%',
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => setReplicateSellerDefaultToSpecific(false)}
                modal
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                modal
                color="#414F62"
                onClick={() => replicateDefaultSellerSpecificConfig()}
              >
                Salvar
              </S.Button>
            </div>
          </S.ButtonContainer>
        </InfraModal>

        <InfraModal
          width="450"
          theme="light"
          show={optionModal}
          onClose={() => {
            setOptionModal('');
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja {optionModal} as alterações
          </Typography>
          <S.ButtonContainer>
            {optionModal === 'salvar' ? (
              <div
                style={{
                  width: '100%',
                  marginTop: '15px',
                  justifyContent: 'space-around',
                  display: 'flex',
                }}
              >
                <S.Button
                  icon="X"
                  iconLeft
                  outline
                  size="medium"
                  color="#414F62"
                  onClick={() => setOptionModal('')}
                  modal
                >
                  Cancelar
                </S.Button>
                <S.Button
                  icon="Check"
                  iconLeft
                  size="medium"
                  modal
                  color="#414F62"
                  onClick={() => isCustomOptionInserted(data.new)}
                >
                  Salvar
                </S.Button>
              </div>
            ) : (
              <div
                style={{
                  width: '100%',
                  marginTop: '15px',
                  justifyContent: 'space-around',
                  display: 'flex',
                }}
              >
                <S.Button
                  icon="X"
                  iconLeft
                  outline
                  size="medium"
                  color="#414F62"
                  onClick={() => setOptionModal('')}
                  modal
                >
                  Não
                </S.Button>
                <S.Button
                  icon="Check"
                  iconLeft
                  size="medium"
                  color="#414F62"
                  onClick={() => getPayment()}
                  modal
                >
                  Sim
                </S.Button>
              </div>
            )}
          </S.ButtonContainer>
        </InfraModal>

        <S.ButtonContainer>
          {!def && (
            <S.DefaultButtonContainer
              onClick={() => setReplicateSellerDefaultToSpecific(true)}
            >
              <S.DefaultSellerButton size="medium" color="#414F62" modal>
                Replicar configurações de Seller Default para um Seller
                específico
              </S.DefaultSellerButton>
              <ArrowUUpLeft size={20} color="#414F62" />
            </S.DefaultButtonContainer>
          )}
          {def && (
            <S.DefaultButtonContainer
              onClick={() => setReplicateSellerDefault(true)}
            >
              <S.DefaultSellerButton size="medium" color="#414F62" modal>
                Replicar configurações de Seller Default para todos os Sellers
              </S.DefaultSellerButton>
              <ArrowUUpLeft size={20} color="#414F62" />
            </S.DefaultButtonContainer>
          )}

          {(changedInputs || data.new) && (
            <>
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => {
                  ScrollToTop();
                  setOptionModal('cancelar');
                }}
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                color="#414F62"
                onClick={() => {
                  ScrollToTop();
                  setOptionModal('salvar');
                }}
              >
                Salvar
              </S.Button>
            </>
          )}
        </S.ButtonContainer>
      </S.AccordionContainer>
    </InfraAccordion>
  );
}
