import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApi } from '../../../Services/api';

import { setError } from '../Error/actions';
import { resendWebhookSuccess } from './actions';

export function* resendWebhook(payload) {
  try {
    const body = payload.payload;
    const response = yield call(
      baseApi.post,
      `payments/resend/webhook/${body.paymentId}`
    );

    yield put(resendWebhookSuccess(response.data.data));
  } catch (err) {
    console.log(err); 
    yield put(
      setError({
        show: true,
        message: err.message,
      })
    );
  }
}

export default all([takeLatest(Types.RESEND_WEBHOOK, resendWebhook)]);
