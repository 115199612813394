import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApi } from '../../../Services/api';

import { rollbackPaymentSuccess } from './actions';
import { setError } from '../Error/actions';

export function* rollbackPayment(payload) {
  try {
    const body = payload.payload;
    const response = yield call(
      baseApi.patch,
      `payments/rollback`,
      body
    );

    yield put(rollbackPaymentSuccess(response.data.data));
  } catch (err) {
    console.log(err); 
    yield put(
      setError({
        show: true,
        message: err.message,
      })
    );
  }
}

export default all([takeLatest(Types.ROLLBACK_PAYMENT, rollbackPayment)]);
