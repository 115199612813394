import * as S from '../../styles';
import { Typography } from '../../../../Components/typography';

export default function BillingAddress({ data }) {
  return (
    <S.Card>
      <S.CardItemsColumn separator>
        <div style={{ borderRight: '1px solid #DDE5EF', marginRight: '15px' }}>
          <S.CardHeader>
            <Typography
              align="left"
              variant="h5"
              variantion="h5_small"
              weigth="600"
              color="#0F1826"
            >
              Endereço de Cobrança
            </Typography>
          </S.CardHeader>
          <S.CardItemsColumnAddress>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                PAIS
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.customer?.billingAddress.country}
              </Typography>
            </S.CardItem>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                ESTADO
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.customer?.billingAddress.state}
              </Typography>
            </S.CardItem>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                CIDADE
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.customer?.billingAddress.city}
              </Typography>
            </S.CardItem>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                RUA
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.customer?.billingAddress.street}
              </Typography>
            </S.CardItem>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                NUMERO
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.customer?.billingAddress.number}
              </Typography>
            </S.CardItem>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                BAIRRO
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.customer?.billingAddress.neighborhood}
              </Typography>
            </S.CardItem>
            {data?.customer?.billingAddress.complement ? (
              <S.CardItem>
                <Typography
                  align="left"
                  variant="h6"
                  variantion="h6_small"
                  weigth="normal"
                  color="#879BB4"
                >
                  COMPLEMENTO
                </Typography>
                <Typography
                  align="left"
                  variant="h5"
                  variantion="h5_small"
                  weigth="normal"
                  color="#414F62"
                >
                  {data?.customer?.billingAddress.complement}
                </Typography>
              </S.CardItem>
            ) : (
              ''
            )}
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                CEP
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.customer?.billingAddress.postalCode}
              </Typography>
            </S.CardItem>
          </S.CardItemsColumnAddress>
        </div>
        <div style={{ marginLeft: '10px' }}>
          <S.CardHeader>
            <Typography
              align="left"
              variant="h5"
              variantion="h5_small"
              weigth="600"
              color="#0F1826"
            >
              Endereço de Envio
            </Typography>
          </S.CardHeader>
          <S.CardItemsColumnAddress>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                PAIS
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.shippingAddress?.country}
              </Typography>
            </S.CardItem>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                ESTADO
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.shippingAddress?.state}
              </Typography>
            </S.CardItem>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                CIDADE
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.shippingAddress?.city}
              </Typography>
            </S.CardItem>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                RUA
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.shippingAddress?.street}
              </Typography>
            </S.CardItem>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                NUMERO
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.shippingAddress?.number}
              </Typography>
            </S.CardItem>
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                BAIRRO
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.shippingAddress?.neighborhood}
              </Typography>
            </S.CardItem>
            {data?.customer?.billingAddress.complement ? (
              <S.CardItem>
                <Typography
                  align="left"
                  variant="h6"
                  variantion="h6_small"
                  weigth="normal"
                  color="#879BB4"
                >
                  COMPLEMENTO
                </Typography>
                <Typography
                  align="left"
                  variant="h5"
                  variantion="h5_small"
                  weigth="normal"
                  color="#414F62"
                >
                  {data?.shippingAddress?.complement}
                </Typography>
              </S.CardItem>
            ) : (
              ''
            )}
            <S.CardItem>
              <Typography
                align="left"
                variant="h6"
                variantion="h6_small"
                weigth="normal"
                color="#879BB4"
              >
                CEP
              </Typography>
              <Typography
                align="left"
                variant="h5"
                variantion="h5_small"
                weigth="normal"
                color="#414F62"
              >
                {data?.shippingAddress?.postalCode}
              </Typography>
            </S.CardItem>
          </S.CardItemsColumnAddress>
        </div>
      </S.CardItemsColumn>
    </S.Card>
  );
}
