/* eslint-disable consistent-return */
export const Types = {
  GET_CONNECTOR_REQUEST: 'GET_CONNECTOR_REQUEST',
  GET_CONNECTOR_REQUEST_SUCCESS: 'GET_CONNECTOR_REQUEST_SUCCESS',
};

const initialState = {
  options: [],
  loading: true,
};

export default function connectors(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CONNECTOR_REQUEST: {
      return { loading: true };
    }
    case Types.GET_CONNECTOR_REQUEST_SUCCESS: {
      return action.payload;
    }

    default:
      return state;
  }
}
