export const Types = {
  SETTLED_FILTERS: 'SETTLED_FILTERS',
};

const initialState = {};

export default function Filters(state = initialState, action) {
  switch (action.type) {
    case Types.SETTLED_FILTERS: {
      return action.payload;
    }

    default:
      return state;
  }
}
