export const percentMask = (value: string, maxValue = 100) => {
  // Remove caracteres não numéricos e adiciona zeros à esquerda
  const cleanedValue = value.toString().padStart(2, '0').replace(/[^\d]/g, '');

  // Divide o valor em parte inteira e parte decimal
  const integerPart = cleanedValue.slice(0, -2);
  const decimalPart = cleanedValue.slice(-2);

  // Formata o valor com 2 casas decimais
  const result = `${integerPart || '0'}.${decimalPart}`;

  // Certifica-se de que a parte inteira não tenha zeros à esquerda
  const finalResult = result.replace(/^0+(?=\d)/, '');

  // Limita o valor ao máximo especificado
  const limitedValue = Math.min(parseFloat(finalResult), maxValue);

  // Retorna o valor formatado com até 2 casas decimais
  return limitedValue.toFixed(2);
};
