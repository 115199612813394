/* eslint-disable consistent-return */
export const Types = {
  GET_TOTALSALES: 'GET_TOTALSALES',
  GET_TOTALSALES_SUCCESS: 'GET_TOTALSALES_SUCCESS',
  GET_TOTALTRANSACTIONS: 'GET_TOTALTRANSACTIONS',
  GET_TOTALTRANSACTIONS_SUCCESS: 'GET_TOTALTRANSACTIONS_SUCCESS',
  GET_CONTROLDATE: 'GET_CONTROLDATE',
};

export function getPaymentOptions() {
  return {
    type: Types.GET_TOTALSALES,
  };
}

const initialState = {
  totalSales: {
    total: 0,
    credit: 0,
    invoice: 0,
    pix: 0,
    exchangeVoucher: 0,
    voucher: 0,
  },
  monthlySales: [],
  loading: true,
  controlDate: 180,
};

export default function GetPaymentOptions(state = initialState, action) {
  switch (action.type) {
    case Types.GET_TOTALSALES: {
      return { ...state, loading: true };
    }
    case Types.GET_CONTROLDATE: {
      return { ...state, controlDate: action.payload };
    }
    case Types.GET_TOTALSALES_SUCCESS: {
      return {
        ...state,
        loading: false,
        monthlySales: action.payload,
      };
    }
    case Types.GET_TOTALTRANSACTIONS: {
      return { ...state, loading: true };
    }
    case Types.GET_TOTALTRANSACTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalSales: action.payload,
      };
    }
    default:
      return state;
  }
}
