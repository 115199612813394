/* eslint-disable consistent-return */
export const Types = {
  GET_PROVIDERS: 'GET_PROVIDERS',
  GET_PROVIDERS_SUCCESS: 'GET_PROVIDERS_SUCCESS',
};

const initialState = {
  loading: false,
  data: [],
};

export default function providers(state = initialState, action) {
  switch (action.type) {
    case Types.GET_PROVIDERS: {
      return { ...state, loading: true };
    }
    case Types.GET_PROVIDERS_SUCCESS: {
      return { loading: false, data: action.payload };
    }

    default:
      return state;
  }
}
