/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  InfraTable,
  InfraTableColumn,
  InfraIcons,
  InfraPagination,
  InfraTextField,
  InfraSelect,
  InfraLabel,
  InfraDatepicker,
  InfraTag,
} from '@infralabs/design-system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Select } from 'antd';
import Papa from 'papaparse';
import { Typography } from '../../Components/typography';
import * as S from './styles';
import { RootState } from '../../redux/reducers';
import { getPaymentRequest } from '../../redux/ducks/Payment/actions';
import { formatCurrency, dateTimeFormat, dateFormatIso } from '../../utils';
import { getTransactions } from '../../redux/ducks/TransactionDetails/actions';
import { Loader } from '../../Components/loader';
import BreadCrumbs from '../../Components/BreadCrumbs';
import { getChannelsRequest } from '../../redux/ducks/GetChannels/actions';
import { changePage } from '../../redux/ducks/ChangePage/actions';
import useDebounce from '../../utils/useDebouce';
import { settleFilters } from '../../redux/ducks/Filters/actions';
import { getCSVTransactionRequest } from '../../redux/ducks/Csv/actions';

type TypeFilters = {
  page: number;
  itemsPerPage: number;
  status?: string;
  transactionId?: string;
  paymentType?: string;
  salesChannelId?: string;
  dateRange?: string;
  sellerId?: string;
};

export default function Transactions() {
  const { store, payment, channel, page, settledFilters, csvTransaction } =
    useSelector((state: RootState) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const [payments, setPayments] = useState([]);
  const [pages, setPages] = useState(1);
  const [channels, setChannel] = useState();
  const [filters, setFilters] = useState<TypeFilters>({
    page,
    itemsPerPage: 10,
  });
  const [searchTerm, setSearchTerm] = useState({});
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [selectedChannel, setSelectedChannel] = useState('');
  const [selectedSeller, setSelectedSeller] = useState('');
  const [sellers, setSellers] = useState([]);

  const divideNumber = (number) => {
    return number / 100;
  };

  useEffect(() => {
    dispatch(getPaymentRequest(filters));
  }, [store, filters]);

  useEffect(() => {
    if (channel && channel?.length) {
      channel?.forEach((e) => {
        if (e.id === selectedChannel) {
          const mappedSellers = e.sellers.map((seller) => {
            return {
              label: `${seller.description}`,
              value: `${seller.id}`,
            };
          });
          setSellers(mappedSellers);
        }
      });
    }
  }, [settledFilters.salesChannelId, selectedChannel]);

  useEffect(() => {
    dispatch(getChannelsRequest());
    const date = new Date();
    setFilters({
      page,
      itemsPerPage: 10,
      dateRange: `${dateFormatIso(
        new Date(date.setDate(date.getDate() - 90))
      )},${dateFormatIso(new Date())}`,
    });
    dispatch(changePage(1));
  }, [store]);

  useEffect(() => {
    const storeChannels = channel.length
      ? channel.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      : [];

    setChannel(storeChannels);
    dispatch(settleFilters({}));
    dispatch(changePage(1));
  }, [channel]);

  useEffect(() => {
    if (payment.transactions) {
      const storePayment = payment.transactions.map((el) => ({
        ...el,
        value: formatCurrency(divideNumber(el.value)),
        createdAt: dateTimeFormat(el.createdAt, false),
      }));
      setPages(Math.ceil(payment.totalItems / payment.itemsPerPage));
      setPayments(storePayment);
    }
  }, [payment]);

  const onChangePage = (e, v) => {
    setFilters({ ...filters, page: v });
    dispatch(changePage(v));
    dispatch(settleFilters({ ...filters, page: v }));
  };

  useEffect(() => {
    if (settledFilters.page !== undefined) {
      setFilters(settledFilters);
    }
  });

  const onChangeFilter = (ev) => {
    if (ev.target.value === '') {
      const newFilters = filters;
      delete newFilters[ev.target.name];
      if (ev.target.name === 'dateRange') {
        const date = new Date();
        newFilters.dateRange = `${dateFormatIso(
          new Date(date.setDate(date.getDate() - 90))
        )},${dateFormatIso(new Date())}`;
      }
      setFilters({ ...newFilters });
      return;
    }
    setFilters({ ...filters, [ev.target.name]: ev.target.value, page: 1 });
    dispatch(
      settleFilters({ ...filters, [ev.target.name]: ev.target.value, page: 1 })
    );
    dispatch(changePage(1));
  };

  useEffect(() => {
    if (debouncedSearchTerm?.target) {
      onChangeFilter(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const onChangeDate = (date) => {
    if (Array.isArray(date)) {
      const ev = {
        target: {
          name: 'dateRange',
          value: `${dateFormatIso(date[0])},${dateFormatIso(date[1])}`,
        },
      };
      onChangeFilter(ev);
      return;
    }
    const ev = {
      target: {
        name: 'createdAt',
        value: dateFormatIso(date),
      },
    };
    onChangeFilter(ev);
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  const formattedDate = (date: Date) => {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
      'report',
    ].join('_');
  };

  const onClearDate = () => {
    onChangeFilter({
      target: {
        name: 'dateRange',
        value: '',
      },
    });
    onChangeFilter({
      target: {
        name: 'createdAt',
        value: '',
      },
    });
  };

  const renderAction = (rowData) => {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        style={{ padding: '2px' }}
        onClick={() => {
          dispatch(getTransactions(rowData));
          history.push('/detalhes-da-transacao');
        }}
      >
        <InfraIcons name="Info" />
      </div>
    );
  };

  const getColor = (status) => {
    switch (status) {
      case 'NOT-AUTHORIZED':
      case 'CANCELED':
      case 'ERROR':
      case 'FRAUD-CHECK-ERROR':
        return 'errorDefault';
      case 'APPROVED':
        return 'active';
      default:
        return 'pendency';
    }
  };

  const statusOptions = [
    {
      value: '',
      label: 'Todos',
    },
    {
      value: 'PROCESSING',
      label: 'PROCESSING',
    },
    {
      value: 'PRE-AUTHORIZED',
      label: 'PRE-AUTHORIZED',
    },
    {
      value: 'NOT-AUTHORIZED',
      label: 'NOT-AUTHORIZED',
    },
    {
      value: 'FRAUD-CHECK-OK',
      label: 'FRAUD-CHECK-OK',
    },
    {
      value: 'FRAUD-CHECK-ERROR',
      label: 'FRAUD-CHECK-ERROR',
    },
    {
      value: 'FRAUD-CHECK-PENDING',
      label: 'FRAUD-CHECK-PENDING',
    },
    {
      value: 'WAITING-APPROVAL',
      label: 'WAITING-APPROVAL',
    },
    {
      value: 'CHECKING-PAYMENT',
      label: 'CHECKING-PAYMENT',
    },
    {
      value: 'APPROVED',
      label: 'APPROVED',
    },
    {
      value: 'REFUNDED',
      label: 'REFUNDED',
    },
    {
      value: 'PARTIAL_REFUNDED',
      label: 'PARTIAL_REFUNDED',
    },
    {
      value: 'CANCELED',
      label: 'CANCELED',
    },
    {
      value: 'ERROR',
      label: 'ERROR',
    },
  ];

  const headers = [
    { label: 'Payment ID', key: 'paymentId' },
    { label: 'Transaction ID', key: 'transactionId' },
    { label: 'Sales Channel ID', key: 'salesChannelId' },
    { label: 'Seller ID', key: 'sellerId' },
    { label: 'Order Id', key: 'paymentOrderId' },
    { label: 'Payment Option Type', key: 'paymentOptionType' },
    { label: 'Order Value', key: 'paymentOrderValue' },
    { label: 'Client Name', key: 'clientName' },
    { label: 'Document', key: 'document' },
    { label: 'E-mail', key: 'clientEmail' },
    { label: 'Status', key: 'status' },
    { label: 'Status Comment', key: 'statusComment' },
    { label: 'Status Code', key: 'statusCode' },
    { label: 'Created At', key: 'createdAt' },
  ];

  const paymentTypeOptions = [
    {
      value: '',
      label: 'Todos',
    },
    {
      value: 'CREDIT_CARD',
      label: 'CREDIT_CARD',
    },
    {
      value: 'PIX',
      label: 'PIX',
    },
    {
      value: 'BOLETO',
      label: 'BOLETO',
    },
    {
      value: 'EXCHANGE_VOUCHER',
      label: 'EXCHANGE_VOUCHER',
    },
    {
      value: 'VOUCHER',
      label: 'VOUCHER',
    },
  ];

  const renderStatus = ({ status }) => {
    return <InfraTag name={status} color={getColor(status)} />;
  };

  const handleDownloadCsv = () => {
    dispatch(getCSVTransactionRequest(filters));
  };

  useEffect(() => {
    if (csvTransaction.transactions.length) {
      const csvData = Papa.unparse(csvTransaction.transactions);

      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `${formattedDate(new Date())}.csv`);
      document.body.appendChild(link);
      link.click();
    }
  }, [csvTransaction.transactions]);

  return (
    <>
      <S.BreadCrumbsContainer>
        <BreadCrumbs
          options={[
            {
              name: 'Pagina Inicial',
              path: '/',
              disabled: false,
            },
          ]}
        />
      </S.BreadCrumbsContainer>
      <S.Container>
        <S.CSVContainer>
          <Typography align="left" variant="h1" weight={400} color="#2F3C4E">
            Consultar Transações
          </Typography>
          {csvTransaction.loading ? (
            <div
              style={{
                width: '160px',
                height: '32px',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px',
              }}
            >
              <S.buttonLoader />
            </div>
          ) : (
            <Button
              style={{
                width: '160px',
                height: '32px',
                borderRadius: '4px',
              }}
              onClick={() => {
                handleDownloadCsv();
              }}
            >
              Exportar CSV
            </Button>
          )}
        </S.CSVContainer>
        <S.FilterContainer>
          <S.FilterItem>
            <InfraTextField
              height="medium"
              inputStyle="normal"
              name="orderId"
              label="ID do Pedido"
              onChange={(e) => {
                const ev = {
                  target: {
                    name: 'orderId',
                    value: e.target.value,
                  },
                };
                setSearchTerm(ev);
              }}
              type="text"
              placeholder={settledFilters.orderId ? settledFilters.orderId : ''}
            />
          </S.FilterItem>
          <S.FilterItemDate>
            <InfraLabel>Data de Criação</InfraLabel>

            <InfraDatepicker
              height="medium"
              maskDate="dd/MM/yyyy"
              period
              onChange={onChangeDate}
              onCleared={onClearDate}
              placeholder={
                settledFilters.createdAt ? settledFilters.createdAt : ''
              }
              shouldCloseOnSelect
              accentColor="default"
            />
          </S.FilterItemDate>
          <S.FilterItem>
            <InfraLabel>Status</InfraLabel>
            <InfraSelect
              size="medium"
              handlerClick={({ value }) => {
                const ev = {
                  target: {
                    name: 'status',
                    value,
                  },
                };
                onChangeFilter(ev);
              }}
              display="count"
              items={statusOptions}
              placeholder={
                settledFilters.status ? settledFilters.status : 'Selecione'
              }
            />
          </S.FilterItem>

          <S.FilterItem>
            <InfraLabel>Tipo de Pagamento</InfraLabel>
            <InfraSelect
              size="medium"
              handlerClick={({ value }) => {
                const paymentType = {
                  target: {
                    name: 'paymentType',
                    value,
                  },
                };
                onChangeFilter(paymentType);
              }}
              display="count"
              items={paymentTypeOptions}
              placeholder={
                settledFilters.paymentType
                  ? settledFilters.paymentType
                  : 'Selecione'
              }
            />
          </S.FilterItem>
          <S.FilterItem>
            <InfraLabel>ID do Canal</InfraLabel>
            <InfraSelect
              size="medium"
              handlerClick={({ value }) => {
                const salesChannelId = {
                  target: {
                    name: 'salesChannelId',
                    value,
                  },
                };
                const sellerId = {
                  target: {
                    name: 'sellerId',
                    value: '',
                  },
                };
                setSelectedSeller('');
                setSelectedChannel(salesChannelId.target.value);
                onChangeFilter(sellerId);
                onChangeFilter(salesChannelId);
              }}
              display="count"
              items={channels}
              placeholder={
                settledFilters.salesChannelId
                  ? settledFilters.salesChannelId
                  : 'Selecione'
              }
            />
          </S.FilterItem>
          <S.FilterItem>
            <InfraLabel>ID do Vendedor</InfraLabel>
            <Select
              showSearch
              placeholder="Selecione"
              optionFilterProp="children"
              filterOption={(input, option: { label: string }) =>
                (option?.label.toLowerCase() ?? '').includes(
                  input.toLowerCase()
                )
              }
              disabled={!settledFilters.salesChannelId}
              onChange={(value) => {
                const sellerId = {
                  target: {
                    name: 'sellerId',
                    value,
                  },
                };
                setSelectedSeller(value);
                onChangeFilter(sellerId);
              }}
              value={selectedSeller || 'Selecione'}
              filterSort={(
                optionA: { label: string },
                optionB: { label: string }
              ) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={sellers}
            />
          </S.FilterItem>
        </S.FilterContainer>
        {payment.loading ? (
          <div
            style={{
              width: '100%',
              marginTop: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader />
          </div>
        ) : (
          <S.TableContainer>
            <InfraTable value={payments}>
              <InfraTableColumn
                align="left"
                field="orderId"
                header="ID do Pedido"
              />
              <InfraTableColumn
                align="left"
                field="createdAt"
                header="Data de Criação"
              />
              <InfraTableColumn
                align="left"
                padding="checkbox"
                field="value"
                header="Valor"
              />
              <InfraTableColumn
                align="left"
                padding="checkbox"
                field="status"
                header="Status"
                style={{
                  width: '180px',
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '97px',
                  justifyContent: 'center',
                }}
                body={renderStatus}
              />

              <InfraTableColumn
                align="left"
                field="paymentType"
                header="Tipo de Pagamento"
              />
              <InfraTableColumn
                align="left"
                field="salesChannel"
                header="ID do Canal"
              />
              <InfraTableColumn
                align="left"
                field="sellerName"
                header="ID do Vendedor"
              />

              <InfraTableColumn
                field="action"
                header=""
                body={(rowData: unknown) => renderAction(rowData)}
              />
            </InfraTable>
          </S.TableContainer>
        )}
        <S.PaginationContainer>
          <InfraPagination
            defaultPage={page}
            count={pages}
            page={page}
            arrowfilled="true"
            color="neutral"
            onChange={(e, v) => onChangePage(e, v)}
          />
        </S.PaginationContainer>{' '}
      </S.Container>
    </>
  );
}
