/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from 'react';

import {
  InfraAccordion,
  InfraTextField,
  InfraSelect,
  InfraModal,
  InfraButton,
} from '@infralabs/design-system';
import { PlusCircle, ArrowUUpLeft, Trash } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from '../styles';
import { Typography } from '../../../Components/typography';
import {
  getPaymentOptionsRequest,
  postPaymentOptions,
  replicateDefaultSellerConfigs,
  replicateDefaultSellerConfigsToSpecific,
  updatePaymentOptions,
} from '../../../redux/ducks/PaymentOptions/actions';
import { Separator } from '../../NewPayment/styles';
import { ScrollToTop } from '../../../utils/scrollToTop';
import { CurrencyInput } from '../../../Components/currencyInput';
import Table from './table';
import { mountInvoicedTable } from '../../../utils/mountInvoicedTable';
import {
  createSalesPlansRequest,
  updateSalesPlansRequest,
  deleteSalesPlansRequest,
  setNewSalesPlanToNull,
} from '../../../redux/ducks/SalesPlans/actions';
import { RootState } from '../../../redux/reducers';
import formatCurrency from '../../../utils/formatCurrency';
import { Loader } from '../../../Components/loader';

type ConnectorsType = {
  label: string;
  value: string;
};

type SalesPlansIds = {
  fieldName: string;
  label: string;
  type: string;
  value: Array<string>;
};

export default function InvoicedTicket({
  data,
  provider,
  channel,
  position,
  seller,
  def,
  defId,
  connectors,
  active,
  paymentType,
}) {
  const dispatch = useDispatch();
  const [partner, setPartner] = useState<ConnectorsType>({
    label: '',
    value: '',
  });
  const [namePaymentOption, setNamePaymentOption] = useState(data.name);
  const [customOptions, setCustomOptions] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [customOptionsHidden, setCustomOptionsHidden] =
    useState<SalesPlansIds>();
  const [options, setOptions] = useState([]);
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [parcelSelect, setParcelSelect] = useState([]);
  const [plans, setPlans] = useState([]);
  const [handleButtons, setHandleButtons] = useState(false);
  const [salesPlansIds, setSalesPlansIds] = useState<SalesPlansIds>();
  const [result, setResult] = useState([]);
  const [error, setError] = useState(false);
  const [parcelValueError, setParcelValueError] = useState(false);
  const [parcelError, setParcelError] = useState([false]);
  const [addShow, setAddShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [indice, setIndex] = useState({
    index: 0,
    id: '' || undefined,
  });
  const [numberOfParcels, setNumberOfParcels] = useState<number>();
  const [minimunValue, setMinimunValue] = useState<number>();
  const [replicateSellerDefault, setReplicateSellerDefault] = useState(false);
  const [
    replicateSellerDefaultToSpecific,
    setReplicateSellerDefaultToSpecific,
  ] = useState(false);
  const { salesPlans } = useSelector((state: RootState) => state);

  const { loading, newSalesPlan } = salesPlans;

  function replicateDefaultSellerConfig() {
    const item = {
      id: data._id,
      salesChannelId: channel,
      sellerId: seller,
      paymentType,
    };

    dispatch(replicateDefaultSellerConfigs(item));

    setReplicateSellerDefault(false);
  }

  function replicateDefaultSellerSpecificConfig() {
    const item = {
      salesChannelId: channel,
      sellerDefaultId: defId,
      sellerSpecificId: seller,
      paymentType,
    };

    dispatch(replicateDefaultSellerConfigsToSpecific(item));

    setReplicateSellerDefaultToSpecific(false);
  }

  const postSalesPlan = (createData) => {
    const { rulesInstallments, minimumValue, plans: Plans } = createData;
    const item = {
      body: {
        rulesInstallments,
        minimumValue,
        plans: Plans,
        sellerId: seller,
      },
      headers: {
        'x-channel-id': channel,
      },
      paymentOptionsId: data._id,
      position,
    };
    dispatch(createSalesPlansRequest(item));
  };

  const updateExistentSalesPlan = (updateData) => {
    const { _id, rulesInstallments, minimumValue, plans: Plans } = updateData;
    const item = {
      id: _id,
      body: {
        rulesInstallments,
        minimumValue,
        plans: Plans,
        sellerId: seller,
      },
      headers: {
        'x-channel-id': channel,
      },
      paymentOptionsId: data._id,
    };

    dispatch(updateSalesPlansRequest(item));
  };

  const deleteExistentSalesPlan = (idArray) => {
    idArray.map((id) => {
      const item = {
        id,
        sellerId: seller,
        headers: {
          'x-channel-id': channel,
        },
      };

      setDeleteShow(false);

      dispatch(deleteSalesPlansRequest(item));
    });
  };

  const PostPaymentOptions = () => {
    const newArr = options.concat([salesPlansIds]);

    const item = {
      id: data._id,
      salesChannelId: channel,
      sellerId: seller,
      active: true,
      paymentType,
      name: namePaymentOption != '' ? namePaymentOption : null,
      partnerName: partner.label,
      connectorId: partner.value,
      customOptions: newArr,
      availableCardFlags: null,
      expirationDataInDays: 5,
      reUsable: null,
      installmentMinimumValue: null,
      antiFraud: false,
      default: false,
    };
    dispatch(postPaymentOptions(item));
  };

  const UpdatePaymentOptions = () => {
    const newArr = options.concat([salesPlansIds]);

    const item = {
      id: data._id,
      salesChannelId: channel,
      sellerId: seller,
      active: active?.active === undefined ? active : active.active,
      paymentType,
      name: namePaymentOption != '' ? namePaymentOption : null,
      partnerName: partner.label,
      connectorId: partner.value,
      customOptions: newArr,
      availableCardFlags: null,
      expirationDataInDays: 5,
      reUsable: null,
      installmentMinimumValue: null,
      antiFraud: false,
      default: false,
      position,
    };

    dispatch(updatePaymentOptions(item));
  };

  const createNewSalesPlanAndAddIntoArrId = () => {
    inputs.map((e) => {
      if (e._id === undefined) {
        postSalesPlan(e);
      }
    });
  };

  const updateSalesPlanAndSave = () => {
    inputs?.map((e) => {
      if (e.toUpdate) {
        updateExistentSalesPlan(e);
      }
    });
  };

  const handlePaymentOptions = () => {
    updateSalesPlanAndSave();
    idsToDelete.length && deleteExistentSalesPlan(idsToDelete);
    data.new ? PostPaymentOptions() : UpdatePaymentOptions();
  };

  const createItem = () => {
    if (numberOfParcels > 0) {
      const createData = {
        _id: undefined,
        rulesInstallments: {
          maxNumber: options[0]?.value,
          minInterval: options[1]?.value,
          maxExpirationDays: options[2]?.value,
          minExpirationDays: options[3]?.value,
        },
        minimumValue: formatCurrency(minimunValue, true),
        plans,
      };

      const newArr = inputs.length ? inputs : [];
      setInputs([...newArr, createData]);
    }
  };

  const updateItem = (parcelNumber, Plans, minValue, index, id) => {
    if (id !== undefined) {
      const newArray = [...inputs];
      newArray[index].numberOfParcels = parcelNumber;
      newArray[index].plans = Plans;
      newArray[index].minimumValue = formatCurrency(minValue, true);
      newArray[index].toUpdate = true;

      setInputs(newArray);
    }
  };

  const deleteItem = (index, id) => {
    if (id !== undefined) {
      setIdsToDelete([...idsToDelete, id]);
      const newArr = [...inputs];
      const indArray = [...salesPlansIds.value];
      newArr.splice(index, 1);
      indArray.splice(index, 1);
      setSalesPlansIds({ ...salesPlansIds, value: indArray });
      setInputs(newArr);
    }
  };

  const numberMask = (value) => {
    return value >= 0 ? value : '';
  };

  const parcelNumberMask = (value) => {
    return value >= 0 && value <= Number(options[0]?.value) ? value : '';
  };

  const assignedFieldsNumberMask = (value, i) => {
    if (i !== 3) {
      return value >= 0 ? value : 0;
    }
    return value >= 0 && value <= options[2]?.value ? value : 0;
  };

  const setNewOptions = (e, index) => {
    const newArr = [...options];
    newArr[index].value = e;

    setOptions(newArr);
  };

  const regularTamanho = (e) => {
    const newArr = new Array(Number(e)).fill(0);

    setParcelSelect(newArr);
  };

  function VerificarIntervaloMinimoComAnterior(valores, minimo) {
    const erros = [];

    for (let i = 1; i < valores.length; i++) {
      if (valores[i].days < valores[i - 1].days + minimo) {
        erros.push(true);
      }
    }

    return erros;
  }

  const setPosition = (e, index) => {
    const newArr = [...plans];
    newArr[index] = { days: Number(e) };

    setPlans(newArr);
  };

  const SetUpdateModal = (rowData: any) => {
    setIndex({ index: rowData.index, id: rowData.salesPlan });
    setNumberOfParcels(parcelNumberMask(Number(rowData.createFields)));
    regularTamanho(
      Number(rowData.createFields) > Number(options[0]?.value)
        ? 0
        : Number(rowData.createFields)
    );
    setPlans(inputs[rowData.index].plans);
    setMinimunValue(inputs[rowData.index].minimumValue);

    // eslint-disable-next-line no-sequences
    return setEditShow(true), ScrollToTop();
  };

  const validateFields = () => {
    let open = true;
    options?.map((e) => {
      if (!e.value) {
        setError(true);
        open = false;
      }
    });
    open && setAddShow(true);
  };

  const saveValidateFields = () => {
    let open = true;

    if (partner.label === 'Adicionar nome do parceiro') {
      setError(true);
      open = false;
    }

    options?.map((e) => {
      if (!e.value) {
        setError(true);
        open = false;
      }
    });
    setHandleButtons(false);
    open && handlePaymentOptions();
  };

  const validateParcelFields = (method) => {
    let open = true;

    for (let i = 0; i < numberOfParcels; i++) {
      if (plans[i] === undefined || plans[i]?.days === 0) {
        setParcelValueError(true);
        open = false;
      }
    }

    if (method === 'update' && open) {
      updateItem(numberOfParcels, plans, minimunValue, indice.index, indice.id);
      setEditShow(false);
      setHandleButtons(true);
    }
    if (method === 'create' && open) {
      createItem();
      setAddShow(false);
      setHandleButtons(true);
    }
  };

  useEffect(() => {
    setPartner({ value: data.connectorId, label: data.partnerName });
    setInputs(
      data?.salesPlans !== null && data.salesPlans !== undefined
        ? [...data.salesPlans]
        : []
    );
    if (data?.customOptions !== undefined) {
      setOptions(data?.customOptions?.filter((e) => e.type !== 'hidden'));
      setSalesPlansIds(
        data?.customOptions?.filter((e) => e.type === 'hidden')[0]
      );
    }
  }, [data]);

  useEffect(() => {
    if (parcelSelect.length !== 0 && parcelSelect.length < plans.length) {
      const newPlan = [];
      for (let x = 0; x < parcelSelect.length; x++) {
        newPlan.push(plans[x]);
      }
      setPlans(newPlan);
    }
  }, [parcelSelect]);

  useEffect(() => {
    setCustomOptions(
      provider?.customFieldOptions?.filter((e) => e.type !== 'hidden')
    );
    const hiddenIds = provider?.customFieldOptions?.filter(
      (e) => e.type === 'hidden'
    )[0];
    const hiddenIdsFields = {
      fieldName: hiddenIds?.fieldName,
      label: hiddenIds?.label,
      type: hiddenIds?.type,
      value: [],
    };
    setCustomOptionsHidden(hiddenIdsFields);
  }, [provider]);

  useEffect(() => {
    if (provider && data.customOptions === undefined) {
      setOptions(customOptions);
      setSalesPlansIds(customOptionsHidden);
    }
  }, [data, customOptions, customOptionsHidden]);

  useEffect(() => {
    if (inputs?.length) {
      const mappedSalesPlan = inputs.map((e) => {
        const { _id, minimumValue, plans: Plans, rulesInstallments } = e;
        const { maxExpirationDays, maxNumber, minExpirationDays, minInterval } =
          rulesInstallments;

        const createData = {
          id: _id,
          maxNumber,
          minInterval,
          maxExpirationDays,
          minExpirationDays,
          salesPlan: _id,
          minimumValue,
          numberOfParcels: Plans.length,
          plans: Plans,
        };
        return createData;
      });

      setResult(mountInvoicedTable({ data: mappedSalesPlan }));
    } else {
      setResult([]);
    }
  }, [inputs]);

  useEffect(() => {
    if (newSalesPlan !== null && newSalesPlan.position === position) {
      const newArr = inputs.filter((e) => e._id !== undefined);
      setInputs([...newArr, newSalesPlan.request]);
      dispatch(setNewSalesPlanToNull({ value: null }));
    }
  }, [newSalesPlan]);

  useEffect(() => {
    if (inputs?.length) {
      createNewSalesPlanAndAddIntoArrId();
    }
  }, [inputs]);

  useEffect(() => {
    if (inputs?.length && salesPlansIds?.value !== undefined) {
      const newArr = [...salesPlansIds.value];
      inputs.map((e) => {
        if (e._id !== undefined && !salesPlansIds.value.includes(e._id)) {
          newArr.push(e._id);
          setSalesPlansIds({ ...salesPlansIds, value: newArr });
        }
      });
    }
  }, [inputs]);

  useEffect(() => {
    if (options) {
      setParcelError(
        VerificarIntervaloMinimoComAnterior(plans, options[1]?.value)
      );
    }
  }, [plans]);

  useEffect(() => {
    if (addShow === false && editShow === false) {
      setNumberOfParcels(0);
      setPlans([]);
      regularTamanho(0);
      setMinimunValue(0);
      setParcelValueError(false);
    }
  }, [addShow, editShow]);

  return (
    <>
      <InfraAccordion
        title={partner.label !== '' ? partner.label : data.partnerName}
        theme="light"
        headColor
        expanded
      >
        <S.AccordionContainer>
          <S.invoicedTicketFieldsContainer>
            <S.InvoicedExchangePartnerInput>
              <S.PartnerInput namePaymentOption>
                <Typography
                  align="left"
                  variant="h6"
                  variantion="h6_small"
                  weight="bold"
                >
                  Nome de Exibição
                </Typography>
                <InfraTextField
                  size="medium"
                  height="medium"
                  inputStyle="normal"
                  onChange={(e) => {
                    setNamePaymentOption(e.target.value);
                    setHandleButtons(true);
                  }}
                  placeholder="Limite de Crédito"
                  type="text"
                  value={namePaymentOption}
                  position="start"
                />
              </S.PartnerInput>
              <S.PartnerField disabled={!def}>
                <Typography
                  align="left"
                  variant="h6"
                  variantion="h6_small"
                  weight="bold"
                >
                  Nome do Parceiro
                </Typography>
                <InfraSelect
                  size="medium"
                  disable={!def}
                  handlerClick={(e) => {
                    setPartner(e);
                    setError(false);
                    setHandleButtons(true);
                  }}
                  items={connectors}
                  placeholder={partner.label}
                />
                {error && partner.label === 'Adicionar nome do parceiro' && (
                  <Typography
                    align="left"
                    variant="h6"
                    variantion="h6_small"
                    weight={600}
                    color="error_300"
                    margin="0"
                  >
                    Preencha este campo antes de continuar
                  </Typography>
                )}
              </S.PartnerField>
              {customOptions?.length && options?.length
                ? customOptions.map((item, i) => (
                    <S.MappedFields key={i}>
                      <InfraTextField
                        height="medium"
                        id="1"
                        inputStyle="normal"
                        disabled={!def}
                        label={options[i].label}
                        onChange={(e) => {
                          setNewOptions(
                            assignedFieldsNumberMask(Number(e.target.value), i),
                            i
                          );
                          setError(false);
                          setHandleButtons(true);
                        }}
                        type={options[i].type}
                        placeholder={item.label}
                        value={options[i].value ? options[i].value : ''}
                        position="start"
                      />
                      {error && !options[i].value && (
                        <Typography
                          align="left"
                          variant="h6"
                          variantion="h6_small"
                          weight={600}
                          color="error_300"
                          margin="0"
                        >
                          Preencha este campo antes de continuar
                        </Typography>
                      )}
                    </S.MappedFields>
                  ))
                : ''}
            </S.InvoicedExchangePartnerInput>
          </S.invoicedTicketFieldsContainer>
          <Separator />
          <S.ParcelConfigContainer>
            <S.ParcelText>Configuração de Parcelas</S.ParcelText>
          </S.ParcelConfigContainer>
          {inputs.length ? (
            !loading && result.length ? (
              <div
                style={{
                  overflow: 'auto',
                  width: '100%',
                  marginBottom: '32px',
                }}
              >
                <Table
                  data={result}
                  maxParcel={options ? options[0]?.value : 3}
                  func={(row) => {
                    SetUpdateModal(row);
                  }}
                  func2={(row) => {
                    setIndex({ index: row.index, id: row.salesPlan });
                    setDeleteShow(true);
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Loader />
              </div>
            )
          ) : (
            <div style={{ visibility: 'hidden' }} />
          )}
          <S.AddFlagContainer
            onClick={() => {
              validateFields();
            }}
          >
            <PlusCircle size={20} color="#414F62" />
            <Typography
              align="left"
              variant="h5"
              variantion="h5_small"
              weight={600}
              color="#414F62"
              margin="0 10px"
            >
              Adicionar configuração de Plano de Venda
            </Typography>
          </S.AddFlagContainer>
          <InfraModal
            width="450"
            theme="light"
            show={addShow}
            onClose={() => {
              setAddShow(false);
            }}
          >
            <S.divInfraTexts>
              <Typography
                align="center"
                variant="h3"
                variantion="h3"
                weight={600}
                color="#414F62"
                margin="0 0 16px 0"
              >
                Novo Plano de Vendas
              </Typography>
              <InfraTextField
                height="medium"
                id="1"
                inputStyle="normal"
                label="Quantidade de Parcelas"
                onFocus={() => {
                  setNumberOfParcels(0);
                }}
                onChange={(e) => {
                  setNumberOfParcels(parcelNumberMask(Number(e.target.value)));
                  regularTamanho(
                    Number(e.target.value) > Number(options[0]?.value)
                      ? 0
                      : Number(e.target.value)
                  );
                  setPlans([]);
                }}
                placeholder="Quantidade de Parcelas"
                type="number"
                value={!numberOfParcels ? '' : numberOfParcels}
                position="start"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  marginTop: '30px',
                }}
              >
                {parcelSelect.map((item, index) => (
                  <div
                    key={index}
                    style={{ position: 'relative', width: '40%' }}
                  >
                    <div style={{ width: '100%' }}>
                      <S.PartnerInput style={{ width: '100%' }}>
                        <Typography
                          align="left"
                          variant="h5"
                          variantion="h5_small"
                          weight="bold"
                        >
                          {`Prazo Parcela ${index + 1}`}
                        </Typography>
                        <CurrencyInput
                          onChange={(e) => {
                            setParcelValueError(false);
                            setPosition(
                              numberMask(Number(e.target.value)),
                              index
                            );
                            // setChangedInputs(true);
                          }}
                          error={parcelValueError && !plans[index]?.days}
                          value={plans[index]?.days ? plans[index]?.days : ''}
                          placeholder="0"
                          allowNegative={false}
                          autoComplete="off"
                        />
                        <div
                          style={{
                            position: 'absolute',
                            top: '37%',
                            right: '30%',
                          }}
                        >
                          dia(s)
                        </div>
                      </S.PartnerInput>
                    </div>
                  </div>
                ))}
              </div>
              {parcelValueError && (
                <Typography
                  align="left"
                  variant="h6"
                  variantion="h6_small"
                  weight={600}
                  color="error_300"
                  margin="0px 0px 20px 0px"
                >
                  Insira um valor para todas as parcelas ou reduza o numero de
                  parcelas
                </Typography>
              )}
              <S.PartnerInput width="100">
                <Typography
                  align="left"
                  variant="h5"
                  variantion="h5_small"
                  weight="bold"
                >
                  Valor Minimo
                </Typography>
                <CurrencyInput
                  onChange={(e) => {
                    setMinimunValue(e.target.value);
                  }}
                  value={minimunValue}
                  placeholder="R$ 0,00"
                  autoComplete="none"
                  format={formatCurrency}
                />
              </S.PartnerInput>
              <div
                style={{
                  marginTop: '15px',
                  justifyContent: 'space-around',
                  display: 'flex',
                }}
              >
                <InfraButton
                  style={{
                    backgroundColor: '#7676FF',
                    padding: '20px 40px 20px 40px',
                  }}
                  icon="admin"
                  outline={false}
                  disabled={parcelError.length}
                  onClick={() => {
                    validateParcelFields('create');
                  }}
                >
                  Confirmar
                </InfraButton>

                <InfraButton
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#000',
                    borderRadius: '5px',
                    border: '1px solid #000',
                    padding: '20px 40px 20px 40px',
                  }}
                  icon="admin"
                  outline={false}
                  onClick={() => {
                    setAddShow(false);
                  }}
                >
                  Cancelar
                </InfraButton>
              </div>
            </S.divInfraTexts>
          </InfraModal>

          <InfraModal
            width="450"
            theme="light"
            show={editShow}
            onClose={() => {
              setEditShow(false);
            }}
          >
            <S.divInfraTexts>
              <Typography
                align="center"
                variant="h3"
                variantion="h3"
                weight={600}
                color="#414F62"
                margin="0 0 16px 0"
              >
                Editar Plano de Vendas
              </Typography>
              <InfraTextField
                height="medium"
                id="1"
                inputStyle="normal"
                label="Quantidade de Parcelas"
                onChange={(e) => {
                  setNumberOfParcels(parcelNumberMask(Number(e.target.value)));
                  regularTamanho(
                    Number(e.target.value) > Number(options[0]?.value)
                      ? 0
                      : Number(e.target.value)
                  );
                }}
                placeholder="Quantidade de Parcelas"
                type="number"
                value={!numberOfParcels ? '' : numberOfParcels}
                position="start"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  marginTop: '30px',
                }}
              >
                {parcelSelect.map((item, index) => (
                  <div
                    key={index}
                    style={{ position: 'relative', width: '40%' }}
                  >
                    <div style={{ width: '100%' }}>
                      <S.PartnerInput style={{ width: '100%' }}>
                        <Typography
                          align="left"
                          variant="h5"
                          variantion="h5_small"
                          weight="bold"
                        >
                          {`Prazo Parcela ${index + 1}`}
                        </Typography>
                        <CurrencyInput
                          onChange={(e) => {
                            setParcelValueError(false);
                            setPosition(
                              numberMask(Number(e.target.value)),
                              index
                            );
                            // setChangedInputs(true);
                          }}
                          error={parcelValueError && !plans[index]?.days}
                          value={plans[index]?.days ? plans[index]?.days : ''}
                          placeholder="0"
                          allowNegative={false}
                          autoComplete="off"
                        />
                        <div
                          style={{
                            position: 'absolute',
                            top: '37%',
                            right: '30%',
                          }}
                        >
                          dia(s)
                        </div>
                      </S.PartnerInput>
                    </div>
                  </div>
                ))}
                <S.PartnerInput width="40">
                  <Typography
                    align="left"
                    variant="h5"
                    variantion="h5_small"
                    weight="bold"
                  >
                    Valor Minimo
                  </Typography>
                  <CurrencyInput
                    onChange={(e) => {
                      setMinimunValue(e.target.value);
                    }}
                    value={minimunValue}
                    placeholder="R$ 0,00"
                    autoComplete="none"
                    format={formatCurrency}
                  />
                </S.PartnerInput>
                {parcelValueError && (
                  <Typography
                    align="left"
                    variant="h6"
                    variantion="h6_small"
                    weight={600}
                    color="error_300"
                    margin="0px 0px 20px 0px"
                  >
                    Insira um valor para todas as parcelas ou reduza o numero de
                    parcelas
                  </Typography>
                )}
              </div>
              <div
                style={{
                  marginTop: '15px',
                  justifyContent: 'space-around',
                  display: 'flex',
                }}
              >
                <InfraButton
                  style={{
                    backgroundColor: '#7676FF',
                    padding: '20px 40px 20px 40px',
                  }}
                  icon="admin"
                  outline={false}
                  disabled={parcelError.length}
                  onClick={() => {
                    validateParcelFields('update');
                  }}
                >
                  Confirmar
                </InfraButton>

                <InfraButton
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#000',
                    borderRadius: '5px',
                    border: '1px solid #000',
                    padding: '20px 40px 20px 40px',
                  }}
                  icon="admin"
                  outline={false}
                  onClick={() => {
                    setEditShow(false);
                  }}
                >
                  Cancelar
                </InfraButton>
              </div>
            </S.divInfraTexts>
          </InfraModal>

          <InfraModal
            width="350"
            theme="light"
            show={deleteShow}
            onClose={() => {
              setDeleteShow(false);
            }}
          >
            <S.divInfraTexts>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px',
                }}
              >
                <Trash size={48} color="#FA878F" />
              </div>

              <Typography
                align="center"
                variant="h4"
                variantion="h4"
                weight={600}
                color="#414F62"
                margin="0 0 16px 0"
              >
                Você realmente deseja excluir o Plano de Venda?
              </Typography>
              <div
                style={{
                  marginTop: '15px',
                  justifyContent: 'space-around',
                  display: 'flex',
                }}
              >
                <InfraButton
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#FA878F',
                    borderRadius: '5px',
                    border: '1px solid #FA878F',
                    padding: '20px 40px 20px 40px',
                  }}
                  icon="admin"
                  outline={false}
                  onClick={() => {
                    setDeleteShow(false);
                  }}
                >
                  Cancelar
                </InfraButton>

                <InfraButton
                  style={{
                    backgroundColor: '#FA878F',
                    padding: '20px 40px 20px 40px',
                  }}
                  icon="admin"
                  outline={false}
                  onClick={() => {
                    deleteItem(indice.index, indice.id);
                    setDeleteShow(false);
                    setHandleButtons(true);
                  }}
                >
                  Excluir
                </InfraButton>
              </div>
            </S.divInfraTexts>
          </InfraModal>
        </S.AccordionContainer>
      </InfraAccordion>
      <S.InvoicedButtonContainer>
        {!def && (
          <S.DefaultButtonContainer
            onClick={() => setReplicateSellerDefaultToSpecific(true)}
          >
            <S.DefaultSellerButton size="medium" color="#414F62" modal>
              Replicar configurações de Seller Default para um Seller específico
            </S.DefaultSellerButton>
            <ArrowUUpLeft size={20} color="#414F62" />
          </S.DefaultButtonContainer>
        )}
        {def && (
          <S.DefaultButtonContainer
            onClick={() => setReplicateSellerDefault(true)}
          >
            <S.DefaultSellerButton size="medium" color="#414F62" modal>
              Replicar configurações de Seller Default para todos os Sellers
            </S.DefaultSellerButton>
            <ArrowUUpLeft size={20} color="#414F62" />
          </S.DefaultButtonContainer>
        )}

        {(handleButtons || data?.new) && (
          <>
            <S.InvoicedButtons
              icon="X"
              iconLeft
              outline
              size="medium"
              color="#414F62"
              onClick={() => {
                dispatch(
                  getPaymentOptionsRequest({
                    id: channel,
                    paymentType,
                    sellerId: seller,
                  })
                );
              }}
            >
              Cancelar
            </S.InvoicedButtons>
            <S.InvoicedButtons
              icon="Check"
              iconLeft
              size="medium"
              color="#414F62"
              onClick={saveValidateFields}
            >
              Salvar
            </S.InvoicedButtons>
          </>
        )}
      </S.InvoicedButtonContainer>

      <InfraModal
        width="450"
        theme="light"
        show={replicateSellerDefault}
        onClose={() => {
          setReplicateSellerDefault(false);
        }}
      >
        <Typography
          align="center"
          variant="h5"
          variantion="h5"
          weight={400}
          color="#414F62"
          margin="0 0 16px 0"
        >
          Tem certeza que deseja replicar as configurações de seller default
          para todos os sellers?
        </Typography>
        <S.ButtonContainer>
          <div
            style={{
              width: '100%',
              marginTop: '15px',
              justifyContent: 'space-around',
              display: 'flex',
            }}
          >
            <S.Button
              icon="X"
              iconLeft
              outline
              size="medium"
              color="#414F62"
              onClick={() => setReplicateSellerDefault(false)}
              modal
            >
              Cancelar
            </S.Button>
            <S.Button
              icon="Check"
              iconLeft
              size="medium"
              modal
              color="#414F62"
              onClick={() => replicateDefaultSellerConfig()}
            >
              Salvar
            </S.Button>
          </div>
        </S.ButtonContainer>
      </InfraModal>

      <InfraModal
        width="450"
        theme="light"
        show={replicateSellerDefaultToSpecific}
        onClose={() => {
          setReplicateSellerDefaultToSpecific(false);
        }}
      >
        <Typography
          align="center"
          variant="h5"
          variantion="h5"
          weight={400}
          color="#414F62"
          margin="0 0 16px 0"
        >
          Tem certeza que deseja replicar as configurações de seller default
          para apenas este seller?
        </Typography>
        <S.ButtonContainer>
          <div
            style={{
              width: '100%',
              marginTop: '15px',
              justifyContent: 'space-around',
              display: 'flex',
            }}
          >
            <S.Button
              icon="X"
              iconLeft
              outline
              size="medium"
              color="#414F62"
              onClick={() => setReplicateSellerDefaultToSpecific(false)}
              modal
            >
              Cancelar
            </S.Button>
            <S.Button
              icon="Check"
              iconLeft
              size="medium"
              modal
              color="#414F62"
              onClick={() => replicateDefaultSellerSpecificConfig()}
            >
              Salvar
            </S.Button>
          </div>
        </S.ButtonContainer>
      </InfraModal>
    </>
  );
}

//   '63f7e27bf1210b00147acc10',
//   '63f7e3b6f1210b00147acc35',
//   '63f7e3faf1210b00147acc44';
