import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApi } from '../../../Services/api';
import { setError } from '../Error/actions';

import { getChannelSuccess } from './actions';

export function* getChannel() {
  try {
    const response = yield call(baseApi.get, 'sales-channels/getByTenantId');

    yield put(getChannelSuccess(response.data.data));
  } catch (err) {
    console.log(err);
    yield put(
      setError({
        show: true,
        message: 'Tivemos algum problema ao buscar os canais cadastrados.',
      })
    );
  }
}

export default all([takeLatest(Types.GET_CHANNEL, getChannel)]);
