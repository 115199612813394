import { action } from 'typesafe-actions';

import { Types } from './index';

export const reprocessPayment = (payload) =>
  action(Types.REPROCESS_PAYMENT, payload);

export const reprocessPaymentSuccess = (payload) =>
  action(Types.REPROCESS_PAYMENT_SUCCESS, payload);


  
