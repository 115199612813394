import { action } from 'typesafe-actions';

import { Types } from './index';

export const getTransactions = (payload) =>
  action(Types.GET_TRASACTIONS_DETAILS, payload);

export const getTransactionsSuccess = (payload) =>
  action(Types.GET_TRASACTIONS_DETAILS_SUCCESS, payload);

export const getTransactionsHistorySuccess = (payload) =>
  action(Types.GET_TRASACTIONS_HISTORY_SUCCESS, payload);
