import { history } from '../../Helper';

export const Types = {
  CHANGE_STORE: 'CHANGE_STORE',
};

export function changeStore(payload) {
  return {
    type: Types.CHANGE_STORE,
    payload,
  };
}

const initialState = {
  storeName: '',
  storeId: '',
};

export default function ChangeStore(state = initialState, action) {
  switch (action.type) {
    case Types.CHANGE_STORE: {
      history.push('/');
      return {
        storeName: action.payload.name,
        storeId: action.payload.id,
      };
    }

    default:
      return state;
  }
}
