/* eslint-disable consistent-return */
export const Types = {
  GET_CSV_TRANSACTION_REQUEST: 'GET_CSV_TRANSACTION_REQUEST',
  GET_CSV_TRANSACTION_SUCCESS: 'GET_CSV_TRANSACTION_REQUEST_SUCCESS',
};

export function getPaymentOptions() {
  return {
    type: Types.GET_CSV_TRANSACTION_REQUEST,
  };
}

const initialState = {
  loading: false,
  transactions: [],
};

export default function GetPaymentOptions(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CSV_TRANSACTION_REQUEST: {
      return { ...state, loading: true };
    }
    case Types.GET_CSV_TRANSACTION_SUCCESS: {
      return { loading: false, transactions: action.payload.data };
    }

    default:
      return state;
  }
}
