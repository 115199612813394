import { InfraAccordion } from '@infralabs/design-system';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import * as S from '../../styles';
import { Typography } from '../../../../Components/typography';
import { approvePayment } from '../../../../redux/ducks/ApprovePayment/actions';

export default function Transaction({ data, paymentId }) {
  const [paymentApproved, setPaymentApproved] = useState(false);
  const dispatch = useDispatch();
  const divideNumber = (number) => {
    return number / 100;
  };

  const approve = (paymentOrderId, paymentType, approved) => {
    dispatch(
      approved
        ? approvePayment({
            paymentId,
            paymentOrderId,
            paymentType: paymentType === 'BOLETO' ? 'billet' : 'pix',
            body: paymentType === 'BOLETO' ? 'Paid' : 'approved',
          })
        : approvePayment({
            paymentId,
            paymentOrderId,
            paymentType: paymentType === 'BOLETO' ? 'billet' : 'pix',
            body: paymentType === 'BOLETO' ? 'Expired' : 'expired',
          })
    );
    setPaymentApproved(true);
  };

  return (
    <S.Card>
      <S.CardHeader>
        <Typography
          align="left"
          variant="h5"
          variantion="h5_small"
          weigth="600"
          color="#0F1826"
        >
          Transações
        </Typography>
      </S.CardHeader>
      {data?.transactions !== undefined
        ? data?.transactions.map((item, index) => (
            <div key={index} style={{ width: '100%', marginBottom: '20px' }}>
              <InfraAccordion
                theme="light"
                headColor
                expanded
                title={`Id do Pagamento: ${item.paymentOrderId}`}
              >
                <S.CardItems style={{ borderBottom: '1px solid #DDE5EF' }}>
                  <S.CardItem>
                    <Typography
                      align="left"
                      variant="h6"
                      variantion="h6_small"
                      weigth="normal"
                      color="#879BB4"
                    >
                      ID DE PAGAMENTO
                    </Typography>
                    <Typography
                      align="left"
                      variant="h5"
                      variantion="h5_small"
                      weigth="normal"
                      color="#414F62"
                    >
                      {item.paymentOrderId}
                    </Typography>
                  </S.CardItem>
                  <S.CardItem>
                    <Typography
                      align="left"
                      variant="h6"
                      variantion="h6_small"
                      weigth="normal"
                      color="#879BB4"
                    >
                      VALOR DE ENVIO
                    </Typography>
                    <Typography
                      align="left"
                      variant="h5"
                      variantion="h5_small"
                      weigth="normal"
                      color="#414F62"
                    >
                      {divideNumber(item?.paymentOrderValue).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </Typography>
                  </S.CardItem>
                  <S.CardItem>
                    <Typography
                      align="left"
                      variant="h6"
                      variantion="h6_small"
                      weigth="normal"
                      color="#879BB4"
                    >
                      TIPO DE PAGAMENTO
                    </Typography>
                    <Typography
                      align="left"
                      variant="h5"
                      variantion="h5_small"
                      weigth="normal"
                      color="#414F62"
                    >
                      {item?.paymentOptionType}
                    </Typography>
                  </S.CardItem>
                  <S.CardItem>
                    <Typography
                      align="left"
                      variant="h6"
                      variantion="h6_small"
                      weigth="normal"
                      color="#879BB4"
                    >
                      PARCELAS
                    </Typography>
                    <Typography
                      align="left"
                      variant="h5"
                      variantion="h5_small"
                      weigth="normal"
                      color="#414F62"
                    >
                      {item?.installmentsNumber}
                    </Typography>
                  </S.CardItem>
                  {process.env.APPLICATION_ENV !== 'production' &&
                  paymentApproved !== true &&
                  (item.paymentOptionType === 'BOLETO' ||
                    item.paymentOptionType === 'PIX') &&
                  (item.status === 'PROCESSING' ||
                    item.status === 'PRE-AUTHORIZED' ||
                    item.status === 'FRAUD-CHECK-OK' ||
                    item.status === 'FRAUD-CHECK-PENDING' ||
                    item.status === 'WAITING-APPROVAL' ||
                    item.status === 'CHECKING-PAYMENT') ? (
                    <Button
                      style={{
                        width: '160px',
                        height: '32px',
                        color: '#fff',
                        borderRadius: '4px',
                        backgroundColor: '#45E398',
                      }}
                      onClick={() =>
                        approve(
                          item.paymentOrderId,
                          item.paymentOptionType,
                          true
                        )
                      }
                    >
                      Aprovar Pagamento
                    </Button>
                  ) : null}
                  {process.env.APPLICATION_ENV !== 'production' &&
                  paymentApproved !== true &&
                  (item.paymentOptionType === 'BOLETO' ||
                    item.paymentOptionType === 'PIX') &&
                  (item.status === 'PROCESSING' ||
                    item.status === 'PRE-AUTHORIZED' ||
                    item.status === 'FRAUD-CHECK-OK' ||
                    item.status === 'FRAUD-CHECK-PENDING' ||
                    item.status === 'WAITING-APPROVAL' ||
                    item.status === 'CHECKING-PAYMENT') ? (
                    <Button
                      style={{
                        width: '160px',
                        height: '32px',
                        color: '#000',
                        borderRadius: '4px',
                        backgroundColor: '#E62424',
                      }}
                      onClick={() =>
                        approve(
                          item.paymentOrderId,
                          item.paymentOptionType,
                          false
                        )
                      }
                    >
                      Reprovar Pagamento
                    </Button>
                  ) : null}
                </S.CardItems>
                {item?.card !== null ? (
                  <S.CardItems>
                    <S.CardItem>
                      <Typography
                        align="left"
                        variant="h6"
                        variantion="h6_small"
                        weigth="normal"
                        color="#879BB4"
                      >
                        NUMERO
                      </Typography>
                      <Typography
                        align="left"
                        variant="h5"
                        variantion="h5_small"
                        weigth="normal"
                        color="#414F62"
                      >
                        XXXX XXXX XXXX {item?.card.finalNumber}
                      </Typography>
                    </S.CardItem>
                    <S.CardItem>
                      <Typography
                        align="left"
                        variant="h6"
                        variantion="h6_small"
                        weigth="normal"
                        color="#879BB4"
                      >
                        CVC
                      </Typography>
                      <Typography
                        align="left"
                        variant="h5"
                        variantion="h5_small"
                        weigth="normal"
                        color="#414F62"
                      >
                        {item?.card.csc}
                      </Typography>
                    </S.CardItem>
                    <S.CardItem>
                      <Typography
                        align="left"
                        variant="h6"
                        variantion="h6_small"
                        weigth="normal"
                        color="#879BB4"
                      >
                        NOME IMPRESSO NO CARTÃO
                      </Typography>
                      <Typography
                        align="left"
                        variant="h5"
                        variantion="h5_small"
                        weigth="normal"
                        color="#414F62"
                      >
                        {item?.card.holder}
                      </Typography>
                    </S.CardItem>
                    <S.CardItem>
                      <Typography
                        align="left"
                        variant="h6"
                        variantion="h6_small"
                        weigth="normal"
                        color="#879BB4"
                      >
                        DATA DE EXPIRAÇÃO
                      </Typography>
                      <Typography
                        align="left"
                        variant="h5"
                        variantion="h5_small"
                        weigth="normal"
                        color="#414F62"
                      >
                        {item?.card.expiration.month}/
                        {item?.card.expiration.year}
                      </Typography>
                    </S.CardItem>
                  </S.CardItems>
                ) : (
                  ''
                )}
              </InfraAccordion>
            </div>
          ))
        : ''}
    </S.Card>
  );
}
