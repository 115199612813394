import { InfraAccordion } from '@infralabs/design-system';
import * as S from '../../styles';
import { Typography } from '../../../../Components/typography';
import formatCurrency from '../../../../utils/formatCurrency';

export default function Items({ data }) {
  return (
    <S.Card>
      {data?.items !== undefined
        ? data?.items.map((item, index) => (
            <div key={index} style={{ width: '100%', marginBottom: '20px' }}>
              <InfraAccordion
                theme="light"
                headColor
                expanded
                title={`Item ${index + 1}`}
              >
                <S.CardItems>
                  <S.CardItem>
                    <Typography
                      align="left"
                      variant="h6"
                      variantion="h6_small"
                      weigth="normal"
                      color="#879BB4"
                    >
                      ID
                    </Typography>
                    <Typography
                      align="left"
                      variant="h5"
                      variantion="h5_small"
                      weigth="normal"
                      color="#414F62"
                    >
                      {item?.skuId}
                    </Typography>
                  </S.CardItem>
                  <S.CardItem>
                    <Typography
                      align="left"
                      variant="h6"
                      variantion="h6_small"
                      weigth="normal"
                      color="#879BB4"
                    >
                      NOME
                    </Typography>
                    <Typography
                      align="left"
                      variant="h5"
                      variantion="h5_small"
                      weigth="normal"
                      color="#414F62"
                    >
                      {item?.name}
                    </Typography>
                  </S.CardItem>
                  <S.CardItem>
                    <Typography
                      align="left"
                      variant="h6"
                      variantion="h6_small"
                      weigth="normal"
                      color="#879BB4"
                    >
                      PREÇO
                    </Typography>
                    <Typography
                      align="left"
                      variant="h5"
                      variantion="h5_small"
                      weigth="normal"
                      color="#414F62"
                    >
                      {formatCurrency(item?.price)}
                    </Typography>
                  </S.CardItem>
                  <S.CardItem>
                    <Typography
                      align="left"
                      variant="h6"
                      variantion="h6_small"
                      weigth="normal"
                      color="#879BB4"
                    >
                      QUANTIDADE
                    </Typography>
                    <Typography
                      align="left"
                      variant="h5"
                      variantion="h5_small"
                      weigth="normal"
                      color="#414F62"
                    >
                      {item?.quantity}
                    </Typography>
                  </S.CardItem>
                  <S.CardItem>
                    <Typography
                      align="left"
                      variant="h6"
                      variantion="h6_small"
                      weigth="normal"
                      color="#879BB4"
                    >
                      ID DO VENDEDOR
                    </Typography>
                    <Typography
                      align="left"
                      variant="h5"
                      variantion="h5_small"
                      weigth="normal"
                      color="#414F62"
                    >
                      {item?.sellerId}
                    </Typography>
                  </S.CardItem>
                </S.CardItems>
              </InfraAccordion>
            </div>
          ))
        : ''}
    </S.Card>
  );
}
