/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { InfraBreadcrumbs } from '@infralabs/design-system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Typography } from '../../Components/typography';
import * as S from './styles';
import { RootState } from '../../redux/reducers';
import { ConectorConfig } from './conectorConfig';
import { TestConfig } from './testConfig';
import { LogsTable } from './logsTable';
import { getConnectorsById } from '../../redux/ducks/Connectors/actions';
import BreadCrumbs from '../../Components/BreadCrumbs';

export default function NewPayment() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { connectors } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getConnectorsById(id));
    }
  }, []);

  return (
    <>
      <S.BreadCrumbsContainer>
        <BreadCrumbs
          options={[
            {
              name: 'Pagina Inicial',
              path: '/config',
              disabled: false,
            },
            {
              disabled: false,
              path: '/config',
              name: 'Configuração de conectores',
            },
            {
              disabled: true,
              name: `${id !== undefined ? 'Editar conector' : 'Novo conector'}`,
            },
          ]}
        />
      </S.BreadCrumbsContainer>
      <S.Container>
        <Typography align="left" variant="h2" weight={800} color="#2F3C4E">
          {id !== undefined ? 'Editar conector' : 'Novo conector'}
        </Typography>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '30px',
          }}
        >
          <ConectorConfig connector={id ? connectors : {}} id={id} />
          <TestConfig />
        </div>

        <LogsTable />
      </S.Container>
    </>
  );
}
