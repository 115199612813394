import { action } from 'typesafe-actions';

import { Types } from './index';

export const getPaymentOptionsRequest = (payload) =>
  action(Types.GET_BY_CHANNEL_ID, payload);

export const getPaymentOptionsSuccess = (payload) =>
  action(Types.GET_BY_CHANNEL_ID_SUCCESS, payload);

export const createPaymentOptions = (payload) =>
  action(Types.CREATE_NEW_PARTNER, payload);

export const postPaymentOptions = (payload) =>
  action(Types.CREATE_BY_CHANNEL_ID, payload);

export const updatePaymentOptions = (payload) =>
  action(Types.UPDATE_BY_CHANNEL_ID, payload);

export const deletePaymentOptions = (payload) =>
  action(Types.DELETE_BY_CHANNEL_ID, payload);

export const updateActiveOptions = (payload) =>
  action(Types.UPDATE_ACTIVE_OPTIONS_BY_ID, payload);

export const replicateDefaultSellerConfigs = (payload) =>
  action(Types.REPLICATE_DEFAULT_SELLER_CONFIGS, payload);

export const replicateDefaultSellerConfigsToSpecific = (payload) =>
  action(Types.REPLICATE_DEFAULT_SELLER_CONFIGS_TO_SPECIFIC, payload);
