import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Reducers } from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default createStore(Reducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);
