export const Types = {
  CHANGE_PAGE: 'CHANGE_PAGE',
};

const initialState = 1;

export default function Page(state = initialState, action) {
  switch (action.type) {
    case Types.CHANGE_PAGE: {
      return action.payload;
    }

    default:
      return state;
  }
}
