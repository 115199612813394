import { useEffect, useState } from 'react';

import {
  InfraAccordion,
  InfraTextField,
  InfraSwitch,
  InfraButton,
  InfraSelect,
  InfraModal,
} from '@infralabs/design-system';
import { X, Info, ArrowUUpLeft } from 'phosphor-react';
import { useDispatch } from 'react-redux';
import { Typography } from '../../../Components/typography';
import * as S from '../styles';
import {
  updatePaymentOptions,
  deletePaymentOptions,
  postPaymentOptions,
  getPaymentOptionsRequest,
  replicateDefaultSellerConfigs,
  replicateDefaultSellerConfigsToSpecific,
} from '../../../redux/ducks/PaymentOptions/actions';
import { ScrollToTop } from '../../../utils/scrollToTop';
import { Separator } from '../../NewPayment/styles';

type ConnectorsType = {
  label: string;
  value: string;
};

export default function Exchange({
  data,
  channel,
  seller,
  def,
  defId,
  active,
  paymentType,
  connectors,
}) {
  const dispatch = useDispatch();
  const [partner, setPartner] = useState<ConnectorsType>({
    label: '',
    value: '',
  });
  const [namePaymentOption, setNamePaymentOption] = useState(data.name);
  const [checked, setChecked] = useState(false);
  const [days, setDays] = useState();
  const [optionModal, setOptionModal] = useState('');
  const [changedInputs, setChangedInputs] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [replicateSellerDefault, setReplicateSellerDefault] = useState(false);
  const [
    replicateSellerDefaultToSpecific,
    setReplicateSellerDefaultToSpecific,
  ] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setChecked(data.reUsable ? data.reUsable : false);
    setDays(data.expirationDateInDays ? data.expirationDateInDays : 0);
    setPartner({ label: data.partnerName, value: '' });
  }, [data]);

  const updatePayment = () => {
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: active?.active === undefined ? active : active.active,
      id: data._id,
      paymentType,
      name: (namePaymentOption != '' ? namePaymentOption : null),
      partnerName: partner.label,
      connectorId: data?.connectorId,
      reUsable: checked,
      expirationDateInDays: Number(days),
    };
    dispatch(updatePaymentOptions(item));
    setChangedInputs(false);
    setOptionModal('');
  };

  function replicateDefaultSellerConfig() {
    const item = {
      id: data._id,
      salesChannelId: channel,
      sellerId: seller,
      paymentType,
    };

    dispatch(replicateDefaultSellerConfigs(item));

    setReplicateSellerDefault(false);
  }

  function replicateDefaultSellerSpecificConfig() {
    const item = {
      salesChannelId: channel,
      sellerDefaultId: defId,
      sellerSpecificId: seller,
      paymentType,
    };

    dispatch(replicateDefaultSellerConfigsToSpecific(item));

    setReplicateSellerDefaultToSpecific(false);
  }

  function getPayment() {
    dispatch(
      getPaymentOptionsRequest({ id: channel, paymentType, sellerId: seller })
    );
  }

  const postPaymentOption = () => {
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: true,
      id: data._id,
      paymentType,
      name: (namePaymentOption != '' ? namePaymentOption : null),
      partnerName: partner.label,
      connectorId: partner.value,
      reUsable: checked,
      expirationDateInDays: Number(days),
    };
    dispatch(postPaymentOptions(item));
    setChangedInputs(false);
    setOptionModal('');
  };

  const deletePayment = () => {
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: active?.active,
      id: data._id,
      paymentType,
      partnerName: partner.label,
      connectorId: active?.connectorId,
    };
    dispatch(deletePaymentOptions(item));
  };

  useEffect(() => {
    setError(false);
  }, [active]);

  return (
    <InfraAccordion
      title={partner.label !== '' ? partner.label : data.partnerName}
      theme="light"
      headColor
      expanded
    >
      <S.AccordionContainer>
        <S.PartnerInput namePaymentOption>
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weight="bold"
          >
            Nome de Exibição
          </Typography>
          <InfraTextField
            size="medium"
            height="medium"
            inputStyle="normal"
            onChange={(e) => {
              setNamePaymentOption(e.target.value);
              setChangedInputs(true);
            }}
            placeholder={paymentType === 'EXCHANGE_VOUCHER' ? 'Vale troca' : 'Vale compra'}
            type="text"
            value={namePaymentOption}
            position="start"
          />
        </S.PartnerInput>
        <S.ExchangePartnerInput partner>
          <Typography
            align="left"
            variant="h5"
            variantion="h5_small"
            weight="bold"
          >
            Nome do Parceiro
          </Typography>
          <InfraSelect
            handlerClick={(e) => {
              setChangedInputs(true);
              setPartner(e);
            }}
            items={connectors}
            placeholder={partner.label}
            size="medium"
          />
        </S.ExchangePartnerInput>
        <S.ExchangeDeleteContainer>
          <S.DeleteButton
            onClick={() => {
              if (!active) {
                setShowAlert(true);
                ScrollToTop();
              } else {
                setError(true);
              }
            }}
          >
            <Typography
              align="left"
              variant="h6"
              variantion="h6_small"
              weight={600}
              color="#FA878F"
            >
              Excluir parceiro
            </Typography>
            <X size={20} color="#FA878F" />
          </S.DeleteButton>
        </S.ExchangeDeleteContainer>

        <S.ExchangePartnerInput>
          <InfraTextField
            height="medium"
            id="1"
            inputStyle="normal"
            label={`Expiração Padrão de Vale ${
              paymentType === 'EXCHANGE_VOUCHER' ? 'Troca' : 'Compra'
            } (dias)`}
            onChange={(e) => {
              setChangedInputs(true);
              setDays(e.target.value);
            }}
            placeholder="dias"
            type="number"
            value={days}
            position="start"
          />
        </S.ExchangePartnerInput>
        {error ? (
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weight={600}
            color="error_300"
            margin="0"
          >
            Para excluir um parceiro é necessário que o método de pagamento
            esteja desabilitado
          </Typography>
        ) : (
          ''
        )}

        <InfraModal
          width="450"
          theme="light"
          show={replicateSellerDefault}
          onClose={() => {
            setReplicateSellerDefault(false);
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja replicar as configurações de seller default
            para todos os sellers?
          </Typography>
          <S.ButtonContainer>
            <div
              style={{
                width: '100%',
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => setReplicateSellerDefault(false)}
                modal
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                modal
                color="#414F62"
                onClick={() => replicateDefaultSellerConfig()}
              >
                Salvar
              </S.Button>
            </div>
          </S.ButtonContainer>
        </InfraModal>
        <InfraModal
          width="450"
          theme="light"
          show={replicateSellerDefaultToSpecific}
          onClose={() => {
            setReplicateSellerDefaultToSpecific(false);
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja replicar as configurações de seller default
            para apenas este seller?
          </Typography>
          <S.ButtonContainer>
            <div
              style={{
                width: '100%',
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => setReplicateSellerDefaultToSpecific(false)}
                modal
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                modal
                color="#414F62"
                onClick={() => replicateDefaultSellerSpecificConfig()}
              >
                Salvar
              </S.Button>
            </div>
          </S.ButtonContainer>
        </InfraModal>
        <InfraModal
          width="450"
          theme="light"
          show={replicateSellerDefault}
          onClose={() => {
            setReplicateSellerDefault(false);
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja replicar as configurações de seller default
            para todos os sellers?
          </Typography>
          <S.ButtonContainer>
            <div
              style={{
                width: '100%',
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => setReplicateSellerDefault(false)}
                modal
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                modal
                color="#414F62"
                onClick={() => replicateDefaultSellerConfig()}
              >
                Salvar
              </S.Button>
            </div>
          </S.ButtonContainer>
        </InfraModal>

        <Separator />

        <S.SwitchContainer margin="12px 0">
          <InfraSwitch
            checked={checked}
            onChange={() => {
              setChecked(!checked);
              setChangedInputs(true);
            }}
          />
          <Typography
            align="left"
            variant="h6"
            variantion="h6"
            weight="regular"
            color="#414F62"
            margin="0 0 0 8px"
          >
            Habilitar reutilização de vale
            {paymentType === 'EXCHANGE_VOUCHER' ? ' troca' : ' compra'}
          </Typography>
        </S.SwitchContainer>

        <InfraModal
          width="450"
          theme="light"
          show={optionModal}
          onClose={() => {
            setOptionModal('');
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja {optionModal} as alterações
          </Typography>
          <S.ButtonContainer>
            {optionModal === 'salvar' ? (
              <div
                style={{
                  width: '100%',
                  marginTop: '15px',
                  justifyContent: 'space-around',
                  display: 'flex',
                }}
              >
                <S.Button
                  icon="X"
                  iconLeft
                  outline
                  size="medium"
                  color="#414F62"
                  onClick={() => {
                    setOptionModal('');
                    setChangedInputs(false);
                  }}
                  modal
                >
                  Cancelar
                </S.Button>
                <S.Button
                  icon="Check"
                  iconLeft
                  size="medium"
                  modal
                  color="#414F62"
                  onClick={data.new ? postPaymentOption : updatePayment}
                >
                  Salvar
                </S.Button>
              </div>
            ) : (
              <div
                style={{
                  width: '100%',
                  marginTop: '15px',
                  justifyContent: 'space-around',
                  display: 'flex',
                }}
              >
                <S.Button
                  icon="X"
                  iconLeft
                  outline
                  size="medium"
                  color="#414F62"
                  onClick={() => setOptionModal('')}
                  modal
                >
                  Não
                </S.Button>
                <S.Button
                  icon="Check"
                  iconLeft
                  size="medium"
                  color="#414F62"
                  onClick={() => getPayment()}
                  modal
                >
                  Sim
                </S.Button>
              </div>
            )}
          </S.ButtonContainer>
        </InfraModal>

        <InfraModal
          width="450"
          theme="light"
          show={showAlert}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          <S.divInfraImage>
            <Info size={84} color="#FF0000" />
          </S.divInfraImage>
          <S.divInfraTexts>
            <Typography
              align="center"
              variant="h5"
              variantion="h5"
              weight={400}
              color="#414F62"
              margin="0 0 16px 0"
            >
              Deseja realmente excluir esse parceiro?
            </Typography>
          </S.divInfraTexts>
          <S.divInfraButtons>
            <div />
            <InfraButton
              style={{
                backgroundColor: '#ffffff',
                color: '#000',
                borderRadius: '5px',
                border: '1px solid #000',
                padding: '20px 60px 20px 60px',
              }}
              icon="admin"
              outline={false}
              onClick={() => {
                setShowAlert(false);
              }}
            >
              Cancelar
            </InfraButton>
            <InfraButton
              style={{
                backgroundColor: '#7676FF',
                padding: '20px 60px 20px 60px',
              }}
              icon="admin"
              outline={false}
              onClick={() => {
                deletePayment();
                setShowAlert(false);
              }}
            >
              Sim
            </InfraButton>
            <div />
          </S.divInfraButtons>
        </InfraModal>

        <S.ButtonContainer>
          {!def && (
            <S.DefaultButtonContainer
              onClick={() => setReplicateSellerDefaultToSpecific(true)}
            >
              <S.DefaultSellerButton size="medium" color="#414F62" modal>
                Replicar configurações de Seller Default para um Seller
                específico
              </S.DefaultSellerButton>
              <ArrowUUpLeft size={20} color="#414F62" />
            </S.DefaultButtonContainer>
          )}
          {def && (
            <S.DefaultButtonContainer
              onClick={() => setReplicateSellerDefault(true)}
            >
              <S.DefaultSellerButton size="medium" color="#414F62" modal>
                Replicar configurações de Seller Default para todos os Sellers
              </S.DefaultSellerButton>
              <ArrowUUpLeft size={20} color="#414F62" />
            </S.DefaultButtonContainer>
          )}

          {(changedInputs || data.new) && (
            <>
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => {
                  ScrollToTop();
                  setOptionModal('cancelar');
                }}
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                color="#414F62"
                onClick={() => {
                  ScrollToTop();
                  setOptionModal('salvar');
                }}
              >
                Salvar
              </S.Button>
            </>
          )}
        </S.ButtonContainer>
      </S.AccordionContainer>
    </InfraAccordion>
  );
}
