import { InfraButton, InfraSwitch } from '@infralabs/design-system';
import { useState } from 'react';
import { Typography } from '../../Components/typography';
import * as S from './styles';

export function TestConfig() {
  const items = [0, 0, 0, 0, 0, 0];

  return (
    <S.TestContainer>
      <div style={{ display: 'flex', minWidth: '100%' }}>
        <S.SwitchContainer>
          <Typography
            align="left"
            variant="h6"
            variantion="h6"
            weight="regular"
            color="#C6D3DE"
          >
            TESTES PARA EXECUTAR
          </Typography>
          {items.map((item, index) => (
            <S.SwitchItemContainer key={index} margin="12px 0">
              <InfraSwitch
                checked={false}
                onChange={() => {
                  // console.log('hi');
                }}
                disabled
              />
              <Typography
                align="left"
                variant="h6"
                variantion="h6"
                weight="regular"
                color="#414F62"
                margin="0 0 0 8px"
              >
                Negado
              </Typography>
            </S.SwitchItemContainer>
          ))}
        </S.SwitchContainer>
        <S.SwitchContainer>
          <Typography
            align="left"
            variant="h6"
            variantion="h6"
            weight="regular"
            color="#C6D3DE"
          >
            STATUS
          </Typography>
        </S.SwitchContainer>
      </div>
      <S.Separator />
      <div
        style={{
          width: '100%',
          marginTop: '15px',
          display: 'flex',
        }}
      >
        <InfraButton
          style={{
            backgroundColor: '#fff',
            color: '#7676FF',
            borderRadius: '5px',
            marginRight: '20px',
            border: '1px solid #7676FF',
          }}
          outline={false}
        >
          Testar
        </InfraButton>

        <InfraButton
          style={{
            backgroundColor: '#fff',
            color: '#7676FF',
            borderRadius: '5px',
            border: '1px solid #7676FF',
          }}
          outline={false}
        >
          Testar e ativar
        </InfraButton>
      </div>
    </S.TestContainer>
  );
}
