/* eslint-disable consistent-return */
export const Types = {
  UPDATE_CARDS: 'UPDATE_CARDS',
  REMOVE_CARDS: 'REMOVE_CARDS',
};

const initialState = {
  fixedList: [
    { value: 'Visa', label: 'Visa' },
    { value: 'Mastercard', label: 'Mastercard' },
    { value: 'Elo', label: 'Elo' },
    { value: 'Hiper', label: 'Hiper' },
    { value: 'Hipercard', label: 'Hipercard' },
    { value: 'JCB', label: 'JCB' },
    { value: 'Banescard', label: 'Banescard' },
    { value: 'American Express', label: 'American Express' },
    { value: 'Aura', label: 'Aura' },
  ],
  usedList: [],
};

export default function GetPaymentOptions(state = initialState, action) {
  switch (action.type) {
    case Types.UPDATE_CARDS: {
      return { ...state, usedList: [...state.usedList, ...action.payload] };
    }
    case Types.REMOVE_CARDS: {
      return state;
    }

    default:
      return state;
  }
}
