import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Types } from '.';

import { baseApi } from '../../../Services/api';

import {
  deleteSalesPlansRequestSuccess,
  createSalesPlansSuccess,
  updateSalesPlansSuccess,
} from './actions';

export function* createSalesPlansRequest(payload) {
  try {
    const request = yield call(
      baseApi.post,
      'sales-plans',
      payload.payload.body,
      { headers: payload.payload.headers }
    );

    yield put(
      createSalesPlansSuccess({
        position: payload.payload.position,
        request: request.data.data,
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* updateSalesPlansRequest(payload) {
  try {
    yield call(
      baseApi.put,
      `sales-plans/${payload.payload.id}`,
      payload.payload.body,
      { headers: payload.payload.headers }
    );

    const response = yield call(
      baseApi.get,
      `sales-plans/payment-options/${payload.payload.paymentOptionsId}/${payload.payload.body.sellerId}`,
      payload.payload.headers
    );

    yield put(updateSalesPlansSuccess(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* deleteSalesPlansRequest(payload) {
  try {
    yield call(
      baseApi.delete,
      `sales-plans/${payload.payload.id}/${payload.payload.sellerId}`,
      { headers: payload.payload.headers }
    );

    yield put(deleteSalesPlansRequestSuccess());
  } catch (err) {
    console.log(err);
  }
}

export default all([
  takeLatest(Types.CREATE_SALES_PLANS_REQUEST, createSalesPlansRequest),
  takeLatest(Types.UPDATE_SALES_PLANS_REQUEST, updateSalesPlansRequest),
  takeLatest(Types.DELETE_SALES_PLANS_REQUEST, deleteSalesPlansRequest),
]);
