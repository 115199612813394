import { action } from 'typesafe-actions';

import { Types } from './index';

export const getTotalSalesRequest = (payload) =>
  action(Types.GET_TOTALSALES, payload);

export const getTotalSalesSuccess = (payload) =>
  action(Types.GET_TOTALSALES_SUCCESS, payload);

export const getControlDate = (payload = {}) =>
  action(Types.GET_CONTROLDATE, payload);

export const getTotalTransactionsRequest = (payload) =>
  action(Types.GET_TOTALTRANSACTIONS, payload);

export const getTotalTransactionsSuccess = (payload) =>
  action(Types.GET_TOTALTRANSACTIONS_SUCCESS, payload);
