/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  InfraSelect,
  InfraSwitch,
  InfraModal,
  InfraButton,
} from '@infralabs/design-system';
import { ArrowUUpLeft, Info } from 'phosphor-react';
import { Select } from 'antd';
import { Container } from '../../Components/container';
import { Card } from '../../Components/card';
import { Typography } from '../../Components/typography';
import Radio from '../../Components/Radio';
import * as S from './styles';
import { RootState } from '../../redux/reducers';
import { getChannelsRequest } from '../../redux/ducks/GetChannels/actions';
import {
  getPaymentOptionsRequest,
  createPaymentOptions,
  updateActiveOptions,
} from '../../redux/ducks/PaymentOptions/actions';
import { getProvidersRequest } from '../../redux/ducks/Providers/actions';
import CreditCard from './Credit/credit';
import DebitCard from './Debit';
import Pix from './Pix';
import Exchange from './Exchange';
import { getConnectors } from '../../redux/ducks/Connectors/actions';
import { Loader } from '../../Components/loader';
import { ScrollToTop } from '../../utils/scrollToTop';
import BreadCrumbs from '../../Components/BreadCrumbs';
import External from './External';
import { setError } from '../../redux/ducks/Error/actions';
import InvoicedTicket from './invoicedTicket/invoicedTicket';

export default function Accounts() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('first');
  const [seller, setSeller] = useState('');
  const [def, setDefault] = useState(false);
  const [defId, setDefaultId] = useState('');
  const [error, setPaymentOptionsError] = useState(false);
  const [provider, setProvider] = useState('');
  const [channels, setChannel] = useState();
  const [selectedChannel, setSelectedChannel] = useState();
  const { store, channel, options, connectors, providers } = useSelector(
    (state: RootState) => state
  );
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [mappedSellers, setMappedSellers] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (seller !== '') {
      const { sellers } = channel.find((e) => e.id === selectedChannel);
      const item = sellers.find((e) => e.id === seller);

      const sellerDef = sellers.find((e) => e.default === true);

      setDefault(item.default);
      setDefaultId(sellerDef.id);
    }
  }, [seller]);

  useEffect(() => {
    if (selectedChannel) {
      const { sellers } = channel.find((e) => e.id === selectedChannel);
      const sellerDef = sellers.find((e) => e.default === true);
      if (!sellerDef) {
        dispatch(
          setError({
            show: true,
            message:
              'Ops! Parece que você não tem um Seller Default configurado, por favor contate um administrador!',
          })
        );
      }
    }
  }, [selectedChannel]);

  useEffect(() => {
    dispatch(getChannelsRequest());
  }, [store]);

  useEffect(() => {
    const storeChannels = channel.length
      ? channel.map((item) => ({
          label: `${item.name} (${item.type})`,
          value: item.id,
        }))
      : [];
    setChannel(storeChannels);
    setChecked(false);
  }, [channel]);

  useEffect(() => {
    if (selected !== 'first' && seller !== '') {
      dispatch(
        getPaymentOptionsRequest({
          id: selectedChannel,
          paymentType: selected,
          sellerId: seller,
        })
      );
    }
  }, [selectedChannel, seller]);

  useEffect(() => {
    console.log('🚀 ~ file: index.tsx:131 ~ Accounts ~ options:', options);
    dispatch(getConnectors());
    if (selectedChannel !== undefined) {
      const { sellers } = channel.find((e) => e.id === selectedChannel);

      setMappedSellers(
        sellers.map((e) => {
          return {
            label: e.default ? `${e.description} (padrão)` : `${e.description}`,
            value: `${e.id}`,
          };
        })
      );
    }
  }, [selectedChannel]);

  const usedConnectors = options.length
    ? options.map((item) => item.partnerName.toLowerCase())
    : '';

  const availableConnectors = connectors.length
    ? connectors.map((connector) => {
        return {
          value: connector._id,
          label: connector.name,
          fraudCheck: connector.providerType || false,
          providerId: connector.providerId,
        };
      })
    : [];

  function selectCards(item) {
    return !usedConnectors.includes(item.toLowerCase());
  }

  const filteredConnectors = availableConnectors.length
    ? availableConnectors.filter((connector) => selectCards(connector.label))
    : [];

  function getPayment(payload) {
    dispatch(
      getPaymentOptionsRequest({
        id: selectedChannel,
        paymentType: payload,
        sellerId: seller,
      })
    );
  }

  const handleAddPartner = () => {
    const item = {
      salesChannelId: selectedChannel,
      sellerId: seller,
      active: false,
      paymentType: selected,
      partnerName: 'Novo Parceiro',
      availableCardFlags: [],
    };
    dispatch(createPaymentOptions(item));
  };

  const updateSwitchAction = () => {
    const item = {
      salesChannelId: selectedChannel,
      sellerId: seller,
      active: checked,
      paymentType: selected,
    };
    dispatch(updateActiveOptions(item));
  };

  useEffect(() => {
    if (options.length) {
      setChecked(
        options.find((option) => option.active === true) !== undefined
          ? options.find((option) => option.active === true)
          : false
      );
      return;
    }
    setChecked(false);
  }, [options]);

  useEffect(() => {
    if (selected === 'CREDIT_LIMIT') {
      dispatch(getProvidersRequest());
    }
  }, [selected]);

  useEffect(() => {
    setProvider(providers.data.find((e) => e.type === 'CREDIT_LIMIT'));
  }, [providers]);

  return (
    <>
      <S.BreadCrumbsContainer>
        <BreadCrumbs
          options={[
            {
              disabled: false,
              path: '/',
              name: 'Pagina Inicial',
            },
            {
              disabled: true,
              path: 'https://www.infracommerce.com.br/',
              name: 'Meios de Pagamento',
            },
          ]}
        />
      </S.BreadCrumbsContainer>
      <Container>
        <Typography
          align="left"
          variant="h1"
          variantion="h1_small"
          weight={700}
          color="#2F3C4E"
        >
          Configuração de Meios de Pagamento - Account
        </Typography>
        <S.BackContainer onClick={() => history.goBack()}>
          <ArrowUUpLeft size={15} color="#45E398" />
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weight="bold"
            color="#45E398"
          >
            Voltar
          </Typography>
        </S.BackContainer>

        <InfraModal
          width="450"
          theme="light"
          show={show}
          onClose={() => {
            setShow(false);
            setChecked(!checked);
          }}
        >
          <S.divInfraImage>
            <Info size={84} color="#7676FF" />
          </S.divInfraImage>
          <S.divInfraTexts>
            <Typography
              align="center"
              variant="h5"
              variantion="h5"
              weight={400}
              color="#414F62"
              margin="0 0 16px 0"
            >
              {checked
                ? 'Tem certeza que deseja habilitar o meio de pagamento?'
                : 'Tem certeza que deseja desabilitar o meio de pagamento? '}
            </Typography>
            <Typography
              align="center"
              variant="h5"
              variantion="h5_small"
              weight={400}
              color="#879BB4"
              margin="0 0 16px 0"
            >
              Ao habilitar o meio de pagamento, este será disponibilizado como
              método de pagamento no canal específico de venda deste account,
              caso configurado corretamente na plataforma
            </Typography>
          </S.divInfraTexts>
          <S.divInfraButtons>
            <InfraButton
              style={{
                backgroundColor: '#7676FF',
                padding: '20px 60px 20px 60px',
              }}
              icon="admin"
              outline={false}
              onClick={() => {
                setShow(false);
                updateSwitchAction();
              }}
            >
              Sim
            </InfraButton>

            <InfraButton
              style={{
                backgroundColor: '#ffffff',
                color: '#000',
                borderRadius: '5px',
                border: '1px solid #000',
                padding: '20px 60px 20px 60px',
              }}
              icon="admin"
              outline={false}
              onClick={() => {
                setShow(false);
                setChecked(!checked);
              }}
            >
              Não
            </InfraButton>
          </S.divInfraButtons>
        </InfraModal>

        <S.PageContent>
          <Card>
            <Typography
              align="left"
              variant="h4"
              variantion="h4_small"
              weight={600}
              color="#414F62"
              margin="0 0 16px 0"
            >
              Selecione Canal de Venda
            </Typography>
            <S.SelectContainer>
              <InfraSelect
                handlerClick={(e) => {
                  setSelectedChannel(e.value);
                  setSeller('');
                  setPaymentOptionsError(false);
                }}
                items={channels}
                placeholder="Selecione"
                size="medium"
              />

              {error && !selectedChannel ? (
                <Typography
                  align="left"
                  variant="h6"
                  variantion="h6_small"
                  weight={600}
                  color="error_300"
                  margin="0"
                >
                  Selecione um Parceiro!
                </Typography>
              ) : (
                ''
              )}

              <Select
                showSearch
                placeholder="Adicionar nome do vendedor"
                optionFilterProp="children"
                filterOption={(input, option: { label: string }) =>
                  (option?.label.toLowerCase() ?? '').includes(
                    input.toLowerCase()
                  )
                }
                disabled={!selectedChannel}
                onChange={(e) => {
                  setSeller(e);
                  setPaymentOptionsError(false);
                }}
                value={seller || 'Adicionar nome do vendedor'}
                filterSort={(
                  optionA: { label: string },
                  optionB: { label: string }
                ) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={mappedSellers}
              />
              {error && !seller ? (
                <Typography
                  align="left"
                  variant="h6"
                  variantion="h6_small"
                  weight={600}
                  color="error_300"
                  margin="0"
                >
                  Selecione um Vendedor!
                </Typography>
              ) : (
                ''
              )}
            </S.SelectContainer>

            <Typography
              align="left"
              variant="h4"
              variantion="h4_small"
              weight={600}
              color="#414F62"
              margin="32px 0 16px 0"
            >
              Selecione Meio de Pagamento
            </Typography>
            <S.RadioContainer>
              <Radio
                value="CREDIT_CARD"
                selected={selected}
                blocked={false}
                text="Cartão de crédito"
                onChange={(e) => {
                  if (selectedChannel && seller) {
                    setSelected(e);
                    getPayment(e);
                  } else {
                    setPaymentOptionsError(true);
                  }
                }}
              />
              <Radio
                value="DEBIT_CARD"
                selected={selected}
                blocked
                text="Cartão de débito"
                onChange={(e) => {
                  if (selectedChannel && seller) {
                    // setSelected(e);
                    // getPayment(e);
                  } else {
                    setPaymentOptionsError(true);
                  }
                }}
              />
              <Radio
                value="PIX"
                selected={selected}
                text="PIX"
                blocked={false}
                onChange={(e) => {
                  if (selectedChannel && seller) {
                    setSelected(e);
                    getPayment(e);
                  } else {
                    setPaymentOptionsError(true);
                  }
                }}
              />
              <Radio
                value="BOLETO"
                blocked={false}
                selected={selected}
                text="Boleto"
                onChange={(e) => {
                  if (selectedChannel && seller) {
                    setSelected(e);
                    getPayment(e);
                  } else {
                    setPaymentOptionsError(true);
                  }
                }}
              />
              <Radio
                value="CREDIT_LIMIT"
                blocked={false}
                selected={selected}
                text="Boleto Faturado"
                onChange={(e) => {
                  if (selectedChannel && seller) {
                    setSelected(e);
                    getPayment(e);
                  } else {
                    setPaymentOptionsError(true);
                  }
                }}
              />
              <Radio
                blocked={false}
                value="EXCHANGE_VOUCHER"
                selected={selected}
                text="Vale Troca"
                onChange={(e) => {
                  if (selectedChannel && seller) {
                    setSelected(e);
                    getPayment(e);
                  } else {
                    setPaymentOptionsError(true);
                  }
                }}
              />
              <Radio
                blocked={false}
                value="VOUCHER"
                selected={selected}
                text="Vale Compra"
                onChange={(e) => {
                  if (selectedChannel && seller) {
                    setSelected(e);
                    getPayment(e);
                  } else {
                    setPaymentOptionsError(true);
                  }
                }}
              />
              <Radio
                value="EXTERNAL_PAYMENT"
                blocked={false}
                selected={selected}
                text="Pagamento externo"
                onChange={(e) => {
                  if (selectedChannel && seller) {
                    setSelected(e);
                    getPayment(e);
                  } else {
                    setPaymentOptionsError(true);
                  }
                }}
              />
            </S.RadioContainer>
            <S.SwitchContainer margin="12px 0">
              <InfraSwitch
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                  setShow(true);
                  ScrollToTop();
                }}
                disabled={options[0]?.new ? true : !options.length}
              />
              <Typography
                align="left"
                variant="h6"
                variantion="h6"
                weight="regular"
                color="#414F62"
                margin="0 0 0 8px"
              >
                Habilitar Método de pagamento
              </Typography>
            </S.SwitchContainer>
            <S.addButton
              onClick={() => {
                handleAddPartner();
              }}
              fullWidth
              size="medium"
              color="#45E398"
              disabled={selected === 'first'}
            >
              + Adicionar Parceiro
            </S.addButton>
          </Card>
          {options.loading ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Loader />
            </div>
          ) : options.length > 0 ? (
            <S.InfoContainer>
              {selected === 'CREDIT_CARD' &&
                options.map((option, index) => (
                  <div
                    key={index}
                    style={{
                      maxWidth: '90%',
                      width: '90%',
                      marginBottom: '20px',
                    }}
                  >
                    <CreditCard
                      data={option}
                      position={index}
                      channel={selectedChannel}
                      seller={seller}
                      def={def}
                      defId={defId}
                      active={checked}
                      connectors={filteredConnectors}
                      selectedChannel={selectedChannel}
                    />
                  </div>
                ))}
              {selected === 'DEBIT_CARD' &&
                options.map((option, index) => (
                  <div
                    key={index}
                    style={{
                      maxWidth: '90%',
                      width: '90%',
                      marginBottom: '20px',
                    }}
                  >
                    <DebitCard
                      data={option}
                      channel={selectedChannel}
                      seller={seller}
                      def={def}
                      defId={defId}
                      active={checked}
                      paymentType="DEBIT_CARD"
                    />
                  </div>
                ))}
              {selected === 'PIX' &&
                options.map((option, index) => (
                  <div
                    key={index}
                    style={{
                      maxWidth: '90%',
                      width: '90%',
                      marginBottom: '20px',
                    }}
                  >
                    <Pix
                      data={option}
                      channel={selectedChannel}
                      seller={seller}
                      def={def}
                      defId={defId}
                      connectors={filteredConnectors}
                      active={checked}
                      paymentType="PIX"
                    />
                  </div>
                ))}
              {selected === 'BOLETO' &&
                options.map((option, index) => (
                  <div
                    key={index}
                    style={{
                      maxWidth: '90%',
                      width: '90%',
                      marginBottom: '20px',
                    }}
                  >
                    <Pix
                      data={option}
                      channel={selectedChannel}
                      seller={seller}
                      def={def}
                      defId={defId}
                      connectors={filteredConnectors}
                      active={checked}
                      paymentType="BOLETO"
                    />
                  </div>
                ))}
              {selected === 'CREDIT_LIMIT' &&
                options.map((option, index) => (
                  <div
                    key={index}
                    style={{
                      maxWidth: '90%',
                      width: '90%',
                      marginBottom: '20px',
                    }}
                  >
                    <InvoicedTicket
                      data={option}
                      provider={provider}
                      channel={selectedChannel}
                      position={index}
                      seller={seller}
                      def={def}
                      defId={defId}
                      connectors={filteredConnectors}
                      active={checked}
                      paymentType="CREDIT_LIMIT"
                    />
                  </div>
                ))}
              {selected === 'EXCHANGE_VOUCHER' &&
                options.map((option, index) => (
                  <div
                    key={index}
                    style={{
                      maxWidth: '90%',
                      width: '90%',
                      marginBottom: '20px',
                    }}
                  >
                    <Exchange
                      data={option}
                      channel={selectedChannel}
                      seller={seller}
                      def={def}
                      defId={defId}
                      active={checked}
                      connectors={filteredConnectors}
                      paymentType="EXCHANGE_VOUCHER"
                    />
                  </div>
                ))}
              {selected === 'VOUCHER' &&
                options.map((option, index) => (
                  <div
                    key={index}
                    style={{
                      maxWidth: '90%',
                      width: '90%',
                      marginBottom: '20px',
                    }}
                  >
                    <Exchange
                      data={option}
                      channel={selectedChannel}
                      seller={seller}
                      def={def}
                      defId={defId}
                      active={checked}
                      connectors={filteredConnectors}
                      paymentType="VOUCHER"
                    />
                  </div>
                ))}
              {selected === 'EXTERNAL_PAYMENT' && (
                <div
                  key="EXTERNAL_PAYMENT"
                  style={{
                    maxWidth: '90%',
                    width: '90%',
                    margin: '0px 20px 20px 20px',
                  }}
                >
                  <External
                    data={options}
                    seller={seller}
                    channel={selectedChannel}
                  />
                </div>
              )}
            </S.InfoContainer>
          ) : (
            selected === 'EXTERNAL_PAYMENT' && (
              <div
                key="EXTERNAL_PAYMENT"
                style={{
                  maxWidth: '90%',
                  width: '90%',
                  margin: '0px 20px 20px 20px',
                }}
              >
                <External
                  data={options}
                  seller={seller}
                  channel={selectedChannel}
                />
              </div>
            )
          )}
        </S.PageContent>
      </Container>
    </>
  );
}
