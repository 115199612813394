import { useEffect, useState } from 'react';

import {
  InfraAccordion,
  InfraTextField,
  InfraSelect,
  InfraModal,
} from '@infralabs/design-system';
import { MinusCircle, PlusCircle, X } from 'phosphor-react';
import { useDispatch } from 'react-redux';
import * as S from '../styles';
import { Typography } from '../../../Components/typography';
import {
  updatePaymentOptions,
  deletePaymentOptions,
  postPaymentOptions,
  replicateDefaultSellerConfigs,
  replicateDefaultSellerConfigsToSpecific,
} from '../../../redux/ducks/PaymentOptions/actions';

export default function CreditCard({
  data,
  channel,
  seller,
  def,
  defId,
  active,
  paymentType,
}) {
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState([]);
  const [partner, setPartner] = useState('');
  const [namePaymentOption, setNamePaymentOption] = useState(data.name);
  const [replicateSellerDefault, setReplicateSellerDefault] = useState(false);
  const [
    replicateSellerDefaultToSpecific,
    setReplicateSellerDefaultToSpecific,
  ] = useState(false);

  const updatePayment = () => {
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: active?.active === undefined ? active : active.active,
      id: data._id,
      name: (namePaymentOption != '' ? namePaymentOption : null),
      paymentType: 'DEBIT_CARD',
      partnerName: partner,
      availableCardFlags: inputs,
    };
    dispatch(updatePaymentOptions(item));
  };

  function replicateDefaultSellerConfig() {
    const item = {
      id: data._id,
      salesChannelId: channel,
      sellerId: seller,
      paymentType,
    };

    dispatch(replicateDefaultSellerConfigs(item));

    setReplicateSellerDefault(false);
  }

  function replicateDefaultSellerSpecificConfig() {
    const item = {
      salesChannelId: channel,
      sellerDefaultId: defId,
      sellerSpecificId: seller,
      paymentType,
    };

    dispatch(replicateDefaultSellerConfigsToSpecific(item));

    setReplicateSellerDefaultToSpecific(false);
  }

  const postPaymentOption = () => {
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: true,
      id: data._id,
      paymentType,
      name: (namePaymentOption != '' ? namePaymentOption : null),
      partnerName: partner,
      availableCardFlags: inputs,
    };
    dispatch(postPaymentOptions(item));
  };

  const deleteItem = (index) => {
    inputs.splice(index, 1);
    setInputs([...inputs]);
  };

  const addItem = () => {
    setInputs([...inputs, { name: '' }]);
  };

  const changeName = (item, index) => {
    inputs[index].name = item;
    setInputs([...inputs]);
  };

  const deletePayment = () => {
    const item = {
      salesChannelId: channel,
      sellerId: seller,
      active: active?.active === undefined ? active : active.active,
      id: data._id,
      paymentType: 'DEBIT_CARD',
      partnerName: partner,
      availableCardFlags: inputs,
    };
    dispatch(deletePaymentOptions(item));
  };

  useEffect(() => {
    setInputs(data.availableCardFlags);
    setPartner(data.partnerName);
  }, [data]);

  const partners = [{ value: 'Zoop', label: 'Zoop' }];

  return (
    <InfraAccordion
      title={data.partnerName === 'Novo Parceiro' ? partner : data.partnerName}
      theme="light"
      headColor
    >
      <S.AccordionContainer>
        <S.PartnerInput namePaymentOption>
          <Typography
            align="left"
            variant="h6"
            variantion="h6_small"
            weight="bold"
          >
            Nome de Exibição
          </Typography>
          <InfraTextField
            size="medium"
            height="medium"
            inputStyle="normal"
            onChange={(e) => {
              setNamePaymentOption(e.target.value);
            }}
            placeholder='Pagamento Externo'
            type="text"
            value={namePaymentOption}
            position="start"
          />
        </S.PartnerInput>
        <S.PartnerInput partner>
          <Typography
            align="left"
            variant="h5"
            variantion="h5_small"
            weight="bold"
          >
            Nome do Parceiro
          </Typography>
          <InfraSelect
            handlerClick={(e) => setPartner(e.label)}
            items={partners}
            placeholder={
              data.partnerName === 'Novo Parceiro' ? partner : data.partnerName
            }
            size="medium"
          />
        </S.PartnerInput>
        <S.DeleteContainer>
          <S.DeleteButton
            onClick={() => {
              !active ? deletePayment() : null;
            }}
          >
            <Typography
              align="left"
              variant="h6"
              variantion="h6_small"
              weight={600}
              color="#FA878F"
            >
              Excluir parceiro
            </Typography>
            <X size={20} color="#FA878F" />
          </S.DeleteButton>
        </S.DeleteContainer>

        <S.DefaultSellerContainer>
          {def === false && (
            <S.DefaultSellerButton
              outline
              size="medium"
              color="#1e2022"
              onClick={() => setReplicateSellerDefaultToSpecific(true)}
              modal
            >
              Replicar configurações de Seller Default para um Seller específico
            </S.DefaultSellerButton>
          )}
          {def === true && (
            <S.DefaultSellerButton
              outline
              size="medium"
              color="#1e2022"
              onClick={() => setReplicateSellerDefault(true)}
              modal
            >
              Replicar configurações de Seller Default para todos os Sellers
            </S.DefaultSellerButton>
          )}
        </S.DefaultSellerContainer>

        <InfraModal
          width="450"
          theme="light"
          show={replicateSellerDefault}
          onClose={() => {
            setReplicateSellerDefault(false);
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja replicar as configurações de seller default
            para todos os sellers?
          </Typography>
          <S.ButtonContainer>
            <div
              style={{
                width: '100%',
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => setReplicateSellerDefault(false)}
                modal
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                modal
                color="#414F62"
                onClick={() => replicateDefaultSellerConfig()}
              >
                Salvar
              </S.Button>
            </div>
          </S.ButtonContainer>
        </InfraModal>

        <InfraModal
          width="450"
          theme="light"
          show={replicateSellerDefaultToSpecific}
          onClose={() => {
            setReplicateSellerDefaultToSpecific(false);
          }}
        >
          <Typography
            align="center"
            variant="h5"
            variantion="h5"
            weight={400}
            color="#414F62"
            margin="0 0 16px 0"
          >
            Tem certeza que deseja replicar as configurações de seller default
            para apenas este seller?
          </Typography>
          <S.ButtonContainer>
            <div
              style={{
                width: '100%',
                marginTop: '15px',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <S.Button
                icon="X"
                iconLeft
                outline
                size="medium"
                color="#414F62"
                onClick={() => setReplicateSellerDefaultToSpecific(false)}
                modal
              >
                Cancelar
              </S.Button>
              <S.Button
                icon="Check"
                iconLeft
                size="medium"
                modal
                color="#414F62"
                onClick={() => replicateDefaultSellerSpecificConfig()}
              >
                Salvar
              </S.Button>
            </div>
          </S.ButtonContainer>
        </InfraModal>

        {inputs?.map((input, i) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
            key={i}
          >
            <S.PartnerInput>
              <InfraTextField
                height="medium"
                id={i}
                inputStyle="normal"
                label="Bandeira"
                onChange={(e) => changeName(e.target.value, i)}
                placeholder="Bandeira"
                type="text"
                value={inputs[i].name}
                position="start"
              />
            </S.PartnerInput>
            <S.DeleteIconContainer onClick={() => deleteItem(i)}>
              <MinusCircle size={20} color="#414F62" />
            </S.DeleteIconContainer>
          </div>
        ))}
        <S.AddFlagContainer onClick={() => addItem()}>
          <PlusCircle size={20} color="#414F62" />
          <Typography
            align="left"
            variant="h5"
            variantion="h5_small"
            weight={600}
            color="#414F62"
            margin="0 10px"
          >
            Adicionar bandeira
          </Typography>
        </S.AddFlagContainer>
        <S.ButtonContainer>
          <S.Button icon="X" iconLeft outline size="medium" color="#414F62">
            Cancelar
          </S.Button>
          <S.Button
            icon="Check"
            iconLeft
            size="medium"
            color="#414F62"
            onClick={data.new ? postPaymentOption : updatePayment}
          >
            Salvar
          </S.Button>
        </S.ButtonContainer>
      </S.AccordionContainer>
    </InfraAccordion>
  );
}
